import React, { useEffect, useState } from "react";
import Menu from '../components/Menu';
import LoadingOverlay from 'react-loading-overlay';
import firebase from '../firebase/firebase';
import {Input} from 'reactstrap';
import moment from 'moment';

export default function Logs(){

    const [loading, setLoading] = useState(true);
    const [logs, setLogs] = useState([]);
    const [fecha, setFecha] = useState(moment(new Date()).format('YYYY-MM-DD'));

    useEffect(() =>{
        getLogs();
    }, [])

    function logout(){
        firebase.logout();
        localStorage.removeItem('redux');
    }
    const getLogs = (date = moment(new Date()).format('YYYY-MM-DD')) => {
        
        firebase.db.collection("log")
            .where("fecha", ">=", date)
            .get()
            .then(querySnapshot => {
                let objetoLog;
                let arrayLogs = []
                querySnapshot.forEach((doc) => {
                    objetoLog = doc.data();
                    objetoLog.id = doc.id;
                    arrayLogs.push(objetoLog);
                });
                setLogs(arrayLogs);
                setLoading(false);
            }).catch(err => setLoading(false))  
    }

    const handleChange = (e) => { 
        let newFecha = e.target.value;
        setFecha(e.target.value);
        getLogs(newFecha);
    }

    return(
        <div>
            <Menu link='logs' logout={logout} ></Menu>
            {loading &&
                    <div id="loader">
                        <div className="lds-heart"><div></div></div>
                    </div>
                }
            {!loading &&
            <main className="container d-flex align-items-center justify-content-center topMain mb-3">
                <div className="row h-100 align-items-center justify-content-center  w-100">
                    <div className="row p-0 w-100">
                        <div className="col-12 p-0 list-postul rounded border border-secondary shadow-lg">
                            <div className="list-postul-header text-center p-3">
                                <h2>Logs</h2>
                            </div>
                            <div className="list-postul-content py-3 px-2 px-md-5 text-left">
                                <div className="row mb-5 justify-content-between">
                                    <div className="col-12 col-md-auto align-items-center mb-3">
                                        <span>Filtrar</span>
                                        <Input type="date" name="fecha" id="fecha" value={fecha} className="w-100" 
                                            onChange={handleChange} 
                                        />
                                    </div>

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="d-table-cell">Usuario</th>
                                                <th scope="col" className="d-table-cell">Acción</th>
                                                <th scope="col" className="d-none d-md-table-cell">Fecha :Hora</th>
                                                <th scope="col" className="d-none d-md-table-cell">Módulo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {logs.map ((data, key) =>(
                                                <tr key={data.id}>
                                                    <td className="d-table-cell">
                                                        {data.info.rol ==="programador" ? 'Especialista ' + data.info.nombres.split(' ')[0] + ' ' + data.info.apellidos.split(' ')[0]  
                                                            : 'Empresa ' + data.info.nombreEmpresa}
                                                    </td>
                                                    <td className="d-table-cell">{data.accion}</td>
                                                    <td className="d-none d-sm-table-cell">{data.fecha}</td>
                                                    <td className="d-none d-sm-table-cell">{data.modulo}</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            }
        </div>
    )
} 