import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import persistState from 'redux-localstorage';
import * as serviceWorker from './serviceWorker';
import { createStore, compose } from 'redux';
import rootReducer from './reducers';
import './index.css';
import App from './App';

const enhancer = compose(
    persistState('perfil')
)

const store = createStore(rootReducer, {}, enhancer);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
