import React, {useState, useEffect} from 'react';
import firebase from '../firebase/firebase';
import { useParams, useHistory, useLocation } from "react-router-dom";

import Menu from '../components/Menu';
import { Input, Label } from 'reactstrap';

const ComprotrabajoDetalle = (props) => {

  
  const location = useLocation();
  const array = location.pathname.split('/');
  const email = array[2];

  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [programador, setProgramador] = useState(null);
  
  useEffect(() => {
    if(email){
      const obtenerPostulado = async () => {
        const postuladoQuery = await firebase.db.collection('registradoscomputrabajo').doc(email);
        const postulado = await postuladoQuery.get();
        if(postulado.exists){
          console.log(postulado.data())
          setProgramador(postulado.data());
        } 
        setLoader(false)
      }
      obtenerPostulado();
    } 
  }, [])

  const logout = () => {
    firebase.logout();
  }

  return ( 
    <div>
      <Menu link='computrabajo' logout={logout} ></Menu>
      {loader &&
        <div id="loader">
          <div className="lds-heart"><div></div></div>
        </div>
      }
      {!loader &&
        <main className="container d-flex align-items-center justify-content-center topMain mb-3">
          <div className="row h-100 w-100 align-items-center justify-content-center" id="divToPrint" >
            <div className="row p-0 w-100">
            {programador &&
              <div className="col-12 p-0 list-postul rounded border border-secondary shadow-lg w-100">  
                <div className="list-postul-header text-center p-3">
                  <h2>PERFIL: {programador.nombres}</h2>
                </div>
                <div className="list-postul-content py-3 px-2 px-md-5 text-left">
                  <div className="datos">
                    <h3 className="heading font-weight-bold text-center p-2">Oferta:</h3>
                    <h3 className="heading font-weight-bold text-center p-2" style={{ color: '#28a745'}} >
                      {programador.oferta}
                    </h3>
                  </div>
                  <div className="datos-content w-100 py-3">
                    
                    <div className="form-group row m-0">
                      <div className="col-md-4">
                        <div className="form-holder">
                          <Label>Correo</Label>
                          <Input type="text" className="form-control"  name="correo" value={programador.correo} 
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-holder">
                          <Label>Fecha de Postulación</Label>
                          <Input type="text" className="form-control"  name="fechapostulacion" value={programador.fechapostulacion} 
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-holder">
                          <Label>Teléfono</Label>
                          <Input type="text" className="form-control"  name="numerotelefono" value={programador.numerotelefono} 
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group row m-0">
                      <div className="col-md-12 mt-4">
                        <Label><b>Raumen Curriular</b></Label>
                        <p>
                          {programador.curriculum}
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            }

            </div>
          </div>
        </main>
      }
    </div>
  );
}
 
export default ComprotrabajoDetalle;