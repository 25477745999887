const perfilReducer = (state='', action) =>{
    switch (action.type){
        case 'SET_PERFIL':
            return action.perfil;
        case 'CLEAR_PERFIL':
            return '';
        case 'GET_PERFIL':
            return Object.assign({}, state);
        default :
            return state;
    }
}

export default perfilReducer