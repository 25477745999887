import React  from 'react';
import { Label, Input  } from 'reactstrap';

function Links(props) {
    return <div className="form-group row m-0">
    <div className="col-12">
        <div className="form-holder">
            {!props.edit &&
                <a href={props.link.link} target="_blank" rel="noopener noreferrer" style={{color: '#007bff'}} >{props.link.link}</a>
            }
            {props.edit &&
                <div>
                    <Label>Enlace</Label>
                    <Input type="text" className="form-control" name="link" value={props.link.link} 
                        onChange={(e) => {props.valueInput(e.target.value, e.target.name, props.indice )}} 
                    />
                </div>
            }
        </div>
    </div>
    <div className="col-12">
        <div className="form-holder">
            <Label><b>Descripción</b></Label>
            {!props.edit &&
                <p style={{textAlign: 'justify'}}>{props.link.linkDescripcion}</p>
            }
            {props.edit &&
                <Input type="textarea" name="linkDescripcion" id="linkDescripcion" cols="35" rows="4" 
                    value={props.link.linkDescripcion}  onChange={(e) => {props.valueInput(e.target.value, e.target.name, props.indice )}}  />
            }
        </div>
    </div>
</div>
}

export default Links;