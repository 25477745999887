import React  from 'react';
import { Label } from 'reactstrap';
import { IoIosCloseCircle } from "react-icons/io";

function Chip(props) {
    return <div className="chip">
        <Label onClick={() => {props.selected({index: props.index, type: props.type})}}  >{props.text}</Label> 
        <IoIosCloseCircle  onClick={() => {props.remove({index: props.index, type: props.type})}}  style={{fontSize: 20, cursor: 'pointer'}} />              
    </div>;
}

export default Chip;