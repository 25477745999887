import React, {useState}  from 'react';
import Logo from '../img/logo-hispanos.png';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
  } from 'reactstrap';

function Menu(props) {

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return <header className="header position-fixed w-100 shadow-lg">
            <Navbar className="p-0" dark expand="md">
                <NavbarBrand href="/home">
                    <img src={Logo} width="60" height="60" className="d-inline-block align-top" alt="" />  
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        <NavItem className="px-1 text-center" active={props.link === 'home'}>
                            <NavLink href="/home">Inicio</NavLink>
                        </NavItem>
                        <NavItem className="px-1 text-center" active={props.link === 'computrabajo'}>
                            <NavLink href="/computrabajo">Computrabajo</NavLink>
                        </NavItem>
                        <NavItem className="px-1 text-center" active={props.link === 'configuracion'}>
                            <NavLink href="/configuracion">Configuración</NavLink>
                        </NavItem>
                        <NavItem className="px-1 text-center" active={props.link === 'mensajes'}>
                            <NavLink  href="/mensajes">Mensajes</NavLink>
                        </NavItem>
                        <NavItem className="px-1 text-center" active={props.link === 'todos'}>
                            <NavLink href="/todos">Todos</NavLink>
                        </NavItem>
                        <NavItem className="px-1 text-center" active={props.link === 'programadores'}>
                            <NavLink href="/programadores">Programadores</NavLink>
                        </NavItem>
                        <NavItem className="px-1 text-center" active={props.link === 'logs'}>
                            <NavLink href="/logs">Logs</NavLink>
                        </NavItem>
                        <NavItem className="px-1 text-center">
                            <NavLink href="/" onClick={props.logout}>Cerrar sesión</NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>

        </header>
}

export default Menu;