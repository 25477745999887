import React, { Component } from 'react';
import { Input, Button, Pagination, PaginationItem, PaginationLink, Badge } from 'reactstrap';
import firebase from '../firebase/firebase';
import {Link} from "react-router-dom";
// import createHistory from 'history/createBrowserHistory';
import Menu from '../components/Menu';
import moment from 'moment';

class ListadoProgramadores  extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.changeOrder = this.changeOrder.bind(this);
        this.changeEstado = this.changeEstado.bind(this);
        this.changeFilterEstado = this.changeFilterEstado.bind(this);
        this.changeFilterIngles = this.changeFilterIngles.bind(this);
        this.changeActive = this.changeActive.bind(this);
        this.search = this.search.bind(this);
        this.logout = this.logout.bind(this);

        this.state = {
            programadores: [],
            programadoresTodos: [],
            search: '',
            orderBy: 'updatedat',
            currentPage: 0,
            pageCount: 0,
            pageSize: 50,
            loader: true,
            filterEstado: 'Todos',
            longitud: 0,
            filtroIngles: 'Todos'
        }
    }

    componentDidMount(){
        let query = firebase.db.collection('programadores').where("admin","==", false);
        query.onSnapshot(querySnapshot => {
            this.loadProgrammers(this.state.orderBy);
        }, err => {
            console.log(`Encountered error: ${err}`);
        });
        this.loadProgrammers(this.state.orderBy);

    }

    handlePageClick = (e, index) => {
        e.preventDefault();
        this.setState({currentPage: index});
    };

    handleNextClick = (e, index) => {
        e.preventDefault();
        this.setState({currentPage: (this.state.currentPage + 1)});
    }

    handlePreviousClick = (e, index) => {
        e.preventDefault();
        this.setState({currentPage: (this.state.currentPage  - 1)});
    }

    loadProgrammers(filtro){
        firebase.db.collection("programadores")
            .where("admin","==", false)  
            .get()
            .then(querySnapshot => {
                let objetoProgramador;
                let arrayProgramador = []
                querySnapshot.forEach((doc) => {
                    objetoProgramador = doc.data();
                    objetoProgramador.id = doc.id
                    arrayProgramador.push(objetoProgramador)
                });
                let notEmpty = arrayProgramador.filter(row => row.datosCompletos === true);
                let newList = notEmpty.sort(this.sortByDate);
                this.setState({
                    programadores: newList, 
                    programadoresTodos: newList,  
                    longitud: newList.length,
                    pageCount: Math.ceil(newList.length / this.state.pageSize), 
                    loader: false
                });
            }).catch(err => {
                console.log(err.message);
                this.setState({ loader: false})
            });  
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});   
    }

    search(){
        const search = this.state.search;
        let dataFiltrada = [];
        let filterTipoPerfiles = [];
        const programadores = this.state.programadoresTodos;
        let arrayDistint = [];
        let arrayConcat = [];

        if (search === '') { // si est vacio el input buscar
            this.setState({programadores: this.state.programadoresTodos, longitud :this.state.programadoresTodos.length,
                pageCount: Math.ceil(this.state.programadoresTodos.length / this.state.pageSize) });
        } else{ // si a escrito lago para buscar
            // fitro por todos los campos menos tipos de perfiles por que es un arreglo
            dataFiltrada = programadores.filter(item => item.nombres.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || 
                item.apellidos.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 
                /*|| item.nivelIngles.toLowerCase().indexOf(this.state.search.toLowerCase()) > - 1*/
                || item.estado.toLowerCase().indexOf(this.state.search.toLowerCase()) > - 1
                || item.updatedat.toLowerCase().indexOf(this.state.search.toLowerCase()) > - 1
                || item.aspiracion.toString().toLowerCase().indexOf(this.state.search.toLowerCase()) > - 1
                || item.anosExperiencia.toString().indexOf(this.state.search.toLowerCase()) > - 1
                || item.numWhatsapp.toString().toLowerCase().indexOf(this.state.search.toLowerCase()) > - 1
                || item.cedula.toString().indexOf(this.state.search.toLowerCase()) > - 1
            );
            // filtro por tipo de perfiles
            programadores
                .map( item => {
                    return item.tipoPerfiles.map(fila => {
                        if(fila.toLowerCase().indexOf(this.state.search.toLowerCase()) > - 1){
                            filterTipoPerfiles.push(item);
                        }
                        return filterTipoPerfiles;
                    });
                })
            // junto los dos arreglos filtrados el general y el de tipo de perfiles
            arrayConcat = dataFiltrada.concat(filterTipoPerfiles);
            // ahora como puede que se repitan los registros aplico un distinc para que no se repitan
            arrayDistint = Array.from(new Set(arrayConcat.map(s => s.uid)))
                            .map(uid => {
                                return arrayConcat.find(x => x.uid === uid)
                            })
            this.setState({programadores: arrayDistint, longitud: arrayDistint.length, pageCount: Math.ceil(arrayDistint.length / this.state.pageSize) });
        }
    }

    changeEstado = (e) => {
        // console.log(e.target.value);
    }

    changeFilterEstado = (e) => {
        const filtro = e.target.value;
        const todos = this.state.programadoresTodos;
        this.setState({filterEstado: filtro});  
        if(filtro === 'Todos'){
            let filtered = [];
            if(this.state.filtroIngles === 'Todos'){
                filtered = todos;
            } else {
                filtered = todos.filter(fila => fila.nivelIngles === this.state.filtroIngles);
            }
            this.setState({programadores: filtered, longitud: filtered.length, pageCount: Math.ceil(filtered.length / this.state.pageSize), currentPage: 0 });
        } else {
            let filtered = [];
            if(this.state.filtroIngles === 'Todos'){
                filtered = todos.filter(fila => fila.estado === filtro);
            } else {
                filtered = todos.filter(fila => fila.estado === filtro && fila.nivelIngles === this.state.filtroIngles);
            }
            //const filtered = todos.filter(fila => fila.estado === filtro);
            this.setState({programadores: filtered, longitud: filtered.length, pageCount: Math.ceil(filtered.length / this.state.pageSize), currentPage: 0 });
        }
        this.setState({ search: '', currentPage: 0 , orderBy: 'updatedat' });

    }

    changeFilterIngles = (e) => {
        const filtro = e.target.value;
        const todos = this.state.programadoresTodos;
        this.setState({filtroIngles: filtro}); 
        if(filtro === 'Todos'){
            let filtered = [];
            if(this.state.filterEstado === 'Todos'){
                filtered = todos;
            } else {
                filtered = todos.filter(fila => fila.estado === this.state.filterEstado);
            }
            this.setState({programadores: filtered, longitud: todos.length, pageCount: Math.ceil(filtered.length / this.state.pageSize), currentPage: 0 });
        } else {
            let filtered = [];
            if(this.state.filtroingles === 'Todos'){
                filtered = todos.filter(fila => fila.nivelIngles === filtro);
            } else {
                filtered = todos.filter(fila => fila.nivelIngles === filtro && fila.estado === this.state.filterEstado);
            }
            //const filtered = todos.filter(fila => fila.nivelIngles === filtro);
            this.setState({programadores: filtered, longitud: filtered.length, pageCount: Math.ceil(filtered.length / this.state.pageSize), currentPage: 0 });
        }
        this.setState({ search: '', currentPage: 0, orderBy: 'updatedat' });
    }

    changeOrder = (e) => {
        this.setState({[e.target.name]: e.target.value});
        let newList = this.state.programadores;
        let ordenado = [];

        if(e.target.value === 'updatedat'){
            ordenado = newList.sort(this.sortByDate);
        } else if(e.target.value === 'aspiracion'){
            ordenado = newList.sort(this.sortByAspiracion);
        } else {
            ordenado = newList.sort((prev, next) => {
                if( prev[e.target.value].toLowerCase()  > next[e.target.value].toLowerCase()  ){
                    return 1;
                }
                if( prev[e.target.value].toLowerCase() < next[e.target.value].toLowerCase()  ){
                    return -1;
                }
                return 0;
            });
        }

        this.setState({
            programadores: ordenado, longitud: ordenado.length,
        });  
    }

    changeActive(event){
        let programadores = this.state.programadores
            programadores.forEach(prog => {
            if (prog.uid === event.target.value){
                prog.activo =  event.target.checked
                firebase.db.collection("programadores").doc(`${prog.uid}`).update({activo: event.target.checked});
            }
        })
        this.setState({programadores: programadores, longitud: programadores.length, pageCount: Math.ceil(programadores.length / this.state.pageSize) });
    }

    logout() {
        firebase.logout();
        localStorage.removeItem('redux');
    }

    colorBadge(estado){
        switch (estado) {
            case 'No contratado':
              return 'warning';
            case 'Contratado':
                return 'secondary';
            case 'No revisado':
                return 'info';
            case 'Habilitado':
                return 'success';
            case 'Penalizado':
                return 'danger';
            case 'No cumple los requisitos':
                return 'dark';
            default:
                return 'info';
        }
    }

    sortByDate(a,b){  
        var dateA = new Date(a.updatedat).getTime();
        var dateB = new Date(b.updatedat).getTime();
        return dateA < dateB ? 1 : -1;  
    }

    sortByAspiracion(a,b){  
        var numbera = parseInt(a.aspiracion, 10);
        var numberb = parseInt(b.aspiracion, 10);
        return   numbera - numberb;
    }

    itemsPerPage(){
        let array = []
        array = this.state.programadores.slice(this.state.currentPage * this.state.pageSize, (this.state.currentPage + 1) * this.state.pageSize)
        return  (this.state.currentPage * this.state.pageSize) + array.length;
    }

    render(){
        return(
            <div>
                <Menu link='home' logout={this.logout} ></Menu>
                {this.state.loader &&
                    <div id="loader">
                        <div className="lds-heart"><div></div></div>
                    </div>
                }
            {!this.state.loader &&
                <main className="container d-flex align-items-center justify-content-center topMain mb-3">
                    <div className="row h-100 w-100 align-items-center justify-content-center">
                        <div className="row p-0 w-100">
                            <div className="col-12 p-0 list-postul rounded border border-secondary shadow-lg w-100">
                                <div className="list-postul-header text-center p-3">
                                    <h2>Listado de postulados</h2>
                                </div>
                                <div className="list-postul-content py-3 px-2 px-md-3 w-100">
                                    <div className="row mb-5 justify-content-between">
                                        <div className="col-12 col-md-auto align-items-center mb-1">
                                            <span>Ordenar</span><br/>
                                            <Input type="select" name="orderBy" id="orderBy" value={this.state.orderBy} className="w-100" onChange={this.changeOrder}  >
                                                <option value="updatedat">Fecha de Postulación</option>
                                                <option value="nombres">Nombre</option>
                                                <option value="apellidos">Apellido</option>
                                                <option value="aspiracion">Aspiración Salarial</option>
                                            </Input>
                                        </div>
                                        <div className="col-12 col-md-auto align-items-center mb-1">
                                            <span>Estado</span>
                                            <Input type="select" name="estado" id="estado" value={this.state.filterEstado} className="w-100"
                                                onChange={this.changeFilterEstado}
                                            >
                                                <option value="Todos">Todos</option>
                                                <option value="Habilitado">Habilitado</option>
                                                <option value="No contratado">No contratado</option>
                                                <option value="No cumple los requisitos">No cumple los requisitos</option>
                                                <option value="No revisado">No revisado</option>
                                                <option value="Contratado">Contratado</option>
                                                <option value="Penalizado">Penalizado</option>
                                            </Input>
                                        </div>
                                        <div className="col-12 col-md-2 align-items-center mb-1">
                                            {/*<span>Idiomas</span>
                                            <Input type="select" name="nivelIngles" id="nivelIngles" value={this.state.filtroIngles} className="w-100"
                                                onChange={this.changeFilterIngles}
                                            >
                                                <option value="Todos">Todos</option>
                                                <option value="No">No</option>
                                                <option value="Si">Si</option>
                                            </Input> */}
                                        </div>
                                        <div className="col-12 col-md-auto div-search mb-1" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }} >
                                            <Input className="w-100 rounded" type="search" name="search" id="search" value={this.state.search} placeholder="Buscar" aria-label="Search" 
                                                onChange={this.handleChange} />
                                            <div className="input-group-append">
                                                <Button className="search position-absolute btn btn-info" onClick={this.search} >
                                                        <i className="zmdi zmdi-search" aria-hidden="true"></i>
                                                </Button>
                                            </div>
        
                                        </div>
                                    </div>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="d-table-cell">Nombres</th>
                                                <th scope="col" className="d-table-cell">Apellidos</th>
                                                <th scope="col" className="d-none d-md-table-cell">Exp.</th>
                                                <th scope="col" className="d-none d-sm-table-cell">$</th>
                                                <th scope="col" className="d-none d-lg-table-cell">Idiomas</th>
                                                <th scope="col" className="d-none d-md-table-cell">Tipo de perfil</th>
                                                <th scope="col" className="d-none d-sm-table-cell">Fecha</th>
                                                <th scope="col" className="text-center d-xs-table-cell">Estado</th>
                                                <th scope="col" className="text-center">Ver</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.programadores 
                                                .slice (this.state.currentPage * this.state.pageSize, (this.state.currentPage + 1) * this.state.pageSize) 
                                                .map ((data, key) => { 
                                                return ( 
                                                    <tr key={key}>
                                                        <td className="d-table-cell">
                                                            {data.nombres === '' &&
                                                                <label>Sin Nombre asociado</label>
                                                            }
                                                            {data.nombres !== '' &&
                                                                <label>{data.nombres.split(' ')[0]}</label>
                                                            }
                                                        </td>
                                                        <td className="d-table-cell">
                                                            {data.apellidos === '' &&
                                                                <label>Sin Apellido asociado</label>
                                                            }
                                                            {data.apellidos !== '' &&
                                                                <label>{data.apellidos.split(' ')[0]}</label>
                                                            }
                                                        </td>
                                                        <td className="d-none d-md-table-cell">{data.anosExperiencia}</td>
                                                        <td className="d-none d-sm-table-cell">{data.aspiracion}</td>
                                                        <td className="d-none d-lg-table-cell">
                                                            {data.idiomas.join(',') }
                                                        </td>
                                                        <td className="d-none d-md-table-cell" style={{ width: 300 }} >

                                                            {data.tipoPerfiles.length === 0 &&
                                                                <label>No tiene perfil asociado</label>
                                                            }
                                                            {data.tipoPerfiles.length !== 0 &&
                                                                data.tipoPerfiles.map((per, k) => ( 
                                                                    <span key={k}>{per} {k !== (data.tipoPerfiles.length -1)  ? ',' : '' }&nbsp;</span>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="d-none d-sm-table-cell">{moment(new Date(data.fecha)).format('DD-MM-YYYY')}</td>
                                                        <td className="text-center d-xs-table-cell">
                                                            <Badge color={this.colorBadge(data.estado)}>{data.estado}</Badge>
                                                        </td>
                                                        <td className="text-center"> 
                                                            <Link className="btn btn-info btn-sm" to={{ 
                                                                pathname: `/detalle/${data.id}`,
                                                            }} target={"_blank"} >
                                                                Ver  
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            
                                        </tbody>
                                    </table>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}} >
                                        <span>Mostrando<b> {(this.state.currentPage * this.state.pageSize) + 1}&nbsp;</b> 
                                            a <b>{this.itemsPerPage()}&nbsp;</b> 
                                            de <b>{this.state.programadores.length}</b>
                                        </span>
                                        <Pagination size="sm" aria-label="Page navigation example">
                                            {[...Array(this.state.pageCount)].map((page, i) => (
                                                <PaginationItem active={i === this.state.currentPage} key={i}>
                                                    <PaginationLink onClick={e => this.handlePageClick(e, i)} href="#">
                                                        {i + 1}
                                                    </PaginationLink>
                                                </PaginationItem>
                                            ))}
                                        </Pagination>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            }
            </div>
        );
    }
    
}

export default ListadoProgramadores