import React, {Component} from 'react';
import { Button, Form, Label, Input  } from 'reactstrap';
import {Link} from "react-router-dom";
import firebase from '../firebase/firebase';
import { Alert } from 'reactstrap';
import axios from 'axios';
import ReactLoading from 'react-loading';
import config from '../config/index';

class ForgotPassword extends Component {
    constructor(props){
        super(props);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.searchUser = this.searchUser.bind(this);
        this.changePassword = this.changePassword.bind(this);
        
    }
    state = {
        email: '',
        errorMessage: '',
        emailSend: false,
        user: null,
        verifyEmail: false,
        changePasswordSuccess: false,
        password: '',
        confirmPassword: '',
        errorMessageChangePass: '',
        loading: false,
        loading2: false,
    }
    
    forgotPassword(event){
        event.preventDefault();
        if (this.state.email === '') {
            this.setState({errorMessage: '*Debe ingresar el email'});
        } else {
            this.setState({errorMessage: ''});
        }
        firebase.recoverPass(this.state.email).then(() => {
            this.setState({emailSend: true, email: ''});
        }).catch(err => {
            this.setState({emailSend: false});
            console.log(err.message);
        })

    }

    searchUser(event){
        event.preventDefault();
        if (this.state.email === '') {
            this.setState({errorMessage: '*Debe ingresar el email'});
        } else {
            this.setState({errorMessage: ''});
            const data = {
                email:  this.state.email,	
            }
            this.setState({loading : true });
            axios.post(`${config.notificationUrl}/getUser`,  data )
            .then(res => {
                if(res.data.success){
                    this.setState({user: res.data.user, verifyEmail: true, loading: false});
                } else {
                    if(res.data.error.code === 'auth/user-not-found'){
                        this.setState({user: null, errorMessage: 'esta cuenta de correo no se encuentra registrada', loading: false});
                    } else {
                        this.setState({user: null, errorMessage: 'error al intentar buscar el email', loading: false});
                    }
                    
                }
            }).catch((error) => {           
                console.log(error);
                this.setState({user: null});                  
            })
        }
    }

    changePassword(event){
        event.preventDefault();
        if (this.state.password === '' || this.state.confirmPassword === '') {
            this.setState({errorMessageChangePass: '*Debe todos los campos'});
        } else {
            if(this.state.password !==  this.state.confirmPassword){
                this.setState({errorMessageChangePass: '*Password no coinciden'});
            } else {
                this.setState({errorMessageChangePass: ''});
                const data = {
                    uid:  this.state.user.uid,
                    password:  this.state.password,	
                }
                this.setState({loading2: true });
                axios.post(`${config.notificationUrl}/setPassword`,  data )
                .then(res => {
                    if(res.data.success){
                        this.setState({errorMessageChangePass: '', emailSend: true, loading2: false });
                    } else {
                        this.setState({errorMessageChangePass: '*Error al intentar cambiar el password', emailSend: false, loading2: false});
                    }
                }).catch((error) => {           
                    console.log(error);
                    this.setState({errorMessageChangePass: '*Error al intentarse conectra con el servidor', emailSend: false, loading2: false});                  
                })
            }
        }
    }

    emailToKey(emailAddress) {
        return emailAddress.replace(/[.]/g, '%20');
    }


    renderContent(){

    }

    render(){
        return !this.state.emailSend ? (
            <main className="container d-flex align-items-center justify-content-center">
                <div className="row h-100 align-items-center justify-content-center">
                    <div className="row">
                        {this.state.verifyEmail === false &&
                            <Form id="login_new_register" className="form-login rounded border border-secondary shadow-lg" onSubmit={this.searchUser}>
                                <div className="form-header">
                                    <div className="row">
                                        <div className="col-12 py-3">
                                            <h2 className="text-center">Recuperar Contraseña</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-content px-4 py-5">
                                        <div className="form-group row m-0 mt-3">
                                            <div className="col-12">
                                                <Label for="email">Correo electrónico:</Label>
                                                <Input name="email" className="form-control" type="email" placeholder="Ingrese su email" onChange={e => this.setState({ email: e.target.value})} 
                                                onKeyUp={e => this.setState({ errorMessage: ''})} />
                                            </div>
                                        </div>
                                        {this.state.loading &&
                                            <div className="form-group row m-0 mt-1">
                                                <div className="col-12 text-center" style={{display: 'flex', justifyContent: 'center'  }} >
                                                    <ReactLoading type='bars' color='#3e4061' height={'10%'} width={'10%'} />
                                                </div>
                                            </div>
                                        }           
                                        {this.state.errorMessage !== '' &&
                                            <div className="form-group row m-0 mt-3">
                                                <div className="col-12 text-center">
                                                    <Alert color="danger">
                                                        {this.state.errorMessage}
                                                    </Alert>
                                                </div>
                                            </div>
                                        }
                                        <div className="form-group row justify-content-center m-0 mt-3">
                                            <Button color="success">Enviar</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-footer">
                                    <div className="row mb-5">
                                        <div className="col-12 text-center">
                                            <Link to="/login">Ya recuerdo mi contraseña</Link>
                                        </div>
                                        <div className="col-12 text-center">
                                            ¿No tienes una cuenta? Registrate  <Link to="/register">aquí</Link>
                                        </div>
                                    </div>
                                </div>

                            </Form>
                        }
                        {this.state.verifyEmail === true &&
                            <Form id="login_new_register" className="form-login rounded border border-secondary shadow-lg" onSubmit={this.changePassword}>
                                <div className="form-header">
                                    <div className="row">
                                        <div className="col-12 py-3">
                                            <h2 className="text-center">Recuperar Contraseña</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-content px-4 py-5">
                                        <div className="form-group row m-0 mt-3">
                                            <div className="col-12">
                                                <Label for="password">Password:</Label>
                                                <Input id ="password" name="password" className="form-control" type="password" onChange={e => this.setState({ password: e.target.value})} 
                                                onKeyUp={e => this.setState({ errorMessageChangePass: ''})} minLength={6} />
                                            </div>
                                            <div className="col-12 mt-3">
                                                <Label for="confirmPassword">Confirmamar Password:</Label>
                                                <Input id ="confirmPassword" name="confirmPassword" className="form-control" type="password" onChange={e => this.setState({ confirmPassword: e.target.value})} 
                                                onKeyUp={e => this.setState({ errorMessageChangePass: ''})} minLength={6} />
                                            </div>
                                        </div>
                                        {this.state.loading2 &&
                                            <div className="form-group row m-0 mt-1">
                                                <div className="col-12 text-center" style={{display: 'flex', justifyContent: 'center'  }} >
                                                    <ReactLoading type='bars' color='#3e4061' height={'10%'} width={'10%'} />
                                                </div>
                                            </div>
                                        } 
                                        {this.state.errorMessageChangePass !== '' &&
                                            <div className="form-group row m-0 mt-3">
                                                <div className="col-12 text-center">
                                                    <Alert color="danger">
                                                        {this.state.errorMessageChangePass}
                                                    </Alert>
                                                </div>
                                            </div>
                                        }
                                        <div className="form-group row justify-content-center m-0 mt-3">
                                            <Button color="success">Enviar</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-footer">
                                    <div className="row mb-5">
                                        <div className="col-12 text-center">
                                            <Link to="/login">Ya recuerdo mi contraseña</Link>
                                        </div>
                                        <div className="col-12 text-center">
                                            ¿No tienes una cuenta? Registrate  <Link to="/register">aquí</Link>
                                        </div>
                                    </div>
                                </div>

                            </Form>
                        }
                    </div>
                </div>
            </main>
        ) : 
            <main className="container d-flex align-items-center justify-content-center my-lg-5">
                <div className="row h-100 align-items-center justify-content-center">
                    <div className="row">
                        <form id="login_new_register" className="form-new-register rounded border border-secondary shadow-lg">
                            <div className="form-content px-4 py-5">
                                <div className="row mb-5">
                                    <div className="col-12 text-center">
                                        <Alert color="success">
                                            Su password ha sido actualizado exitosamente, ya puedes iniciar sesión nuevamente.
                                        </Alert>
                                        <Link to="/login">Volver al Login</Link>
                                        <br /> 
                                        <Link to="/register">¿No tienes una cuenta? Registrate</Link> 
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        
    }
}

export default ForgotPassword;