import React, {useState, useEffect}  from 'react';
import firebase from '../../firebase/firebase';
import Menu from '../Menu';
import {Pagination, PaginationItem, PaginationLink, Badge, Input, Button} from 'reactstrap';
import moment from 'moment';
import axios from 'axios';
import notificaciones from '../../mocks/notificaciones'; 
import {Link} from "react-router-dom";
import { FaCheck } from 'react-icons/fa'; 
import config from '../../config/index';
import { ExportToCsv } from 'export-to-csv';
import { sinAsteriscos } from '../../utilidades/exportCsv';


const optionsExport = { 
    fieldSeparator: ',',
    quoteStrings: '',
    decimalSeparator: '.',
    filename: 'postulados',
    showLabels: true, 
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: false,
    headers: ['correo']
};

const DatosIncompletos = (props) => {

    const [programadores, setProgramadores] = useState([]);
    const [todos, setTodos] = useState([]);
    const [currentpage, setCurrentpage] = useState(0);
    const [pagesize] = useState(70);
    const [pagecount, setPagecount] = useState(0);
    const [loader, setLoader] = useState(true);
    const [estado, setEstado] = useState('Todos');
    const [motivonc, setMotivonc] = useState('Aspiración salarial por encima de nuestras posibilidades');
    const [search, setSearch] = useState('');
    const [orderBy, setOrderby] = useState('updatedat');
    const [nocontratado, setNocontratado] = useState([]);
    const [seleccionados, setSeleccionados] = useState([]);
    const [filtroingles, setFiltroingles] = useState('Todos');
    const [busco, setBusco] = useState(false);

    useEffect(() =>{
        loadProgrammers();
    }, [])

    const loadProgrammers = () => {
        let arrayNocontratado = [];
        let arraySeleccionados = [];
        firebase.db.collection("programadores")
            .where("admin","==", false)
            .get()
            .then(querySnapshot => {
                let objetoProgramador;
                let arrayProgramador = []
                querySnapshot.forEach((doc) => {
                    objetoProgramador = doc.data();
                    objetoProgramador.id = doc.id
                    arrayProgramador.push(objetoProgramador)
                    arrayNocontratado.push(false);
                    arraySeleccionados.push(false);
                });
                let newList = arrayProgramador.sort(sortByDate);
                const count =  Math.ceil(arrayProgramador.length / pagesize);
                setPagecount(count);
                setProgramadores(newList);
                setNocontratado(arrayNocontratado);
                setTodos(newList);
                setLoader(false);
                setSeleccionados(arraySeleccionados);
            });  
    }

    const handleChange = (e) => { 
        setSearch(e.target.value);
    }

    const changeOrder = (e) => { 
        setBusco(true);
        setOrderby(e.target.value);
        let newList = programadores;
        let ordenado = [];
        if(e.target.value === 'updatedat'){
            ordenado = newList.sort(sortByDate);
        } else if(e.target.value === 'aspiracion'){
            ordenado = newList.sort(sortByAspiracion);
        } else {
            ordenado = newList.sort((prev, next) => {
                if( prev[e.target.value].toLowerCase()  > next[e.target.value].toLowerCase()  ){
                    return 1;
                }
                if( prev[e.target.value].toLowerCase() < next[e.target.value].toLowerCase()  ){
                    return -1;
                }
                return 0;
            });
        }
        setProgramadores(ordenado)
        let arrayNocontratado = [];
        ordenado.forEach(item=>{
            arrayNocontratado.push(false)
        })
        setNocontratado(arrayNocontratado)
    }

    const handleSearch = (e) => { 
        setBusco(true);
        let dataFiltrada = [];
        let filterTipoPerfiles = [];
        let arrayDistint = [];
        let arrayConcat = [];

        if (search === '') { // si esta vacio el input buscar
            setProgramadores(todos);
            const count =  Math.ceil(todos.length / pagesize);
            setPagecount(count);
            setCurrentpage(0);
            let arrayNocontratado = [];
            let arraySeleccionados = [];
            todos.forEach(item=>{
                arrayNocontratado.push(false)
                arraySeleccionados.push(false)
            })
            setNocontratado(arrayNocontratado);
            setSeleccionados(arraySeleccionados);
        } else{ // si a escrito lago para buscar
            // fitro por todos los campos menos tipos de perfiles por que es un arreglo
            dataFiltrada = todos.filter(item => item.nombres.toLowerCase().indexOf(search.toLowerCase()) > -1 || 
                item.apellidos.toLowerCase().indexOf(search.toLowerCase()) > -1 
                /* || item.nivelIngles.toLowerCase().indexOf(search.toLowerCase()) > - 1*/
                || item.estado.toLowerCase().indexOf(search.toLowerCase()) > - 1
                || item.updatedat.toLowerCase().indexOf(search.toLowerCase()) > - 1
                || item.aspiracion.toString().toLowerCase().indexOf(search.toLowerCase()) > - 1
                || item.anosExperiencia.toString().indexOf(search.toLowerCase()) > - 1
                || item.numWhatsapp.toString().toLowerCase().indexOf(search.toLowerCase()) > - 1
                || item.cedula.toString().indexOf(search.toLowerCase()) > - 1
            );
            // filtro por tipo de perfiles
            todos.map( item => {
                return item.tipoPerfiles.map(fila => {
                    if(fila.toLowerCase().indexOf(search.toLowerCase()) > - 1){
                        filterTipoPerfiles.push(item);
                    }
                    return filterTipoPerfiles;
                });
            })
            // junto los dos arreglos filtrados el general y el de tipo de perfiles
            arrayConcat = dataFiltrada.concat(filterTipoPerfiles);
            // ahora como puede que se repitan los registros aplico un distinc para que no se repitan
            arrayDistint = Array.from(new Set(arrayConcat.map(s => s.uid)))
                            .map(uid => {
                                return arrayConcat.find(x => x.uid === uid)
                            })

            setProgramadores(arrayDistint);
            let arrayNocontratado = [];
            let arraySeleccionados = [];
            arrayDistint.forEach(item=>{
                arrayNocontratado.push(false)
                arraySeleccionados.push(false)
            })
            setNocontratado(arrayNocontratado)
            setSeleccionados(arraySeleccionados)
            const count =  Math.ceil(arrayDistint.length / pagesize);
            setPagecount(count);
            setCurrentpage(0);
        }
    }

    function logout() {
        firebase.logout();
        localStorage.removeItem('redux');
    }

    const handlePageClick = (e, index) => {
        e.preventDefault();
        setCurrentpage(index);
    };

    const colorBadge = (estado) => {
        switch (estado) {
            case 'No contratado':
              return 'warning';
            case 'Contratado':
                return 'secondary';
            case 'No revisado':
                return 'info';
            case 'Habilitado':
                return 'success';
            case 'Penalizado':
                return 'danger';
            case 'No cumple los requisitos':
                return 'light';
            default:
                return 'dark';
        }
    }

    const changeFilterEstado = (e) => {
        setBusco(true);
        let arrayNocontratado = [];
        let arraySeleccionados = [];
        const filtro = e.target.value;
        setEstado(filtro);
        setSearch('');
        if(filtro === 'Todos'){
            let filtered = [];
            if(filtroingles === 'Todos'){
                filtered = todos;
            } else {
                filtered = todos.filter(fila => fila.nivelIngles === filtroingles);
            }
            setProgramadores(filtered);
            const count =  Math.ceil(filtered.length / pagesize);
            setPagecount(count);
            filtered.forEach(item=>{
                arrayNocontratado.push(false)
                arraySeleccionados.push(false)
            })
            setNocontratado(arrayNocontratado);
            setSeleccionados(arraySeleccionados);
        } else {
            let filtered = [];
            if(filtroingles === 'Todos'){
                filtered = todos.filter(fila => fila.estado === filtro);
            } else {
                filtered = todos.filter(fila => fila.estado === filtro && fila.nivelIngles === filtroingles);
            }
            setProgramadores(filtered);
            const count =  Math.ceil(filtered.length / pagesize);
            setPagecount(count);
            filtered.forEach(item=>{
                arrayNocontratado.push(false)
                arraySeleccionados.push(false)
            })
            setNocontratado(arrayNocontratado);
            setSeleccionados(arraySeleccionados);
        }
        setCurrentpage(0);
        setSearch('');
        setOrderby('updatedat');
    }

    const changeFilterIngles = (e) => {
        setBusco(true);
        let arrayNocontratado = [];
        let arraySeleccionados = [];
        const filtro = e.target.value;
        setFiltroingles(filtro)
        if(filtro === 'Todos'){
            let filtered = [];
            if(estado === 'Todos'){
                filtered = todos;
            } else {
                filtered = todos.filter(fila => fila.estado === estado);
            }
            setProgramadores(filtered);
            const count =  Math.ceil(filtered.length / pagesize);
            setPagecount(count);
            filtered.forEach(item=>{
                arrayNocontratado.push(false)
                arraySeleccionados.push(false)
            })
            setNocontratado(arrayNocontratado);
            setSeleccionados(arraySeleccionados);
        } else {
            let filtered = [];
            if(estado === 'Todos'){
                 filtered = todos.filter(fila => fila.nivelIngles === filtro);
            } else {
                 filtered = todos.filter(fila => fila.nivelIngles === filtro && fila.estado === estado);
            }
            setProgramadores(filtered);
            const count =  Math.ceil(filtered.length / pagesize);
            setPagecount(count);
            filtered.forEach(item=>{
                arrayNocontratado.push(false)
                arraySeleccionados.push(false)
            })
            setNocontratado(arrayNocontratado);
            setSeleccionados(arraySeleccionados);
        }
        setCurrentpage(0);
        setSearch('');
        setOrderby('updatedat');
    }

    const changeSubMotivo = (e, item, index) => {
        setMotivonc(e.target.value);            
    }

    const confirmarNoContratado = (e, item, index) => {
        const programador = item;
        const programadorNombres = `${programador.nombres} ${programador.apellidos}`;
        let dataUpdate;
        let arrayHistorico = [];
        arrayHistorico = programador.historico;
        arrayHistorico.push({ fecha: moment(new Date()).format('DD-MM-YYYY'), accion: 'El estado del programador ha cambiado ha No contratado'  });
        dataUpdate = {estado: programador.estado, historico: arrayHistorico }
        let motivo = `Estimado/a, <b>${programadorNombres}</b> le informamos que la empresa <b> Hispanos Soluciones C.A</b>, `;
        motivo +=  notificaciones.noContratado.texto + ` <b>${motivonc}</b>
                    <p>Le recordamos que puede modificar su perfil en la siguiente url http://postulate.hispanossoluciones.com/, 
                y sera evaluado nuevamente.</p>`;
        actualizarEstado(item, motivo, dataUpdate, true);
        setTimeout(()=>{ 
            let arrayNocontratado = [];
            arrayNocontratado = [...nocontratado];
            arrayNocontratado[index] = false;
            setNocontratado(arrayNocontratado);
        }, 2000);
    }

    const changeEstado = (e, item, index) => {
        const value = e.target.value;
        let programador = item;
        let all = [...programadores];
        all[index].estado = value;
        setProgramadores(all);
        let dataUpdate;
        const programadorNombres = `${programador.nombres} ${programador.apellidos}`;
        let motivo = `Estimado/a, <b>${programadorNombres}</b> le informamos que la empresa <b> Hispanos Soluciones C.A</b>, `;
        switch (value)
        {
            case "Habilitado":
                motivo +=  notificaciones.habilitado.texto;
                dataUpdate = {estado: value};
                actualizarEstado(item, motivo, dataUpdate, true);
                break;
            case "No contratado":
                let array = [...nocontratado];
                array[index] = true;
                setNocontratado(array);
                break;
            case "No revisado":
                dataUpdate = {estado: value};
                actualizarEstado(item, motivo, dataUpdate, false);
                break;
            case "Contratado":
                motivo +=  notificaciones.contratado.texto;
                dataUpdate = {estado: value}; 
                actualizarEstado(item, motivo, dataUpdate, true);
                break;
            case "Penalizado": 
                motivo +=  notificaciones.penalizado.texto;
                dataUpdate = {estado: value};
                actualizarEstado(item, motivo, dataUpdate, true);
                break;
            case "No cumple los requisitos": 
                motivo +=  notificaciones.noCumpleRequisitos.texto;
                dataUpdate = {estado: value};
                actualizarEstado(item, motivo, dataUpdate, true);
                break;
            default: 
                break;
        }
    }

    const actualizarEstado = (programador, motivo, dataUpdate, email)=>{
        firebase.db.collection("programadores").doc(`${programador.uid}`)
            .update(dataUpdate)
            .then(result => {
                const data = {
                    email:  programador.email,	
                    motivo
                }
                loadProgrammers();
                if(email === true){
                    notificarProgramador(data);
                }
            }).catch(err => {
                console.log(err.message);
            });
    }
    
    const notificarProgramador = async (data) =>{
        return await axios.post(`${config.notificationUrl}/sendmail`,  data )
    }

    function sortByDate(a,b){  
        var dateA = new Date(a.updatedat).getTime();
        var dateB = new Date(b.updatedat).getTime();
        return dateA < dateB ? 1 : -1;  
    }

    function sortByAspiracion(a,b){  
        var numbera = parseInt(a.aspiracion, 10);
        var numberb = parseInt(b.aspiracion, 10);
        return   numbera - numberb;
    }

    function itemsPerPage(){
        let array = []
        array = programadores.slice(currentpage * pagesize, (currentpage + 1) * pagesize)
        return  (currentpage * pagesize) + array.length;
    }

    const seleectedAll = (e) => {
        let array = [];
        array = [...seleccionados];
        if(e.target.checked){
            programadores.forEach((item, k) =>{
                if(item.estado === 'Iniciado'){
                    array[k] = true;
                }
            })
        }else{
            programadores.forEach((item, k) =>{
                if(item.estado === 'Iniciado'){
                    array[k] = false;
                }
            })
        }
        setSeleccionados(array);
    }

    const handleSelected = (e, index) => {
        let array = [];
        array = [...seleccionados];
        array[index] = e.target.checked;
        setSeleccionados(array);
    }

    const notificarEmail = ()=>{
        let array = [];
        let filteredProg = [];
        filteredProg = [...programadores];
        array = [...seleccionados];


        for(let i=0; i <= array.length; i ++){
            if(array[i] === true){
                const persona = filteredProg[i];
                let motivo = '';
                if(persona.nombres === '' && persona.apellidos === ''){
                    motivo = `Estimado/a, <b>${persona.email}</b> le informamos que la empresa <b> Hispanos Soluciones C.A</b>, `;
                    motivo += notificaciones.perfilIncompleto.texto + ` ${persona.email}, 
                    puede crear una nueva contraseña en la opción <b>¿Olvidó su contraseña?</b>, luego entrar al sistema y cargar su perfil profesional
                    para evaluer su perfil y ser consierado por nuestras empresas`;
                } else {
                    motivo += `Estimado/a, <b>${persona.nombres}  ${persona.apellidos} </b> le informamos que la empresa <b> Hispanos Soluciones C.A</b>,
                    le invita a completar sus datos para evaluer su perfil y ser condierado por nuestras empresas.`;
                }
                const data = {
                    email:  persona.email,	
                    motivo
                }
                notificarProgramador(data);
            }
        }
    }

    const exportarCSV = () => {
        let formatData = [];
        programadores.forEach(item => {
            if(item.estado === 'Iniciado' || item.estado === 'Penalizado' ){
              formatData.push({correo: item.email});
            }
        });
        const csvExporter = new ExportToCsv(optionsExport);
        csvExporter.generateCsv(formatData);
    }

    return(
        <div>
            <Menu link='todos' logout={logout} ></Menu>
            {loader &&
                <div id="loader">
                    <div className="lds-heart"><div></div></div>
                </div>
            }
        {!loader &&
            <main className="container d-flex align-items-center justify-content-center topMain mb-3">
                <div className="row h-100 align-items-center justify-content-center  w-100">
                    <div className="row p-0 w-100">
                        <div className="col-12 p-0 list-postul rounded border border-secondary shadow-lg">
                            <div className="list-postul-header text-center p-3">
                                <h2>Listado Todos los postulados</h2>
                            </div>
                            <div className="list-postul-content py-3 px-2 px-md-3 text-left">

                                <div className="row mb-3 justify-content-between">
                                    <div className="col-12 col-md-auto align-items-center mb-1">
                                        <span>Ordenar</span>
                                        <Input type="select" name="orderBy" id="orderBy" value={orderBy} className="w-100" onChange={changeOrder}  >
                                            <option value="updatedat">Fecha de Postulación</option>
                                            <option value="nombres">Nombre</option>
                                            <option value="apellidos">Apellido</option>
                                            <option value="aspiracion">Aspiración Salarial</option>
                                        </Input>
                                    </div>
                                    <div className="col-12 col-md-auto align-items-center mb-1">
                                        <span>Estado</span>
                                        <Input type="select" name="estado" id="estado" value={estado} className="w-100"
                                            onChange={changeFilterEstado}
                                        >
                                            <option value="Todos">Todos</option>
                                            <option value="Habilitado">Habilitado</option>
                                            <option value="Iniciado">Iniciado</option>
                                            <option value="No contratado">No contratado</option>
                                            <option value="No cumple los requisitos">No cumple los requisitos</option>
                                            <option value="No revisado">No revisado</option>
                                            <option value="Contratado">Contratado</option>
                                            <option value="Penalizado">Penalizado</option>
                                        </Input>
                                    </div>
                                    <div className="col-12 col-md-2 align-items-center mb-1">
                                        {/*<span>Idiomas</span>
                                        <Input type="select" name="nivelIngles" id="nivelIngles" value={filtroingles} className="w-100"
                                            onChange={changeFilterIngles}
                                        >
                                            <option value="Todos">Todos</option>
                                            <option value="No">No</option>
                                            <option value="Si">Si</option>
                                        </Input> */}
                                    </div>
                                    <div className="col-12 col-md-auto div-search mb-1" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }} >
                                        <Input className="w-100 rounded" type="search" name="search" id="search" bsSize="sm" 
                                            value={search} placeholder="Buscar" aria-label="Search" 
                                            onChange={handleChange} />
                                        <div className="input-group-append">
                                            <Button className="search position-absolute btn btn-info" onClick={handleSearch}  size="sm" >
                                                    <i className="zmdi zmdi-search" aria-hidden="true"></i>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3 justify-content-between">
                                    <div className="col-12 text-center">
                                        <Button color="success" onClick={notificarEmail} style={{ marginRight: '5px' }} >
                                            Notificar a selecionados
                                        </Button>
                                        {busco &&
                                            <Button className="btn btn-info" onClick={e =>exportarCSV()} >
                                                Exportar a CSV
                                            </Button>
                                        }
                                    </div>
                                </div>

                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="d-table-cell">Nombres</th>
                                            <th scope="col" className="d-none d-md-table-cell">Tipo de perfil</th>
                                            <th scope="col" className="d-none d-md-table-cell">$</th>
                                            <th scope="col" className="d-none d-md-table-cell">Idiomas</th>
                                            <th scope="col" className="d-none d-md-table-cell">Fecha</th>
                                            <th scope="col" className="d-none d-md-table-cell">Estado</th>
                                            <th scope="col" className="text-center">Acciones</th>
                                            <th scope="col" className="d-none d-md-table-cell">
                                                {/*<input type="checkbox" onChange={seleectedAll} /> */}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    {programadores 
                                        .slice (currentpage * pagesize, (currentpage + 1) * pagesize) 
                                        .map ((data, key) => { 
                                        return ( 
                                            <tr key={key} >
                                                <td className="d-table-cell">
                                                    {data.nombres === '' && data.apellidos === ''  && 
                                                        <b>Sin nombres</b>
                                                    }
                                                    {data.nombres !== '' && data.apellidos !== '' && 
                                                        <span>{data.nombres.split(' ')[0]} {data.apellidos.split(' ')[0]}</span>
                                                    }
                                                </td>
                                                <td className="d-none d-md-table-cell" style={{width: 300}} >
                                                    {data.tipoPerfiles.length === 0 && 
                                                        <b>No ha seleccionado el perfil</b>
                                                    }
                                                    {data.tipoPerfiles.length !== 0 &&
                                                        data.tipoPerfiles.map((per, k) => ( 
                                                            <span key={k}>{per} {k !== (data.tipoPerfiles.length -1)  ? ',' : '' }&nbsp;</span>
                                                        ))
                                                    }
                                                </td>
                                                <td className="d-none d-sm-table-cell">{data.aspiracion}</td>
                                                <td className="d-none d-md-table-cell">
                                                    {data.idiomas.join(',') }
                                                </td>
                                                <td className="d-none d-md-table-cell">{moment(new Date(data.fecha)).format('DD-MM-YYYY')}</td>
                                                <td className="d-none d-md-table-cell">
                                                    <Badge color={colorBadge(data.estado)}>{data.estado}</Badge>
                                                </td>
                                                <td className="d-table-cell">

                                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                                            <Input type="select" name="estado" id="estado" style={{ marginRight: 7, width: 130}}
                                                                value={data.estado} bsSize="sm"
                                                                onChange={(e) => {changeEstado(e, data, key)}}>
                                                                <option value="Habilitado">Habilitado</option>
                                                                <option value="Iniciado">Iniciado</option>
                                                                <option value="No contratado">No contratado</option>
                                                                <option value="No cumple los requisitos">No cumple los requisitos</option>
                                                                <option value="No revisado">No revisado</option>
                                                                <option value="Contratado">Contratado</option>
                                                                <option value="Penalizado">Penalizado</option>
                                                            </Input>
                                                            <Link className="btn btn-info btn-sm" to={{ 
                                                                pathname: `/detalle/${data.id}`,
                                                            }} target={"_blank"} >
                                                                Ver  
                                                            </Link>
                                                        </div>
                                                        {data.estado === 'No contratado' && nocontratado[key] === true  && 
                                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                                            <Input type="select" name="motivonc" id="motivonc" style={{ marginRight: 7, width: 150}}
                                                                value={motivonc} bsSize="sm"
                                                                onChange={(e) => {changeSubMotivo(e, data, key)}}>
                                                                {notificaciones.noContratado.motivos.map((mot, j) => ( 
                                                                    <option value={mot} key={j} >{mot}</option>
                                                                ))}
                                                            </Input>
                                                            <Button color="success" size="sm"
                                                                onClick={(e)=>{confirmarNoContratado(e, data, key)}}
                                                            >
                                                                <FaCheck />
                                                            </Button>
                                                        </div>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="d-none d-sm-table-cell">
                                                    {data.estado === 'Iniciado' &&
                                                        <input type="checkbox" 
                                                            checked={seleccionados[key]} 
                                                            onChange={(e) => handleSelected(e, key)} 
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                                <div style={{display: 'flex', justifyContent: 'space-between'}} >
                                    <span>Mostrando<b> {(currentpage * pagesize) + 1}&nbsp;</b> 
                                        a <b>{itemsPerPage()}&nbsp;</b> 
                                        de <b>{programadores.length}</b>
                                    </span>
                                    <Pagination size="sm" aria-label="Page navigation example">
                                        {[...Array(pagecount)].map((page, i) => (
                                            <PaginationItem active={i === currentpage} key={i}>
                                                <PaginationLink onClick={e => handlePageClick(e, i)} href="#">
                                                    {i + 1}
                                                </PaginationLink>
                                            </PaginationItem>
                                        ))}
                                    </Pagination>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        }
        </div>
    )
}

export default DatosIncompletos;