import React, { Component } from "react";
import { Button, Alert, Label, Input, Tooltip } from "reactstrap";
import moment from "moment";
import firebase from "../firebase/firebase";
import { FaStickyNote } from "react-icons/fa";

import { setPerfil } from "../actions";
import { connect } from "react-redux";

class TabFinalizado extends Component {
  constructor(props) {
    super(props);
    this.changeStep = this.changeStep.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.enviarMensaje = this.enviarMensaje.bind(this);
    this.toggleTooltipAspiracion = this.toggleTooltipAspiracion.bind(this);
    this.toggleTooltipDisponibilidad =
      this.toggleTooltipDisponibilidad.bind(this);
    this.cambiosrapidos = this.cambiosrapidos.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.closeAlerts = this.closeAlerts.bind(this);

    this.state = {
      activeTab: this.props.perfil.ultimaPestana,
      perfil: this.props.perfil,
      existenMensajes: false,
      cuantosMensajes: 0,
      openAlert: true,
      openAlertCambiosRapidos: false,
      mensajeCambiosRapidos: "",
      arrayMensajes: this.props.perfil.chat.mensajes,
      colorCambiosRapidos: "success",
      mensaje: "",
      messageEnviarMensaje: "",
      openGuardarInfo: false,
      openEnviarMensaje: false,
      tipoEnviarMensaje: "success",
      tooltipAspiracionOpen: false,
      tooltipDisponibilidadOpen: false,
      aspiracion: this.props.perfil.aspiracion,
      disponibilidad: this.props.perfil.disponibilidad,
      primerasesioncerrada: this.props.perfil.primerasesioncerrada,
      registroCompletado: this.props.perfil.registroCompletado,
      historico: this.props.perfil.historico,
      updatedat: this.props.perfil.updatedat,
    };
  }

  componentDidMount() {
    //let query = firebase.db.collection("programadores").doc(`${firebase.auth.currentUser.uid}`);
    if (firebase.auth.currentUser) {
      let query = firebase.db.collection("programadores");
      query.onSnapshot(
        (querySnapshot) => {
          querySnapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              //console.log('New programador: ', change.doc.data());
            }
            if (change.type === "modified") {
              const data = change.doc.data();
              if (data.uid === firebase.auth.currentUser.uid) {
                this.getData(data);
              }
            }
            if (change.type === "removed") {
              //console.log('Removed programador: ', change.doc.data());
            }
          });
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        }
      );
    }
  }

  getData(datos) {
    const data = datos;
    const mensajes = data.chat.mensajes;
    mensajes.sort(this.sortMessages);
    this.setState({
      arrayMensajes: mensajes,
      primerasesioncerrada: datos.primerasesioncerrada,
      registroCompletado: datos.registroCompletado,
    });
    this.props.setPerfil(datos);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  verificarMensajes() {
    let existen = false;
    let contador = 0;
    const mensajes = this.props.perfil.chat.mensajes;
    mensajes.forEach((item) => {
      if (item.creador === "admin" && item.leido === 0) {
        contador++;
        existen = true;
      }
    });
    this.setState({ existenMensajes: existen, cuantosMensajes: contador });
  }

  changeStep(tab) {
    firebase.db
      .collection("programadores")
      .doc(`${firebase.auth.currentUser.uid}`)
      .update({ ultimaPestana: tab })
      .then((querySnapshot) => {
        this.props.stepFinal(tab);
      });
  }

  enviarMensaje(e) {
    const self = this;
    if (this.state.mensaje === "") {
      this.setState({
        messageEnviarMensaje: "Debe llenar el campo Contacto con administrador",
        tipoEnviarMensaje: "danger",
        openEnviarMensaje: true,
      });
    } else {
      let array = this.state.arrayMensajes;
      array.push({
        creador: "programador",
        fecha: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        id: firebase.auth.currentUser.uid,
        leido: 0,
        texto: self.state.mensaje,
      });
      const body = {
        actualizado: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        id: self.props.perfil.chat.id,
        cerrado: 0,
        mensajes: array,
        nombres: self.props.perfil.nombres,
        apellidos: self.props.perfil.apellidos,
        email:
          self.props.perfil.email === ""
            ? firebase.auth.currentUser.email
            : self.props.perfil.email,
        numWhatsapp: self.props.perfil.numWhatsapp,
        cedula: self.props.perfil.cedula,
        anosExperiencia: self.props.perfil.anosExperiencia,
        nivelIngles: self.props.perfil.nivelIngles,
        titulo: self.props.perfil.titulo,
        universidad: self.props.perfil.universidad,
        ciudad: self.props.perfil.ciudad,
        direccion: self.props.perfil.direccion,
        aspiracion: self.props.perfil.aspiracion,
        fecha: self.props.perfil.fecha,
        tipoPerfiles: self.props.perfil.tipoPerfiles,
        idiomas: self.props.perfil.idiomas
          ? self.props.perfil.idiomas
          : ["Español"],
      };
      let newProfile = Object.assign({}, self.props.perfil, body);
      firebase.db
        .collection("programadores")
        .doc(`${firebase.auth.currentUser.uid}`)
        .update({ chat: body })
        .then((querySnapshot) => {
          this.props.setPerfil(newProfile);
          self.setState({
            messageEnviarMensaje: "Mensaje enviado exitosamente",
            tipoEnviarMensaje: "success",
            mensaje: "",
            openEnviarMensaje: true,
          });
          setTimeout(function () {
            self.setState({ openEnviarMensaje: false });
          }, 3000);
        });
    }
  }

  llenarDatosGenrales() {
    let array = [];
    array.push({
      nombres: this.props.perfil.nombres,
      apellidos: this.props.perfil.apellidos,
      email:
        this.props.perfil.email === ""
          ? firebase.auth.currentUser.email
          : this.props.perfil.email,
      numWhatsapp: this.props.perfil.numWhatsapp,
      cedula: this.props.perfil.cedula,
      anosExperiencia: this.props.perfil.anosExperiencia,
      nivelIngles: this.props.perfil.nivelIngles,
      titulo: this.props.perfil.titulo,
      universidad: this.props.perfil.universidad,
      ciudad: this.props.perfil.ciudad,
      direccion: this.props.perfil.direccion,
      aspiracion: this.props.perfil.aspiracion,
      fecha: this.props.perfil.fecha,
    });
    return array;
  }

  updateMensajes() {
    let mensajesArray = this.props.perfil.mensajes;
    mensajesArray.forEach((item) => {
      item.leido = 1;
    });
    let newProfile = Object.assign({}, this.props.perfil, {
      mensajes: mensajesArray,
    });
    firebase.db
      .collection("programadores")
      .doc(`${firebase.auth.currentUser.uid}`)
      .update({ mensajes: mensajesArray })
      .then((resp) => {
        this.props.setPerfil(newProfile);
      });
  }

  sortMessages(a, b) {
    var dateA = new Date(a.fecha).getTime();
    var dateB = new Date(b.fecha).getTime();
    return dateA < dateB ? 1 : -1;
  }

  toggleTooltipAspiracion(event) {
    this.setState({ tooltipAspiracionOpen: !this.state.tooltipAspiracionOpen });
  }

  toggleTooltipDisponibilidad(event) {
    this.setState({
      tooltipDisponibilidadOpen: !this.state.tooltipDisponibilidadOpen,
    });
  }

  cambiosrapidos(event) {
    let arrayHistorico = [];
    let estado;
    arrayHistorico = this.props.perfil.historico;
    if (this.props.perfil.primerasesioncerrada === true) {
      this.setState({
        updatedat: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      });
      if (this.props.perfil.aspiracion !== this.state.aspiracion) {
        arrayHistorico.push({
          fecha: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          accion:
            this.props.perfil.estado === "No contratado"
              ? `Cambio de aspiracion salarial de ${this.props.perfil.aspiracion}$ a ${this.state.aspiracion}$, 
                    tambien se cambia el estado del programador a No revisado ya que se encontraba No Contratado`
              : `Cambio de aspiracion salarial de ${this.props.perfil.aspiracion}$ a ${this.state.aspiracion}$`,
        });
        estado =
          this.props.perfil.estado === "No contratado"
            ? "No revisado"
            : this.props.perfil.estado;
      }
      if (this.props.perfil.disponibilidad !== this.state.disponibilidad) {
        arrayHistorico.push({
          fecha: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          accion:
            this.props.perfil.estado === "No contratado"
              ? `Cambio la disponibilidad ${this.props.perfil.disponibilidad} a ${this.state.disponibilidad}, 
                    tambien se cambia el estado del programador a No revisado ya que se encontraba No contratado`
              : `Cambio la disponibilidad de ${this.props.perfil.disponibilidad} a ${this.state.disponibilidad}`,
        });
        estado =
          this.props.perfil.estado === "No contratado"
            ? "No revisado"
            : this.props.perfil.estado;
      }
    }

    let newProfile = Object.assign({}, this.props.perfil, {
      aspiracion: this.state.aspiracion,
      disponibilidad: this.state.disponibilidad,
      historico: arrayHistorico,
      estado,
      updatedat: this.state.updatedat,
    });
    firebase.db
      .collection("programadores")
      .doc(`${firebase.auth.currentUser.uid}`)
      .update({
        aspiracion: this.state.aspiracion,
        disponibilidad: this.state.disponibilidad,
        historico: arrayHistorico,
        estado,
        updatedat: this.state.updatedat,
      })
      .then((resp) => {
        this.props.setPerfil(newProfile);
        this.setState({
          mensajeCambiosRapidos: "Datos actualizados exitosamente",
          colorCambiosRapidos: "success",
          openAlertCambiosRapidos: true,
        });
      });
  }

  handleChangeSelect = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  closeAlerts() {
    this.setState({
      openAlertCambiosRapidos: false,
      openAlert: false,
    });
  }

  render() {
    return (
      <div style={{ width: "100%" }}>
        <div className="wizard-header">
          <h3 className="heading">Postulación completa</h3>
          <p>Gracias por participar</p>
          <p>
            Tu postulación ha sido completada exitosamente, se le estara
            comunicando via correo electrónico o via número de whatsapp, si ha
            sido seleccionado para alguna de nuestras vacantes, tome en
            consideración que siempre generamos nuevos puestos de trabajo a
            distancia.
          </p>
          <p>Consideraciones previas a una entrevista:</p>
          <p>
            1. Expresarse en un lenguaje técnico cient&iacute;fico, evita la
            informalidad, comunicate como un profesional en tu area.
          </p>
          <p>
             2. Ser capaz de responder preguntas técnicas porque los
            entrevistadores son especialistas en el área donde estás buscando
            empleo, aunque en algunas ocasiones no lo mencionen al presentarse,
            no es apropiado un "yo sé cómo se hace, pero no como se dice", una
            simple búsqueda un Google te puede ser de gran utilidad, ejemplo:
            "preguntas para una entrevista de Laravel, Angular, etc".
          </p>
          <p>
            3. Respecto a la experiencia: Así dispongas una amplia experiencia,
            es necesario tener disposición a realizar pruebas y es importante
            repasar algoritmos básicos para el caso de los programadores.  
          </p>
          <p>
            4. No menos importante es la presentación... su presentación
            personal hasta la comprobación del correcto funcionamiento de su
            equipo de trabajo es relevante para la entrevista para un trabajo
            remoto y por supuesto la puntualidad. Es importante ingresar 5
            minutos antes de la hora citada.
          </p>
          <p>
            5. Sé amable, trata correctamente a las personas con las cuales te
            relacionas, poseer habilidades blandas te abrirá las puertas en
            Hispanos Soluciones y en cualquier otra empresa.
          </p>
        </div>

        <div className="wizard-header">
          <p>
            Recuerda que tienes libertad para agregar nuevas tecnologías que
            hayas aprendido, cambiar tu disponibilidad de tiempo para asumir
            proyectos a tiempo completo o variar tu aspiración salarial de
            acuerdo a lo que consideres... todos estos cambios seran
            monitoreados
          </p>

          {this.state.cuantosMensajes === 1 && (
            <Alert
              color="success"
              isOpen={this.state.openAlert}
              toggle={this.closeAlerts}
            >
              Tienes {this.state.cuantosMensajes} mensaje nuevo del
              administrador
            </Alert>
          )}
          {this.state.cuantosMensajes > 1 && (
            <Alert color="success">
              Tienes {this.state.cuantosMensajes} mensajes nuevos del
              administrador
            </Alert>
          )}
        </div>
        <div className="form-group row m-1">
          <div className="col-12 d-flex justify-content-center">
            <Button color="success" onClick={this.props.salirse}>
              Cerrar sesión
            </Button>
            <a href="https://www.facebook.com/profile.php?id=100084938400686" className="btn btn-primary ml-1">
              Dale me gusta a nuestra nueva página de Facebook 
            </a>
          </div>
        </div>

        {this.state.primerasesioncerrada === true &&
          this.state.registroCompletado === true && (
            <div>
              <div className="wizard-header mt-3">
                <h3 className="heading">Cambios rápidos</h3>
              </div>
              <div className="form-group row m-3 align-items-start">
                <div className="col-md-5">
                  <div className="form-holder">
                    <Tooltip
                      placement="top"
                      isOpen={this.state.tooltipAspiracionOpen}
                      target="tooltipAspiracion"
                      toggle={this.toggleTooltipAspiracion}
                    >
                      Sólo trabajamos en base a costos mensuales, si desea
                      ingresar un monto por horas no se postule
                    </Tooltip>
                    <Label id="tooltipAspiracion">
                      Aspiración salarial MENSUAL en dolares USD
                      <FaStickyNote
                        style={{ marginLeft: "5px", color: "#666" }}
                      />
                    </Label>
                    <Input
                      type="number"
                      name="aspiracion"
                      value={this.state.aspiracion}
                      id="aspiracion"
                      className="form-control"
                      pattern="[^@]+@[^@]+.[a-zA-Z]{2,6}"
                      placeholder="Monto en dolares"
                      required
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-holder">
                    <Tooltip
                      placement="top"
                      isOpen={this.state.tooltipDisponibilidadOpen}
                      target="tooltipDisponibilidad"
                      toggle={this.toggleTooltipDisponibilidad}
                    >
                      Sólo trabajamos con especialistas con disponibilidad a
                      tiempo completo, si no la tiene igual registrese y cuando
                      si tenga disponibilidad entre al sistema y cambie esta
                      casilla
                    </Tooltip>
                    <Label id="tooltipDisponibilidad">
                      disponibilidad para trabajar 8h diarias?
                      <FaStickyNote
                        style={{ marginLeft: "5px", color: "#666" }}
                      />
                    </Label>

                    <div className="caja-select">
                      <Input
                        type="select"
                        name="disponibilidad"
                        value={this.state.disponibilidad}
                        id="disponibilidad"
                        onChange={this.handleChangeSelect}
                      >
                        <option value="Si, si tengo disponibilidad para trabajo FULL TIME">
                          Si, si tengo disponibilidad para trabajo FULL TIME
                        </option>
                        <option value="No tengo, pero apenas tenga disponibilidad lo indicare">
                          No tengo, pero apenas tenga disponibilidad lo indicare
                        </option>
                      </Input>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Label>&nbsp;</Label>
                  <Button color="success" onClick={this.cambiosrapidos}>
                    Guardar
                  </Button>
                </div>

                <div className="col-12">
                  <Alert
                    color={this.colorCambiosRapidos}
                    isOpen={this.state.openAlertCambiosRapidos}
                    toggle={this.closeAlerts}
                  >
                    {this.state.mensajeCambiosRapidos}
                  </Alert>
                </div>
              </div>
            </div>
          )}

        <div className="wizard-header mt-3">
          <h3 className="heading">Mensajería</h3>
        </div>
        <div className="form-group row m-3">
          <div
            className="col-12"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              textAlign: "justify",
            }}
          >
            <Label>
              <b>Contacto con administrador</b>, Si tienes dudas y te quieres
              comunicar con el equipo administrativo de Hispanos Soluciones C.A.
              escribe aqui y recibirás respuesta a través de esta plataforma.
              Una vez hayas llenado todos tus datos e indicado que si tienes
              disponibilidad para trabajar a tiempo completo con nosotros serás
              evaluado junto con los otros postulantes para otorgarte un
              proyecto, se ponderaran tanto habilidades técnicas / experiencia
              como aspiración salarial mensual en dolares, recuerda que sólo
              trabajamos a tiempo completo.
            </Label>
          </div>
          <div
            className="col-12"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            {this.state.arrayMensajes.length === 0 && (
              <Label>
                <b>No ha enviado Mensajes: </b>
              </Label>
            )}
            {this.state.arrayMensajes.length > 0 && (
              <Label>
                <b>Mensajes: </b>
              </Label>
            )}
          </div>
          <div className="col-12">
            {this.state.arrayMensajes.map((msg, key) => (
              <Alert
                color={msg.creador === "programador" ? "info" : "success"}
                key={key}
              >
                {msg.creador === "programador" && (
                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    className="text-left"
                  >
                    <span>
                      <b>Yo: </b>
                      <label style={{ fontSize: 13 }}>
                        {moment(new Date(msg.fecha)).fromNow()}
                      </label>
                    </span>
                    <span>{msg.texto}</span>
                  </div>
                )}
                {msg.creador === "admin" && (
                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    className="text-lef"
                  >
                    <span>
                      <b>Aministrador</b>{" "}
                      <label style={{ fontSize: 13 }}>
                        {moment(new Date(msg.fecha)).fromNow()}
                      </label>
                    </span>
                    <span>{msg.texto}</span>
                  </div>
                )}
              </Alert>
            ))}
          </div>
          <div className="col-12">
            <div className="form-holder">
              <Input
                type="textarea"
                name="mensaje"
                id="mensaje"
                cols="35"
                rows="4"
                value={this.state.mensaje}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="col-12">
            <Alert
              color={this.state.tipoEnviarMensaje}
              isOpen={this.state.openEnviarMensaje}
              toggle={this.closeAlerts}
            >
              {this.state.messageEnviarMensaje}
            </Alert>
          </div>

          <div className="col-12">
            <div className="form-holder">
              <Button color="success" onClick={this.enviarMensaje}>
                Enviar mensaje
              </Button>
            </div>
          </div>
        </div>

        <div className="form-group row my-0 mt-5">
          <div className="col-12">
            <ul
              className="nav nav-tabs  d-flex align-items-center justify-content-between"
              role="tablist"
            >
              <li className="nav-item">
                <Button
                  color="success"
                  id="btn_prev_generales"
                  className="btn btn-success rounded-circle btn-previous"
                  onClick={() => {
                    this.changeStep("3");
                  }}
                >
                  <i className="zmdi zmdi-arrow-left"></i>
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    perfil: state.perfil,
    //token: state.token
  };
};

const mapDispatchToProps = {
  setPerfil,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabFinalizado);
// export default TabFinalizado;
