import React, { Component } from 'react';
import { Input, Label, Button, Alert, CustomInput, Progress, Card, CardBody, CardTitle, CardImg, 
    Modal, ModalHeader, ModalBody, ModalFooter   } from 'reactstrap';
import firebase from '../firebase/firebase';
import {Typeahead} from 'react-bootstrap-typeahead'; 
import 'react-bootstrap-typeahead/css/Typeahead.css';
import mocks from '../mocks/data';
import Chip from './chip';
import StarRatings from 'react-star-ratings';
import moment from 'moment'; 

import { setPerfil } from '../actions';
import { connect }  from  'react-redux';
import { FaTrash, FaImage } from 'react-icons/fa'; 

class TabDatosTecnicos extends Component {
    constructor(props) {    
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeNumeric = this.handleChangeNumeric.bind(this);
        this.handleChangeAutocomplete = this.handleChangeAutocomplete.bind(this);
        this._handleSearch = this._handleSearch.bind(this);
        this.saveTecnologia = this.saveTecnologia.bind(this);
        this.saveExperiencia = this.saveExperiencia.bind(this);
        this.saveLink = this.saveLink.bind(this);
        this.saveCurso = this.saveCurso.bind(this);
        this.changeStep = this.changeStep.bind(this);
        this.closeAlerts = this.closeAlerts.bind(this);
        this.terminarPostulacion = this.terminarPostulacion.bind(this);
        this.remove = this.remove.bind(this);
        this.selected = this.selected.bind(this);
        this.cancelEditTecnologia = this.cancelEditTecnologia.bind(this);
        this.cancelEditExperiencia = this.cancelEditExperiencia.bind(this);
        this.cancelEditLink = this.cancelEditLink.bind(this);
        this.cancelEditCurso = this.cancelEditCurso.bind(this);
        this.guardarInformacionPersonal = this.guardarInformacionPersonal.bind(this);
        //this.enviarMensaje = this.enviarMensaje.bind(this);
        this.changeRating = this.changeRating.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
        this.openImage = this.openImage.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        
        this.state = {
          puedeAvanzar: true,
          tecnologia: '',
          tecnologiaDominio: '',
          tecnologiaVersion: '',
          tecnologiaAños: '',
          tecnologiaTipo: 'Años',
          dominio: 0,
          empresaNombre: '',
          empresaDescripcion: '',
          link: '',
          linkDescripcion: '',
          cursoNombre: '',
          cursoDescripcion: '',
          messageTecnologias: '' ,
          messageCursos: '' ,
          messageExeriencias: '' ,
          messageLinks: '' ,
          openTecnologias: false ,
          openCursos: false ,
          openExeriencias: false ,
          openLinks: false, 
          tipoErrTecnologias: 'success' ,
          tipoErrCursos: 'success' ,
          tipoErrExeriencias: 'success' ,
          tipoErrLinks: 'success',
          arrayTecnologias: this.props.perfil.tecnologias.sort(this.compare),
          arrayExpericncias: this.props.perfil.experiencias,
          arrayLinks: this.props.perfil.links,
          arrayCursos: this.props.perfil.cursos,
          arrayImagenes: this.props.perfil.imagenes || [],
          historico: this.props.perfil.historico,
          multiple: false,
          options: [],
          editTecnologia: false,
          indexTecnologia: -1,
          editExperiencia: false,
          indexExperiencia: -1,
          editLink: false,
          indexLink: -1,
          editCurso: false,
          indexCurso: -1,

          categorias: [],
          selectedTecnologie: [],

          datosPersonales: this.props.perfil.datosPersonales,
          messageGuardarInfo: '' ,
          openGuardarInfo: false ,
          tipoGuardarInfo: 'success',
          arrayMensajes: this.props.perfil.mensajes,
          mensaje: '',
          messageEnviarMensaje: '' ,
          openEnviarMensaje: false ,
          tipoEnviarMensaje: 'success',
          rating: 0,
          uploadValue: 0,
          messageUpload: '',
          typeErrorUpload: 'success',
          openAlertUpload: false,
          openmodal: false,
          ulrImage: '',
          nameImage: ''
        }
    }

    componentDidMount(){
        this.loadTecnologias();
        this.validateData();
    }

    validateData(){
        const tecnologias = this.state.arrayTecnologias;
        const experiencias = this.state.arrayExpericncias;
        const links = this.state.arrayLinks;
        if(tecnologias.length === 0 || experiencias.length === 0){
            firebase.db.collection("programadores").doc(`${firebase.auth.currentUser.uid}`).update({datosCompletos: false, estado: 'Iniciado'});
            this.setState({puedeAvanzar: false });
        } else {
            let dataUpdate;
            if(this.props.perfil.estado === 'Iniciado'){
                dataUpdate = {
                    datosCompletos: true, estado: 'No revisado'
                }
            } else{
                dataUpdate = {
                    datosCompletos: true
                }
            }
            firebase.db.collection("programadores").doc(`${firebase.auth.currentUser.uid}`).update(dataUpdate);
            this.setState({puedeAvanzar: true });
        }
    }

    showError () {
        const tecnologias = this.state.arrayTecnologias;
        const experiencias = this.state.arrayExpericncias;
        let mensaje = '';
        if(tecnologias.length === 0) {
            mensaje = 'Debe seleccionar al menos una Tecnología';
        } else if(experiencias.length === 0){
            mensaje = 'Debe seleccionar al menos una Experiencia Laboral';
        }
        return mensaje;
    }

    loadTecnologias(){
        firebase.db.collection("tecnologias")
           .orderBy("name")
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => doc.data());
                this.setState({ options: data })
            });
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    handleChangeNumeric = (e) => {
        let value = e.target.value;
        if(value !== ''){
            if (!Number(value)) {
                return;
            }
        }
        this.setState({ [e.target.name]: value });
    }

    handleChangeAutocomplete = (e) => {
        e.forEach((item, i) => {
            if (item.customOption) {
                let filter = this.state.options.filter(el => {
                    return el.name.toLowerCase() === item.name.toLowerCase()
                });
                if(filter.length === 0){
                    firebase.db.collection('tecnologias').add({name: item.name});
                    let ops = [];
                    ops = this.state.options;
                    ops.push({name: item.name});
                    this.setState({tecnologia: item.name, options: ops})
                } else {
                    this._typeahead.state.selected = [];
                    this._typeahead.state.selected.push(filter[0].name)
                    this._typeahead.state.text = filter[0].name;
                    this.setState({tecnologia: filter[0].name})
                }
            } else {
                this.setState({tecnologia: item.name})
            } 
        })
    }

    _handleSearch(text){
        let value = text;
        if(value.indexOf(',') !== -1){
            const array = text.split(',');
            value = array[0];
            let search = [];
            search = this.state.options.filter(el => el.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
            if(search.length === 0){
                this._typeahead._handleSelectionAdd({customOption: true, id: Math.random().toString(20).substr(2, 6), name: search[0].name})
            } else{
                this._typeahead._handleSelectionAdd({name: search[0].name})
            }
        }
    }

    terminarPostulacion(){
        let newProfile = Object.assign({}, this.props.perfil, {registroCompletado: true, ultimaPestana: '4'});
        firebase.db.collection("programadores").doc(`${firebase.auth.currentUser.uid}`).update({registroCompletado: true})
            .then(reesp =>{
                this.props.setPerfil(newProfile);
            })
        this.changeStep('4');
        window.scrollTo(0, 0)
    }

    saveTecnologia(){
            const self = this;
            if(this.state.tecnologia === ''|| this.state.tecnologiaAños === '' || this.state.dominio === 0 ){
                this.setState({ messageTecnologias: 'Llene todos los datos de tecnologia', tipoErrTecnologias: 'danger', openTecnologias: true  })
                setTimeout(function(){ self.setState({openTecnologias: false}) }, 3000);
            } else {
                if(this.buscarTecnologiaByName(this.state.tecnologia) && this.state.editTecnologia === false ){
                    this.setState({ messageTecnologias: 'Esta Tecnología ya ha sido agregada anteriormente', tipoErrTecnologias: 'danger', openTecnologias: true  })
                } else {
                    const tecnologia = {
                            tecnologia: this.state.tecnologia,
                            tecnologiaDominio: this.state.tecnologiaDominio,
                            tecnologiaVersion: this.state.tecnologiaVersion,
                            tecnologiaAños: this.state.tecnologiaAños,
                            tecnologiaTipo: this.state.tecnologiaTipo,
                            dominio: this.state.dominio
                    }
                    let array = this.state.arrayTecnologias;
                    let arrayHistorico = [];
                    arrayHistorico = this.state.historico;
                    let accionCustom;
                    let antes;
                    if(this.state.editTecnologia === false){
                        array.push(tecnologia)
                        accionCustom = `Ingresó nueva tecnología ${this.state.tecnologia}, nivel (1-5) ${this.state.dominio}, tiempo de experiencia: ${this.state.tecnologiaAños} ${this.state.tecnologiaTipo}`
                    } else {
                        antes = array[this.state.indexTecnologia];
                        array[this.state.indexTecnologia] = tecnologia;
                        accionCustom = `Actualizo tecnología ${tecnologia.tecnologia}`;
                        if(antes.dominio !== tecnologia.dominio) {
                            accionCustom += `, dominio cambio de ${antes.dominio} a ${tecnologia.dominio}`;
                        } 
                        if(antes.tecnologiaAños !== tecnologia.tecnologiaAños || antes.tecnologiaTipo !== tecnologia.tecnologiaTipo){
                            accionCustom += `, tiempo de experiancia cambio: de ${antes.tecnologiaAños} ${antes.tecnologiaTipo} a ${tecnologia.tecnologiaAños} ${tecnologia.tecnologiaTipo}`;
                        }
                    }
                    if(this.props.perfil.primerasesioncerrada === true && this.props.perfil.registroCompletado === true){
                        arrayHistorico.push(
                        { 
                            fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                            accion: accionCustom 
                        });
                    }
                    let newProfile = Object.assign({}, this.props.perfil, {tecnologias: array, historico: arrayHistorico});
                    firebase.db.collection("programadores").doc(`${firebase.auth.currentUser.uid}`)
                    .update({tecnologias: array, historico: arrayHistorico})
                    .then(resp => {
                        this.props.setPerfil(newProfile);
                        if(this.state.editTecnologia === true){
                            this.setState({messageTecnologias: 'Tecnología actualizada exitosamente'})
                        } else {
                            this.setState({messageTecnologias: 'Tecnología agregada exitosamente'})
                        }
                        this.setState({
                            tipoErrTecnologias: 'success',
                            tecnologia: '',
                            tecnologiaDominio: '',
                            tecnologiaVersion: '',
                            tecnologiaAños: '',
                            tecnologiaTipo: 'Años',
                            dominio: 0,
                            openTecnologias: true,
                            editTecnologia: false
                        });
                        this._typeahead.clear()
                        this.getData();
                        this.validateData();
                        setTimeout(function(){ self.setState({openTecnologias: false}) }, 3000);
                    }).catch(err => {
                        console.log(err.message);
                    });
                }
        }
    }

    buscarTecnologiaByName(name){
        let filter = this.state.arrayTecnologias.filter(row => row.tecnologia === name);
        if(filter.length === 0){
            return false;
        } else if (filter.length > 0){
            return true;
        }
    }

    deleteTecnologia(itemEliminar){
        const self = this;
        let array = this.state.arrayTecnologias;
        let arrayHistorico = [];
        arrayHistorico = this.state.historico;
        if(this.props.perfil.primerasesioncerrada === true && this.props.perfil.registroCompletado === true){
            arrayHistorico.push(
            { 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                accion: `Eliminó tecnología ${itemEliminar.tecnologia}`
            });
        }
        let newProfile = Object.assign({}, this.props.perfil, {tecnologias: array, historico: arrayHistorico});
        firebase.db.collection("programadores").doc(`${firebase.auth.currentUser.uid}`)
                .update({tecnologias: array, historico: arrayHistorico })
                .then(resp => {
                    this.props.setPerfil(newProfile);
                    this.setState({
                        messageTecnologias: 'Tecnologia eliminada exitosamente', 
                        tipoErrTecnologias: 'success',
                        tecnologia: '',
                        tecnologiaDominio: '',
                        tecnologiaVersion: '',
                        tecnologiaAños: '',
                        openTecnologias: true,
                        dominio: 0
                    })
                    this.validateData();
                    this._typeahead.clear();
                    this.getData();
                    setTimeout(function(){ self.setState({openTecnologias: false}) }, 3000);
                }).catch(err => {
                    console.log(err.message);
                });
    }

    saveExperiencia(){
            const self = this;
            if(this.state.empresaNombre === ''|| this.state.empresaDescripcion === '' ){
                this.setState({ messageExeriencias: 'Llene todos los datos de experiencia', tipoErrExeriencias: 'danger', openExeriencias: true  })
            } else {
                const experiencia = {
                    empresaNombre: this.state.empresaNombre,
                    empresaDescripcion: this.state.empresaDescripcion,
                }
                let array = this.state.arrayExpericncias;
                let arrayHistorico = [];
                arrayHistorico = this.state.historico;
                let customAccion;
                let antes;
                if(this.state.editExperiencia === false){
                    array.push(experiencia)
                    customAccion = `Ingreso nueva experiencia laboral ${this.state.empresaNombre}`;
                } else {
                    antes = array[this.state.indexExperiencia];
                    array[this.state.indexExperiencia] = experiencia
                    customAccion = `Actualizo experiencia laboral antes ${antes.empresaNombre} luego ${experiencia.empresaNombre}`;
                }
                if(this.props.perfil.primerasesioncerrada === true && this.props.perfil.registroCompletado === true){
                    arrayHistorico.push(
                    { 
                        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                        accion: customAccion 
                    });
                }
                let newProfile = Object.assign({}, this.props.perfil, {experiencias: array, historico: arrayHistorico});
                firebase.db.collection("programadores").doc(`${firebase.auth.currentUser.uid}`)
                    .update({experiencias: array, historico: arrayHistorico})
                    .then(resp => {
                        this.props.setPerfil(newProfile);
                        if(this.state.editExperiencia === true){
                            this.setState({messageExeriencias: 'Experiancia actualizada exitosamente'})
                        } else {
                            this.setState({messageExeriencias: 'Experiancia agregada exitosamente'})
                        }
                        this.setState({
                            tipoErrExeriencias: 'success',
                            empresaNombre: '',
                            empresaDescripcion: '',
                            openExeriencias: true,
                            editExperiencia: false
                        })
                        this.validateData();
                        this.getData();
                        setTimeout(function(){ self.setState({openExeriencias: false}) }, 3000);
                    }).catch(err => {
                        console.log(err.message);
                    });
            }
    }

    deleteExperiencia(itemEliminar){
        const self = this;
        let array = this.state.arrayExpericncias;
        let arrayHistorico = [];
        arrayHistorico = this.state.historico;
        if(this.props.perfil.primerasesioncerrada === true && this.props.perfil.registroCompletado === true){
            arrayHistorico.push(
            { 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                accion: `Eliminó Experiancia laboral ${itemEliminar.empresaNombre}`
            });
        }
        let newProfile = Object.assign({}, this.props.perfil, {experiencias: array, historico: arrayHistorico});
        firebase.db.collection("programadores").doc(`${firebase.auth.currentUser.uid}`)
                .update({experiencias: array, historico: arrayHistorico})
                .then(resp => {
                    this.props.setPerfil(newProfile)
                    this.setState({
                        messageExeriencias: 'Experiancia eliminada exitosamente', 
                        tipoErrExeriencias: 'success',
                        tecnologia: '',
                        empresaNombre: '',
                        empresaDescripcion: '',
                        openExeriencias: true
                    })
                    this.validateData();
                    this.getData();
                    setTimeout(function(){ self.setState({openExeriencias: false}) }, 3000);
                }).catch(err => {
                    console.log(err.message);
                });
    }

    saveLink(){
        const self = this;
        if(this.state.link === '' ){
            this.setState({ messageLinks: 'Llene todos los datos de link', tipoErrLinks: 'danger', openLinks: true  })
        } else if(!this.isValidURL(this.state.link)) {
            this.setState({ messageLinks: 'Link no valido, debe ingresar una url valida relacionado con su perfil profesional (portafalios, repositorios, url de proyectos y otros..)', tipoErrLinks: 'danger', openLinks: true  })
        } else {
            const link = {
                link: this.state.link,
                linkDescripcion: this.state.linkDescripcion
            }
            let array = this.state.arrayLinks;
            let arrayHistorico = [];
            arrayHistorico = this.state.historico;
            let customAccion;
            let antes;
            if(this.state.editLink === false){
                array.push(link)
                customAccion = `Ingresó nuevo Link ${link.link}`;
            } else {
                antes = array[this.state.indexLink];
                array[this.state.indexLink] = link;
                customAccion = `Actualizo un link antes ${antes.link} luego ${link.link}`;
            }
            
            if(this.props.perfil.primerasesioncerrada === true && this.props.perfil.registroCompletado === true){
                arrayHistorico.push(
                { 
                    fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                    accion: customAccion 
                });
            }
            let newProfile = Object.assign({}, this.props.perfil, {links: array, historico: arrayHistorico});
            firebase.db.collection("programadores").doc(`${firebase.auth.currentUser.uid}`)
                .update({links: array, historico: arrayHistorico})
                .then(resp => {
                    this.props.setPerfil(newProfile);
                    this.setState({
                        messageLinks: 'Link agregado exitosamente',
                        tipoErrLinks: 'success',
                        link: '',
                        linkDescripcion: '',
                        openLinks: true
                    })
                    this.validateData();
                    this.getData();
                    setTimeout(function(){ self.setState({openLinks: false}) }, 3000);
                }).catch(err => {
                    console.log(err.message);
                });
        }
    }

    isValidURL(url){
        var RegExp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        if(RegExp.test(url)){
            return true;
        }else{
            return false;
        }
    } 

    deleteLink(itemEliminar){
        const self = this;
        let array = this.state.arrayLinks;
        let arrayHistorico = [];
        arrayHistorico = this.state.historico;
        if(this.props.perfil.primerasesioncerrada === true && this.props.perfil.registroCompletado === true){
            arrayHistorico.push(
            { 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                accion: `Eliminó Link ${itemEliminar.link}`
            });
        }
        let newProfile = Object.assign({}, this.props.perfil, {links: array, historico: arrayHistorico});
        firebase.db.collection("programadores").doc(`${firebase.auth.currentUser.uid}`)
                .update({links: array, historico: arrayHistorico})
                .then(resp => {
                    this.props.setPerfil(newProfile);
                    this.setState({
                        messageLinks: 'Link eliminado exitosamente', 
                        tipoErrLinks: 'success',
                        link: '',
                        linkDescripcion: '',
                        openLinks: true
                    })
                    this.validateData();
                    this.getData();
                    setTimeout(function(){ self.setState({openLinks: false}) }, 3000);
                }).catch(err => {
                    console.log(err.message);
                });
    }

    saveCurso(){
        const self = this;
        if(this.state.cursoNombre === '' ){
            this.setState({ messageCursos: 'Llene todos los datos de curso', tipoErrCursos: 'danger', openCursos: true  })
        } else {
            const curso = {
                cursoNombre: this.state.cursoNombre,
                cursoDescripcion: this.state.cursoDescripcion
            }
            let array = this.state.arrayCursos;
            let arrayHistorico = [];
            arrayHistorico = this.state.historico;
            let customAccion;
            let antes;
            if(this.state.editCurso === false){
                array.push(curso)
                customAccion = `Ingresó un nuevo curso ${curso.cursoNombre}`;
            } else {
                antes =  array[this.state.indexCurso];
                array[this.state.indexCurso] = curso;
                customAccion = `Actualizo un link antes ${antes.cursoNombre} luego ${curso.cursoNombre}`;
            }
            if(this.props.perfil.primerasesioncerrada === true && this.props.perfil.registroCompletado === true){
                arrayHistorico.push(
                { 
                    fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                    accion: customAccion 
                });
            }
            let newProfile = Object.assign({}, this.props.perfil, {cursos: array, historico: arrayHistorico});
            firebase.db.collection("programadores").doc(`${firebase.auth.currentUser.uid}`)
                .update({cursos: array, historico: arrayHistorico})
                .then(resp => {
                    this.props.setPerfil(newProfile);
                    this.setState({
                        messageCursos: 'Curso agregado exitosamente',
                        tipoErrCursos: 'success',
                        cursoNombre: '',
                        cursoDescripcion: '',
                        openCursos: true,
                        editCurso: false
                    })
                    this.validateData();
                    this.getData();
                    setTimeout(function(){ self.setState({openCursos: false}) }, 3000);
                }).catch(err => {
                    console.log(err.message);
                });
        }
    }

    deleteCurso(itemEliminar){
        const self = this;
        let array = this.state.arrayCursos;
        let arrayHistorico = [];
        arrayHistorico = this.state.historico;
        if(this.props.perfil.primerasesioncerrada === true && this.props.perfil.registroCompletado === true){
            arrayHistorico.push(
            { 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                accion: `Eliminó Curso ${itemEliminar.cursoNombre}`
            });
        }
        let newProfile = Object.assign({}, this.props.perfil, {cursos: array, historico: arrayHistorico});
        firebase.db.collection("programadores").doc(`${firebase.auth.currentUser.uid}`)
                .update({cursos: array, historico: arrayHistorico})
                .then(resp => {
                    this.props.setPerfil(newProfile);
                    this.setState({
                        messageCursos: 'Curso eliminado exitosamente', 
                        tipoErrCursos: 'success',
                        cursoNombre: '',
                        cursoDescripcion: '',
                        openCursos: true
                    })
                    this.validateData();
                    this.getData();
                    setTimeout(function(){ self.setState({openCursos: false}) }, 3000);
                }).catch(err => {
                    console.log(err.message);
                });
    }

    changeStep(tab){
        firebase.db.collection("programadores").doc(`${firebase.auth.currentUser.uid}`)
        .update({ultimaPestana: tab})
        .then(querySnapshot => {
            this.props.stepTecnicos(tab);
        });
    }

    closeAlerts(){
        this.setState({
            openTecnologias: false,
            openCursos: false,
            openExeriencias: false,
            openLinks: false,
            openGuardarInfo: false,
            openEnviarMensaje: false,
            openAlertUpload: false,
            uploadValue: 0 
        })
    }

    getData(){
        let datos;
        firebase.db.collection("programadores").doc(`${firebase.auth.currentUser.uid}`)
        .get()
        .then(doc => {
            datos = doc.data();
            this.props.setPerfil(datos);
            this.setState({
                arrayTecnologias: datos.tecnologias.sort(this.compare),
                arrayExpericncias: datos.experiencias,
                arrayLinks: datos.links,
                arrayCursos: datos.cursos,
                historico: datos.historico,
                arrayImagenes: datos.imagenes ? datos.imagenes : []
            });
        });

    }

    compare(a, b) {
        const tecnologiaA = a.tecnologia.toUpperCase();
        const tecnologiaB = b.tecnologia.toUpperCase();
        let comparison = 0;
        if (tecnologiaA > tecnologiaB) {
          comparison = 1;
        } else if (tecnologiaA < tecnologiaB) {
          comparison = -1;
        }
        return comparison;
    }

    remove(e){
        switch (e.type) {
            case 'tecnologias':
                let array = this.state.arrayTecnologias;
                const item = array[e.index];
                array.splice(e.index, 1);
                this.setState({
                    arrayTecnologias: array
                })
                this.deleteTecnologia(item);
              break;
            case 'experiencias':
                let array2 = this.state.arrayExpericncias;
                const item2 = array2[e.index];
                array2.splice(e.index, 1);
                this.setState({
                    arrayExpericncias: array2
                })
                this.deleteExperiencia(item2);
              break;
            case 'links':
                let array3 = this.state.arrayLinks;
                const item3 = array3[e.index];
                array3.splice(e.index, 1);
                this.setState({
                    arrayLinks: array3
                })
                this.deleteLink(item3);
              break;
            case 'cursos':
                let array4 = this.state.arrayCursos;
                const item4 = array4[e.index];
                array4.splice(e.index, 1);
                this.setState({
                    arrayCursos: array4
                })
                this.deleteCurso(item4);
                break;
            default:
              //Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
              break;
        }
    }

    selected(e){
        switch (e.type) {
            case 'tecnologias':
                this._typeahead.state.selected = [];
                this._typeahead.state.selected.push(this.state.arrayTecnologias[e.index].tecnologia)
                this.setState({
                    indexTecnologia: e.index,
                    editTecnologia: true,
                    tecnologia: this.state.arrayTecnologias[e.index].tecnologia,
                    tecnologiaDominio: this.state.arrayTecnologias[e.index].tecnologiaDominio,
                    tecnologiaVersion: this.state.arrayTecnologias[e.index].tecnologiaVersion,
                    tecnologiaAños: this.state.arrayTecnologias[e.index].tecnologiaAños,
                    tecnologiaTipo: this.state.arrayTecnologias[e.index].tecnologiaTipo,
                    dominio: this.state.arrayTecnologias[e.index].dominio
                })
              break;
            case 'experiencias':
                this.setState({
                    indexExperiencia: e.index,
                    editExperiencia: true,
                    empresaNombre: this.state.arrayExpericncias[e.index].empresaNombre,
                    empresaDescripcion: this.state.arrayExpericncias[e.index].empresaDescripcion,
                })
              break;
            case 'links':
              this.setState({
                indexLink: e.index,
                editLink: true,
                link: this.state.arrayLinks[e.index].link,
                linkDescripcion: this.state.arrayLinks[e.index].linkDescripcion,
            })
              break;
            case 'cursos':
                this.setState({
                    indexCurso: e.index,
                    editCurso: true,
                    cursoNombre: this.state.arrayCursos[e.index].cursoNombre,
                    cursoDescripcion: this.state.arrayCursos[e.index].cursoDescripcion,
                })
                break;
            default:
              //Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
              break;
        }
    }

    cancelEditTecnologia(e){
        this._typeahead.clear();
        this.setState({
            editTecnologia: false,
            tecnologia: '',
            tecnologiaDominio: '',
            tecnologiaVersion: '',
            tecnologiaAños: '',
            dominio: 0
        })
    }

    cancelEditExperiencia(e){
        this.setState({
            editExperiencia: false,
            empresaNombre: '',
            empresaDescripcion: '',
        })
    }

    cancelEditLink(e){
        this.setState({
            editLink: false,
            link: '',
            linkDescripcion: '',
        })
    }

    cancelEditCurso(e){
        this.setState({
            editCurso: false,
            cursoNombre: '',
            cursoDescripcion: '',
        })
    }

    guardarInformacionPersonal(e){
        const self = this;
        if(this.state.datosPersonales === ''){
            this.setState({ messageGuardarInfo: 'Debe llenar el campo Cuentanos sobre ti', tipoGuardarInfo: 'danger', openGuardarInfo: true  })
        } else {
            firebase.db.collection("programadores").doc(`${firebase.auth.currentUser.uid}`)
            .update({datosPersonales: this.state.datosPersonales})
            .then(querySnapshot => {
                this.setState({
                    messageGuardarInfo: 'Información personal actualizada',
                    tipoGuardarInfo: 'success',
                    openGuardarInfo: true,
                })
                this.getData();
                setTimeout(function(){ self.setState({openGuardarInfo: false}) }, 3000);
            });
        }
    }

    changeRating( newRating, name ) {
        this.setState({
          rating: newRating,
          dominio: newRating,
        });
    }

    handleUpload(e){
        const self = this;
        const file = e.target.files[0];
        const fileName = file.name
        const idxDot = fileName.lastIndexOf(".") + 1;
        const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="svg" || extFile==="bmp" || extFile==="tiff"  ){
            const storageRef = firebase.storage.ref(`pictures/${file.name}`);
            const task = storageRef.put(file);
            let array = [];
            array = this.state.arrayImagenes;
            let arrayHistorico = [];
            arrayHistorico = this.props.perfil.historico;

            task.on('state_changed', snapshot => { // mientras se sube
                let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                self.setState({ uploadValue: percentage});
            }, error => { // si hubo un error
                console.log(error.message)
                self.setState({ messageUpload: 'ha ocurrido un error al subir la imagen', typeErrorUpload: 'danger', openAlertUpload: true });
            }, () => { // si todo bien
                self.setState({
                    messageUpload: 'Imagen subida exitosamente', 
                    typeErrorUpload: 'success', 
                    openAlertUpload: true ,
                    arrayImagenes: array 
                }); 
                if(self.props.perfil.primerasesioncerrada === true && self.props.perfil.registroCompletado === true){
                    arrayHistorico.push({ 
                        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                        accion: 'Ha subido una nueva imagen de un proyecto' 
                    });
                }
                task.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    array.push({  
                        name: file.name,
                        url: downloadURL,
                        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
                    });
                    let newProfile = Object.assign({}, this.props.perfil, {imagenes: array, historico: arrayHistorico});
                    firebase.db.collection("programadores").doc(`${firebase.auth.currentUser.uid}`)
                        .update({imagenes: array, historico: arrayHistorico})
                        .then(resp => {
                            self.props.setPerfil(newProfile);
                            self.validateData();
                            self.getData();
                            //setTimeout(function(){ self.setState({openCursos: false}) }, 3000);
                        }).catch(err => {
                            console.log(err.message);
                        });
                });
            })
        }else{
            self.setState({
                messageUpload: 'El archivo que intentas subir no es una imagen, o no corresponde con los siguintes formatos: jpg, jpeg, png, gif, svg, BMP, TIFF',
                typeErrorUpload: 'danger', 
                openAlertUpload: true ,
            });
        }   
    }

    deleteImage(e, index, file){
        const self = this;
        let arrayHistorico = [];
        arrayHistorico = this.props.perfil.historico;
        if(self.props.perfil.primerasesioncerrada === true && self.props.perfil.registroCompletado === true){
            arrayHistorico.push({ 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                accion: 'Elimino una imagen de un proyecto' 
            });
        }
        let images = [];
        images = [...this.state.arrayImagenes];
        images.splice( index, 1 );
        const storageRef = firebase.storage.ref(`pictures/${file.name}`);
        // Delete the file
        storageRef.delete()
        .then(() => {
            self.setState({ 
                messageUpload: 'Imagen eliminada exitosamente', 
                typeErrorUpload: 'success', 
                openAlertUpload: true,
                arrayImagenes: images 
            });
            let newProfile = Object.assign({}, this.props.perfil, {imagenes: images, historico: arrayHistorico});
            firebase.db.collection("programadores").doc(`${firebase.auth.currentUser.uid}`)
            .update({imagenes: images, historico: arrayHistorico})
            .then(resp => {
                self.props.setPerfil(newProfile);
                self.validateData();
                self.getData();
            }).catch(err => {
                console.log(err.message);
            });
        }).catch((error) => {
            self.setState({ messageUpload: 'Ha ocurrido un error al eliminar la imagen', typeErrorUpload: 'danger', openAlertUpload: true });
            console.log(error.message);
        });
    }

    openImage(e, img){
        this.setState({ nameImage: img.name, ulrImage: img.url, openmodal: true });
    }

    toggleModal(){
        this.setState({ openmodal: !this.state.openmodal })
    }

    render(){
        return(
            <div>
                <div className="wizard-header">
                    <h3 className="heading">Datos Tecnicos</h3>
                    <p>
                        Por favor ingrese la siguiente informaci&oacute;n de su perfil profesional
                    </p>
                    <p><b>Importante llenar todos los datos obligatorios *, de lo contrariotu su perfil no sera evaluado</b></p>
                </div>
                <h4>Tecnologías <span style={{color: '#dc3545'}}>*</span></h4>
    
                <div className="form-group row m-0">
                    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                        <div className="form-holder">
    
                            <Label>Tecnología <b>(SELECCIONE UNA A LA VEZ)</b></Label>
                            <Typeahead
                                clearButton
                                id="tecnologia"
                                labelKey="name"
                                defaultSelected={this.state.selectedTecnologie}
                                emptyLabel="No se encontraron coincidencias."
                                newSelectionPrefix="Añadir tecnología: "
                                options={this.state.options}
                                placeholder="Ej: (Wordpress) (ReactJS) (PHP) (Django) (MySQL) (.NET) (Java EE) (SQL Server) (Laravel) (HTML5) (CSS3) (ES6)..." 
                                value={this.state.tecnologia} 
                                onChange={this.handleChangeAutocomplete}
                                onInputChange={this._handleSearch}
                                ref={(ref) => this._typeahead = ref}
                                paginationText="Ver mas resultados"
                            />
    
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <div className="form-holder">
                            <Label>
                                Tiempo de experiencia
                            </Label>
                            <div className="row">
                                <div className="col-5"> 
                                    <div className="caja-select">
                                        <Input  type="select" name="tecnologiaTipo" id="tecnologiaTipo" value={this.state.tecnologiaTipo} onChange={this.handleChange}  >
                                            <option value="Mes">Mes</option>
                                            <option value="Años">Años</option>
                                        </Input>
                                    </div>
                                </div>
                                <div className="col-4">                       
                                    <Input type="number" className="form-control" id="tecnologiaAños" name="tecnologiaAños"
                                        value={this.state.tecnologiaAños} onChange={this.handleChangeNumeric}  />                              
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group row m-0">
                    <div className="col-md-6">
                        <div className="form-holder">
                            <Label>
                                Dominio
                            </Label>
                            <div>
                                <StarRatings
                                    rating={this.state.dominio}
                                    starRatedColor="rgb(10,28,70)"
                                    starHoverColor="rgb(62,64,97)"
                                    changeRating={this.changeRating}
                                    numberOfStars={5}
                                    name='rating'
                                    starDimension='40px'
                                />
                            </div>
                        </div>

                        

                    </div>
                    <div className="col-md-6">
                        <div className="form-holder">
    
                            <Label>Version (Opcional)</Label>
                            <Input type="text" className="form-control" id="tecnologiaVersion" name="tecnologiaVersion" placeholder="Ej: 1.1,2.2,5" required
                                value={this.state.tecnologiaVersion} onChange={this.handleChange}  />
    
                        </div>
                    </div>
                    <div className="col-12">
                        <Alert color={this.state.tipoErrTecnologias} isOpen={this.state.openTecnologias} toggle={this.closeAlerts}>
                            {this.state.messageTecnologias}
                        </Alert>
                    </div>
                    <div className="col-12" style={{display: 'flex', flexWrap: 'wrap'}} >
                        <label>Tecnologías agregadas:&nbsp;</label>
                        {this.state.arrayTecnologias.map((data, key) => (
                            <Chip key={key} text={data.tecnologia} remove={this.remove} selected={this.selected} index={key} type="tecnologias" />
                        ))}
                    </div>
                    <div className="col-12">

                        {this.state.editTecnologia === false && (
                            <div className="form-holder">
                                <Button color="success" onClick={this.saveTecnologia} >Agregar nueva tecnologia</Button>
                            </div>
                        )}
                        
                        {this.state.editTecnologia === true && (
                            <div className="form-holder">
                                <Button color="success" onClick={this.saveTecnologia} style={{marginRight: 5}} >Actualizar tecnologia</Button>
                                <Button color="danger" onClick={this.cancelEditTecnologia} >Cancelar</Button>
                            </div>
                        )}

                    </div>
    
                </div>
                <h4>Experiencias laborales <span style={{color: '#dc3545'}}>*</span></h4>
                <div className="form-group row m-0">
                    <div className="col-12">
                        <div className="form-holder">
    
                            <Label>Empresa</Label>
                            <Input type="text" className="form-control" id="empresaNombre" name="empresaNombre" placeholder="Nombre de la empresa" required
                                value={this.state.empresaNombre} onChange={this.handleChange}  />
    
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-holder">
    
                            <Label>Descripcion de las actividades</Label>
                            <Input type="textarea"  name="empresaDescripcion" id="empresaDescripcion" cols="90" rows="4" value={this.state.empresaDescripcion} onChange={this.handleChange}  />
    
                        </div>
                    </div>
                    <div className="col-12">
                        <Alert color={this.state.tipoErrExeriencias} isOpen={this.state.openExeriencias} toggle={this.closeAlerts}>
                            {this.state.messageExeriencias}
                        </Alert>
                    </div>
                    <div className="col-12" style={{display: 'flex', flexWrap: 'wrap'}} >
                        <label>Experiencias agregadas: &nbsp;</label>
                        {this.state.arrayExpericncias.map((data, key) => (
                            <Chip key={key} text={data.empresaNombre} remove={this.remove} selected={this.selected} index={key} type="experiencias" />
                        ))}
                    </div>
                    <div className="col-12">
                        {this.state.editExperiencia === false && (
                            <div className="form-holder">
                                <Button color="success" onClick={this.saveExperiencia} >Agregar nueva experiencia laboral</Button>
                            </div>
                        )}
                        {this.state.editExperiencia === true && (
                            <div className="form-holder">
                                <Button color="success" onClick={this.saveExperiencia} style={{marginRight: 5}}>Actualizar experiencia laboral</Button>
                                <Button color="danger" onClick={this.cancelEditExperiencia} >Cancelar</Button>
                            </div>
                        )}
                    </div>
                </div>
                
                <div className="form-group row m-0">
                    <div className="col-12" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}} >
                        <h4 style={{marginRight:20}}>Links <span style={{fontSize:18}}>(Opcional, 100% recomendado)</span></h4> <h6>Enlaces que permitan observar tu capacidad técnica (proyectos, repositorios, trabajos culminados...)</h6>
                    </div>
                </div>
                <div className="form-group row m-0">
                    <div className="col-12">
                        <div className="form-holder">
    
                            <Label>Enlace</Label>
                            <Input type="url" className="form-control" id="link" name="link" placeholder="URL" required value={this.state.link} onChange={this.handleChange}  />
                
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-holder">
    
                            <Label>Descripcion (Opcional)</Label>
                            <Input type="textarea"  name="linkDescripcion" id="linkDescripcion" cols="35" rows="4" value={this.state.linkDescripcion} onChange={this.handleChange}  />
    
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <Alert color={this.state.tipoErrLinks} isOpen={this.state.openLinks} toggle={this.closeAlerts}>
                         {this.state.messageLinks}
                    </Alert>
                </div>
                <div className="col-12" style={{display: 'flex', flexWrap: 'wrap'}}  >
                    <label>Enlaces agregados: &nbsp;</label>
                    {this.state.arrayLinks.map((data, key) => (
                        <Chip key={key} text={data.link} remove={this.remove} selected={this.selected} index={key} type="links" />
                    ))}
                </div>
                <div className="col-12">
                    {this.state.editLink === false && (
                        <div className="form-holder">
                            <Button color="success" onClick={this.saveLink} >Agregar nuevo link</Button>
                        </div>
                    )}
                    {this.state.editLink === true && (
                        <div className="form-holder">
                            <Button color="success" onClick={this.saveLink} style={{marginRight: 5}} >Actualizar link</Button>
                            <Button color="danger" onClick={this.cancelEditLink} >Cancelar</Button>
                        </div>
                    )}
                </div>
                <h4>Cursos (Opcional)</h4>
                <div className="form-group row m-0">
                    <div className="col-12">
                        <div className="form-holder">
    
                            <Label>Nombre del curso</Label>
                            <Input type="text" className="form-control" id="cursoNombre" name="cursoNombre" placeholder="Nombre del curso" required 
                                value={this.state.cursoNombre} onChange={this.handleChange} />
    
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-holder">
    
                            <Label>Descripcion (Opcional)</Label>
                            <Input type="textarea"  name="cursoDescripcion" id="cursoDescripcion" cols="35" rows="4" value={this.state.cursoDescripcion} onChange={this.handleChange}  />
    
                        </div>
                    </div>
                    <div className="col-12">
                        <Alert color={this.state.tipoErrCursos} isOpen={this.state.openCursos} toggle={this.closeAlerts}>
                            {this.state.messageCursos}
                        </Alert>
                    </div>
                    <div className="col-12" style={{display: 'flex', flexWrap: 'wrap'}} >
                        <label>Cursos agregados: &nbsp;</label>
                        {this.state.arrayCursos.map((data, key) => (
                            <Chip key={key} text={data.cursoNombre} remove={this.remove} selected={this.selected} index={key} type="cursos" />
                        ))}
                    </div>
                    <div className="col-12">
                        {this.state.editCurso === false && (
                            <div className="form-holder">
                                <Button color="success" onClick={this.saveCurso} >Agregar nuevo curso</Button>
                            </div>
                        )}
                        
                        {this.state.editCurso === true && (
                            <div className="form-holder">
                                <Button color="success" onClick={this.saveCurso} style={{marginRight: 5}} >Actualizar curso</Button>
                                <Button color="danger" onClick={this.cancelEditCurso} >Cancelar</Button>
                            </div>
                        )}

                    </div>
                </div>
                <h4>Imagenes de proyectos realizados (Opcional)</h4>
                <div className="form-group row mb-3">
                    <div className="col-12 mb-1">
                        <div className="form-holder">
                            <Label for="exampleCustomFileBrowser">Subir Imagen</Label>
                            <CustomInput type="file" id="customFile" name="customFile" label="Seleccione una imagen!"
                                accept="image/*"
                                onChange={this.handleUpload}
                            />
                        </div>
                        <Progress color="success" value={this.state.uploadValue} max="100" />
                    </div>
                    <div className="col-12">
                        <Alert color={this.state.typeErrorUpload} isOpen={this.state.openAlertUpload} toggle={this.closeAlerts}>
                            {this.state.messageUpload}
                        </Alert>
                    </div>
                </div>

                <div className="row">
                    {this.state.arrayImagenes.map((img, z) => (
                        <div key={z} className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3" >
                            <Card>
                                <CardImg top width="100%" height="166px" src={img.url} alt="Card image cap" />
                                <CardBody style={{ padding: 5 }}>
                                    <CardTitle style={{ marginBottom: 2, textAlign: 'center' }} >{img.name}</CardTitle>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <div>
                                            <Button color="link" style={{ padding: '.175rem .35rem' }} 
                                                onClick={(e) => this.openImage(e, img)}
                                            >
                                                <FaImage />
                                            </Button>
                                            <Button color="link" style={{ padding: '.175rem .35rem' }}
                                                onClick={(e) => this.deleteImage(e,z,img)}
                                            >
                                                <FaTrash />
                                            </Button>
                                        </div>
                                        <small className="text-muted">{moment(img.fecha).fromNow()}</small>    
                                    </div>
                                                              
                                </CardBody>
                            </Card>
                        </div>
                    ))}
                </div>

                <div className="form-group row my-0 mt-5">
                    <div className="col-12">
    
                        <ul className="nav nav-tabs  d-flex align-items-center justify-content-between" role="tablist">
                            <li className="nav-item">
                                
                                <Button color="success" id="btn_prev_generales" className="btn btn-success rounded-circle btn-previous" onClick={() => {this.changeStep('2'); }}>
                                    <i className="zmdi zmdi-arrow-left"></i>
                                </Button>
                            </li>
                            <li className="nav-item">
                                
                                <Button color="success" id="btn_prev_generales" className="btn btn-success rounded-circle btn-next" disabled={!this.state.puedeAvanzar}
                                      onClick={this.terminarPostulacion}>
                                        <i className="zmdi zmdi-arrow-right"></i>
                                </Button>
                            </li>
                        </ul>
                    </div>
                </div>
                {this.state.puedeAvanzar === false && 
                    <div className="row mt-1">
                        <div className="col-12" style={{display: 'flex', justifyContent: 'center'}} >
                            <b><Label style={{color: '#dc3545'}}>* Faltan datos por llenar ({this.showError()})</Label></b>
                        </div>
                    </div>
                 }

        <Modal isOpen={this.state.openmodal} toggle={this.toggleModal} size="lg">
                <ModalHeader toggle={this.toggleModal}>{this.state.nameImage}</ModalHeader>
            <ModalBody>
                <img src={this.state.ulrImage}  width="100%" alt="image"></img>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={this.toggleModal}>Cancelar</Button>
            </ModalFooter>
        </Modal>

            </div>
        );
    }
    
}

const mapStateToProps = (state) => {
    return{
        perfil: state.perfil,
        //token: state.token
    }
}

const mapDispatchToProps = {
    setPerfil,
}

export default connect(mapStateToProps, mapDispatchToProps) (TabDatosTecnicos);
// export default TabDatosTecnicos