import React, { useState, useEffect, useRef }  from 'react';
import { Input, Label  } from 'reactstrap';
import StarRatings from 'react-star-ratings';
import firebase from '../../firebase/firebase';
import {Typeahead} from 'react-bootstrap-typeahead'; 

function Tecnologia(props) {

    const [opciones, setOpciones] = useState([]);
    const [selected, setSeleected] = useState([]);
    const typeahead = useRef();

    useEffect(() =>{
        loadTecnologias();
        let array = [];
        array.push(props.tecnologia.tecnologia)
        setSeleected(array);
    }, [])

    const handleChangeAutocomplete = (e)=>{
        e.forEach((item, i) => {
            if (item.customOption) {
                let filter = opciones.filter(el => {
                    return el.name.toLowerCase() === item.name.toLowerCase()
                });
                if(filter.length === 0){
                    firebase.db.collection('tecnologias').add({name: item.name});
                    let ops = [];
                    ops = opciones;
                    ops.push({name: item.name});
                    setOpciones(ops);
                    props.valueInput(item.name, 'tecnologia', props.indice);
                } else {
                    props.valueInput(item.name, 'tecnologia', props.indice);
                }
            } else {
                props.valueInput(item.name, 'tecnologia', props.indice);
            } 
            //onChange={(e) => {props.valueInput(e.target.value, e.target.name, props.indice )}}
        })
    }

    const handleSearch = (text) => {
        let value = text;
        if(value.indexOf(',') !== -1){
            const array = text.split(',');
            value = array[0];
            let search = [];
            search = opciones.filter(el => el.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
            if(search.length === 0){
                typeahead.current._handleSelectionAdd({customOption: true, id: Math.random().toString(20).substr(2, 6), name: search[0].name})
            } else{
                typeahead.current._handleSelectionAdd({name: search[0].name})
            }
        }
    }

    const loadTecnologias = () => {
        firebase.db.collection("tecnologias")
           .orderBy("name")
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => doc.data());
                setOpciones(data);
            });
    }

    return  <div className="form-group row m-0">
    <div className="col-md-3">
        <div className="form-holder">
            <Label>Tecnologia</Label>
            {!props.edit && 
            <Input type="text" className="form-control" name="tecnologia" value={props.tecnologia.tecnologia} 
                readOnly={true}
            />
            }

            {props.edit && 
            <Typeahead
                clearButton
                id="tecnologia"
                labelKey="name"
                defaultSelected={selected}
                emptyLabel="No se encontraron coincidencias."
                newSelectionPrefix="Añadir tecnología: "
                options={opciones}
                placeholder="Ej: (Wordpress) (ReactJS) (PHP) (Django) (MySQL) (.NET) (Java EE) (SQL Server) (Laravel) (HTML5) (CSS3) (ES6)..." 
                value={props.tecnologia.tecnologia} 
                onChange={handleChangeAutocomplete}
                onInputChange={handleSearch}
                ref={typeahead}
                paginationText="Ver mas resultados"
            />
            }

        </div>
    </div>
    <div className="col-md-3">
        <div className="form-holder">
            <Label>Tiempo de experiencia</Label>
            <div className="row">
                <div className="col-6">
                    <Input type="text" className="form-control" name="tecnologiaAños" value={props.tecnologia.tecnologiaAños} 
                        readOnly={!props.edit}
                        onChange={(e) => {props.valueInput(e.target.value, e.target.name, props.indice )}}
                    />
                </div>
                <div className="col-6">
                    {!props.edit &&
                        <Input type="text" className="form-control" name="tecnologiaTipo" value={props.tecnologia.tecnologiaTipo} readOnly={true}/>
                    }
                    {props.edit &&
                        <div className="caja-select">
                            <Input  type="select" name="tecnologiaTipo" id="tecnologiaTipo" value={props.tecnologia.tecnologiaTipo} 
                                onChange={(e) => {props.valueInput(e.target.value, e.target.name, props.indice )}}
                            >
                                <option value="Mes">Mes</option>
                                <option value="Años">Años</option>
                            </Input>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>

    <div className="col-md-3">
        <div className="form-holder">
            <Label>
                Dominio
            </Label>
            {!props.edit &&
                <StarRatings
                    rating={props.tecnologia.dominio}
                    starRatedColor="rgb(10,28,70)"
                    starHoverColor="rgb(62,64,97)"
                    numberOfStars={5}
                    name='rating'
                    starDimension='30px'
                    disabled={true}
                />
            }
            {props.edit &&
                <StarRatings
                    rating={props.tecnologia.dominio}
                    starRatedColor="rgb(10,28,70)"
                    starHoverColor="rgb(62,64,97)"
                    changeRating={(e) => { props.valueInput(e, 'dominio', props.indice )}}
                    numberOfStars={5}
                    name='dominio'
                    starDimension='30px'
                />
            }
        </div>
    </div>
    <div className="col-md-3">
        <div className="form-holder">
            <Label>Version</Label>
            <Input type="text" className="form-control" name="tecnologiaVersion" 
                value={props.tecnologia.tecnologiaVersion} readOnly={!props.edit}
                onChange={(e) => {props.valueInput(e.target.value, e.target.name, props.indice )}}    
            />
        </div>
    </div>
</div>
}

export default Tecnologia;