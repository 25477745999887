import React, {Component} from 'react';
import { Button, Form, Label, Input, Alert  } from 'reactstrap';
import {Link} from "react-router-dom";
import firebase from '../firebase/firebase';
import Recaptcha from 'react-recaptcha';
import moment from 'moment';

import { setPerfil } from '../actions';
import { connect }  from  'react-redux';

class Register extends Component {
    constructor(props){
        super(props);
        this.register = this.register.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
        this.verifyrecaptcha = this.verifyrecaptcha.bind(this);
    }
    state = {
        username: '',
        email: '',
        password: '',
        repeatPassword: '',
        repeatPasswordValid: false,
        errorMessage: '',
        isVerified: false
    }
    validatePassword(){
        this.setState({ errorMessage: '' });
        if (this.state.password === this.state.repeatPassword) {
            this.setState({ repeatPasswordValid: true});
        } else {
            this.setState({ repeatPasswordValid: false});
        }
    }
    async register(event) {
        event.preventDefault();
        if (this.state.email === '' || this.state.password === ''  || this.state.repeatPasswordValid === '' ) {
            this.setState({errorMessage: '*Por favor introduzca todos los campos'});
        } else if(!this.state.repeatPasswordValid) {
            this.setState({errorMessage: '*Contraseñas no coinciden'});
        } else if(!this.state.isVerified) {
            this.setState({errorMessage: '*Demuestra que eres Humano valida el captcha'});    
        } else {
            if(this.state.password.length < 6) {
                this.setState({errorMessage: '*La contraseña debe tener una longitud minima de 6 caracteres'});
            } else {
                const email = this.state.email.trim();
                const password = this.state.password.trim();
                await firebase.register(email, password)
                    .then(resp => {
                        this.saveDatos();
                        this.setState({
                            email: '',
                            password: '',
                            repeatPassword: '',
                            repeatPasswordValid: false,
                            errorMessage: ''
                        })
                        firebase.db.collection("programadores").doc(`${resp.user.uid}`) //optener los datos de perfil del usuario logueado
                        .get()
                        .then(doc => {
                            const data = doc.data();
                            if (typeof data === 'undefined') {
                                this.props.setPerfil(null)
                                this.props.history.replace('/home')
                            } else {
                                this.props.setPerfil(data)
                                firebase.db.collection("log").doc(doc.id).set({ 
                                    fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                                    accion: 'se registró', 
                                    tipo: 'programador',
                                    info: data,
                                    modulo: 'Registro'  
                                });
                                this.props.history.replace('/home')
                            } 
                        }).catch(err => this.setState({errorMessage: '*Error de servidor, intenta nuevamente'}))
                    }).catch(err => {
                        let msg = '';
                        switch (err.code) {
                            case 'auth/email-already-in-use':
                                msg = '*Correo ya se encuentra registrado en nuestra plataforma.';
                                break;
                            case 'auth/email-already-exists':
                                msg = '*Correo ya se encuentra registrado en nuestra plataforma.';
                                break;
                            case 'auth/invalid-email':
                                msg = '*Formato de email invalido.'
                                break;
                            case 'auth/operation-not-allowed':
                                msg = '*App no tiene habilitada la autenticacion mediante correo y contraseña.';
                                break;
                            case 'auth/weak-password':
                                msg = '*La contraseña no es lo suficientemente segura.';
                                break;
                            default:
                                msg ='*Correo ya se encuentra registrado en nuestra plataforma.';
                                break;
                        }
                        this.setState({errorMessage: msg});
                    });
            }
        }
    }

    saveDatos() {
        let datosGenerales = {
            activo: false,
            admin: false,
            registroCompletado: false,
            rol: 'programador',
            ultimaPestana: '1',
            anosExperiencia: 0,
            apellidos: '',
            aspiracion: 0,
            cedula: '',
            ciudad: '',
            direccion: '',
            email: firebase.auth.currentUser.email,
            estado: 'Iniciado',
            fecha:  moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            nivelIngles: '',
            idiomas: [],
            nombres: '',
            numWhatsapp: '',
            tipoPerfil: '',
            tipoPerfiles: [],
            titulo: '',
            uid: firebase.auth.currentUser.uid,
            universidad: '',
            tecnologias: [],
            experiencias: [],
            links: [],
            cursos: [],
            costoMes: 0,
            disponibilidad: '',
            datosPersonales: '',
            mensajes: [],
            historico: [],
            imagenes: [],
            chat: {
                actualizado: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                cerrado: 0,
                id: `${firebase.auth.currentUser.uid}`,
                mensajes: [],
                nombres: '',
                apellidos: '',
                email: '',
                numWhatsapp: '',
                cedula: '',
                anosExperiencia: '',
                nivelIngles: '',
                titulo: '',
                universidad: '',
                ciudad: '',
                direccion: '',
                aspiracion: '',
                fecha: '',
                tipoPerfiles: []
            },
            updatedat:  moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            primerasesioncerrada: false,
            datosCompletos: false,
            tags:[]
        }
        firebase.db.collection('programadores').doc(`${firebase.auth.currentUser.uid}`).set(datosGenerales)
        .then( () =>{
            //this.changeStep('3');
        })
        .catch(err => {
            console.log(err.message);
        });
    }

    recaptchaLoaded(){
        // console.log('capcha loaded successfull');
    }

    verifyrecaptcha(response){
        if(response){
            this.setState({ isVerified: true, errorMessage: '' })
        }
    }

    render(){
        return(
            <main className="container d-flex align-items-center justify-content-center">
                <div className="row h-100 align-items-center justify-content-center">
                    <div className="row">
                        <Form id="login_new_register" className="form-login rounded border border-secondary shadow-lg" onSubmit={this.register}>
                            <div className="form-header">
                                <div className="row">
                                    <div className="col-12 py-3">
                                        <h2 className="text-center">Registro</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-content px-4 py-3">
                                    <div className="form-group row m-0 mt-3">
                                        {/*<div className="col-md-12">
                                            <Label for="user">Usuario:</Label>
                                            <Input name="user" className="form-control" type="text" placeholder="Tu usuario" 
                                                onChange={e => this.setState({ username: e.target.value})}
                                                onKeyUp={e => this.setState({ errorMessage: ''})} />
                                        </div>*/}
                                        <div className="col-md-12">
                                            <Label for="email">Correo electrónico:</Label>
                                            <Input name="email" className="form-control" type="email" placeholder="Correo@ejemplo.com" 
                                                onChange={e => this.setState({ email: e.target.value})}
                                                onKeyUp={e => this.setState({ errorMessage: ''})} />
                                        </div>
                                    </div>

                                    <div className="form-group row m-0 mt-3">
                                        <div className="col-md-12">
                                            <Label for="pass1">Contraseña:</Label>
                                            <Input id="pass1" name="pass1" className="form-control" type="password" placeholder="Contraseña" 
                                                onChange={e => this.setState({ password: e.target.value})} onKeyUp={this.validatePassword} />
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <Label for="pass2">Repetir contraseña:</Label>
                                            <Input id="pass2" name="pass2" className="form-control" type="password" placeholder="Repita la contraseña"
                                                onChange={e => this.setState({ repeatPassword: e.target.value})} onKeyUp={this.validatePassword} />
                                        </div>
                                    </div>
                                    {this.state.errorMessage !== '' &&                         
                                        <div className="form-group row m-0 mt-3">
                                            <div className="col-12 text-center">
                                                <Alert color="danger">
                                                    {this.state.errorMessage}
                                                </Alert>
                                            </div>
                                        </div>
                                    }

                                    <div className="form-group row justify-content-center m-0 mt-3">
                                        <Recaptcha
                                            sitekey="6Lcsm_MUAAAAAGHt0FLVPB_ZHPvKU4Nu6aDWx_HU"
                                            render="explicit"
                                            hl="es"
                                            onloadCallback={this.recaptchaLoaded}
                                            verifyCallback={this.verifyrecaptcha}
                                        />
                                    </div>

                                    
                                    <div className="form-group row justify-content-center m-0 mt-3">
                                        <Button color="success">Enviar</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-footer">
                                <div className="row mb-5">
                                    <div className="col-12 text-center">
                                        <Link to="/login">¿Ya tengo una cuenta?</Link> 
                                    </div>
                                    {/*<div className="col-12 text-center">
                                        ¿Deseas saber que estamos buscando ? entra en <Link to="/requisitos">requisitos</Link> 
                                    </div>*/}
                                </div>
                            </div>


                        </Form>
                    </div>
                </div>
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return{
        perfil: state.perfil,
        //token: state.token
    }
}

const mapDispatchToProps = {
    setPerfil,
}

export default connect(mapStateToProps, mapDispatchToProps) (Register);
// export default Register;