import React, { Component } from 'react';
import { Input, Label, Button, Tooltip} from 'reactstrap';
import firebase from '../firebase/firebase';
import {Typeahead} from 'react-bootstrap-typeahead';
import { FaStickyNote } from 'react-icons/fa';
import moment from 'moment';
import universidades from '../mocks/universidades';
import { perfiles } from '../mocks/tipoPerfiles';  

import { setPerfil } from '../actions';
import { connect }  from  'react-redux';

const uniSort = universidades.listado.sort();
const uniList = uniSort.map(fila => {
    return {id: fila, name: fila}
})
const perfilSort = perfiles.sort();

class TabDatosGenerales extends Component {
    constructor(props) {
        super(props);
        this.validarAvanzar = this.validarAvanzar.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveDatos  = this.saveDatos.bind(this);
        this.changeStep  = this.changeStep.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeSelectPerfil = this.handleChangeSelectPerfil.bind(this);
        this.handleChangeTipoPerfiles = this.handleChangeTipoPerfiles.bind(this)
        //autocomplete multiple
        this.handleChangeAutocomplete = this.handleChangeAutocomplete.bind(this);
        this._handleSearch = this._handleSearch.bind(this);
        this.handleNewSearch = this.handleNewSearch.bind(this);
        this.handleChangeIdiomas = this.handleChangeIdiomas.bind(this);
        this._handleSearchIdiomas = this._handleSearchIdiomas.bind(this);
        this.handleChangeUniversidad = this.handleChangeUniversidad.bind(this);
        this.toggleTooltip =  this.toggleTooltip.bind(this);
        this.toggleTooltipAspiracion =  this.toggleTooltipAspiracion.bind(this);
        this.toggleTooltipDisponibilidad =  this.toggleTooltipDisponibilidad.bind(this);
        this.olnlyNumber =  this.olnlyNumber.bind(this);

        this.state = {
          nombres: this.props.perfil.nombres,
          apellidos: this.props.perfil.apellidos,
          email: this.props.perfil.email === '' ? firebase.auth.currentUser.email : this.props.perfil.email,
          numWhatsapp: this.props.perfil.numWhatsapp,
          cedula: this.props.perfil.cedula,
          anosExperiencia: this.props.perfil.anosExperiencia,
          tipoPerfil: this.props.perfil.tipoPerfil,
          tipoPerfiles: this.props.perfil.tipoPerfiles,
          nivelIngles: this.props.perfil.nivelIngles,
          titulo: this.props.perfil.titulo,
          universidad: this.props.perfil.universidad,
          ciudad: this.props.perfil.ciudad,
          direccion: this.props.perfil.direccion,
          aspiracion: this.props.perfil.aspiracion,
          fecha: this.props.perfil.fecha,
          puedeAvanzar: false,
          categorias: [],
          categoriaSeleccionada: 0,
          disponibilidad: this.props.perfil.disponibilidad,
          multiple: true,
          options: [],
          tooltipPerfilOpen: false,
          tooltipAspiracionOpen: false,
          tooltipDisponibilidadOpen: false,
          tempAspiracion: this.props.perfil.aspiracion,
          tempPerfil: this.props.perfil,
          historicos: this.props.perfil.historico,
          estado: this.props.perfil.estado,
          updatedat: this.props.perfil.updatedat,
          universidadesList: uniList,
          addNew: false,
          idiomas: this.props.perfil.idiomas ? this.props.perfil.idiomas : [],
          otroTitulo: this.props.perfil.otroTitulo ? this.props.perfil.otroTitulo : '',
          idiomasList: [
            {id: 'Árabe', name:'Árabe'},
            {id: 'Chino mandarín', name:'Chino mandarín'},
            {id: 'Español', name:'Español'},
            {id: 'Francés', name:'Francés'},
            {id: 'Ingles', name:'Ingles'},
            {id: 'Italiano', name:'Italiano'},
            {id: 'Portugués', name:'Portugués'}
          ],
          selectedUni:  this.props.perfil.universidad ? [{ id: this.props.perfil.universidad , name: this.props.perfil.universidad }] : [],
          perfilSingle: this.props.perfil.tipoPerfiles.length > 0 ? this.props.perfil.tipoPerfiles[0]: ''
        };
    }

    componentDidMount(){
        this.validarAvanzar();
        this.loadCategorias();
    }

    loadCategorias(){
        firebase.db.collection("categorias")
           .orderBy("nombre")
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => doc.data());
                this.setState({ categorias: data })
            });
    }

    validarAvanzar(){
        if(this.state.nombres === '' || this.state.apellidos === '' || this.state.email === '' || this.state.numWhatsapp === '' || this.state.cedula === '' || this.state.anosExperiencia === null || this.state.anosExperiencia === ''
        || this.state.tipoPerfiles.length === 0 || this.state.idiomas.length === 0 || this.state.titulo === '' || this.state.ciudad === '' || this.state.direccion === '' ||
        this.state.aspiracion === '' || this.state.aspiracion === null || this.state.fecha === '' || this.state.disponibilidad === '' ) {
            this.setState({ puedeAvanzar: false })
        }else{
            this.setState({ puedeAvanzar: true })
        }
    }

    validarSeguir(){
        if(this.state.nombres === '' || this.state.apellidos === '' || this.state.email === '' || this.state.numWhatsapp === '' || this.state.cedula === '' || this.state.anosExperiencia === null || this.state.anosExperiencia === ''
        || this.state.tipoPerfiles.length === 0 || this.state.idiomas.length === 0 || this.state.titulo === '' || this.state.ciudad === '' || this.state.direccion === '' ||
        this.state.aspiracion === '' || this.state.aspiracion === null || this.state.aspiracion === 0 || this.state.aspiracion === '0' || this.state.fecha === '' || this.state.disponibilidad === '' ) {
            return false;
        }else{
            return true;
        }
    }

    showError(){
        let mensaje = '';
        if(this.state.nombres === ''){
            mensaje = 'Nombres';
        } 
        else if(this.state.apellidos === ''){
            mensaje = 'Apellidos';
        } 
        else if( this.state.email === ''){
            mensaje = 'Corre electóronico';
        } 
        else if( this.state.numWhatsapp === ''){
            mensaje = 'Número de Whatsapp';
        } 
        else if( this.state.cedula === ''){
            mensaje = 'Cedula de itentidad';
        } 
        else if( this.state.anosExperiencia === null){
            mensaje = 'Años de Experiencia';
        } 
        else if( this.state.anosExperiencia === ''){
            mensaje = 'Años de Experiencia';
        }
        else if( this.state.tipoPerfiles.length === 0){
            mensaje = 'Tipo de Perfil';
        }
        else if( this.state.idiomas.length === 0){
            mensaje = 'Idiomas';
        } 
        else if( this.state.titulo === ''){
            mensaje = 'Título';
        }  
        else if(this.state.ciudad === ''){
            mensaje = 'Ciudad';
        } 
        else if(this.state.direccion === ''){
            mensaje = 'Direción';
        } 
        else if(this.state.aspiracion === ''){
            mensaje = 'Aspiración salarial';
        } 
        else if(this.state.aspiracion === null){
            mensaje = 'Aspiración salarial';
        } 
        else if(this.state.aspiracion === 0 ){
            mensaje = 'Aspiración salarial debe se mayor a 0';
        }
        else if(this.state.aspiracion === '0'){
            mensaje = 'Aspiración salarial debe se mayor a 0';
        } 
        else if(this.state.fecha === ''){
            mensaje = 'Fecha de inscripción';
        } 
        else if(this.state.disponibilidad === ''){
            mensaje = 'Disponibilidad';
        }else{
            mensaje = '';
        }
        return mensaje;
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
        this.validarAvanzar();
    }

    handleChangeSelect = (e) => {
        this.setState({[e.target.name]: e.target.options[e.target.selectedIndex].text});
        this.validarAvanzar();
    }

    handleChangeSelectPerfil = (e) => {
        const valor = e.target.options[e.target.selectedIndex].text;
        this.setState({[e.target.name]: e.target.options[e.target.selectedIndex].text});
        this.validarAvanzar();
        firebase.db.collection('categorias')
            .where("nombre","==",valor)
            .get()
            .then((querySnapshot) =>{
                let objetoCategoria;
                let arrayCategoria = []
                querySnapshot.forEach((doc) => {
                    objetoCategoria = doc.data();
                    objetoCategoria.id = doc.id
                    arrayCategoria.push(objetoCategoria)
                });
                this.setState({categoriaSeleccionada: arrayCategoria[0]})
            })
            .catch(err => {
                console.log(err.message);
            });
    }

    handleChangeTipoPerfiles = (e) => {
        let arrayPerfil =  [];
        arrayPerfil.push(e.target.value)
        this.setState({
            tipoPerfiles: arrayPerfil,
            perfilSingle: e.target.value
        })
    }

    /*handleChangeAutocomplete = (e) => {
        let array = [];
        e.forEach((item, i) => {
            if (item.customOption) {
                firebase.db.collection('tecnologias').add({name: item.name});
            } 
            array.push(item.name)
        })
        this.setState({tecnologia: array})
    }*/

    _handleSearch(text, e){
        let value = text;
        if(text.indexOf(',') !== -1){
            const array = text.split(',');
            value = array[0];
            let search = [];
            search = this.state.categorias.filter(el => el.nombre.toLowerCase().indexOf(value.toLowerCase()) !== -1);
            if(search.length === 0){
                this._typeahead._handleSelectionAdd({customOption: true, id: Math.random().toString(20).substr(2, 6), nombre: search[0].nombre})
            } else{
                this._typeahead._handleSelectionAdd({nombre: search[0].nombre})
            }
        }
        let filter = [];
        filter = this.state.categorias.filter(el => el.nombre.toLowerCase().indexOf(value.toLowerCase()) !== -1);
        if(filter.length > 0){
            this.setState({addNew: false});
        } else {
            this.setState({addNew: true});
        }
    }

    filterByCallback = (option, props) => {
        let filter = [];
        filter = option.nombre.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
        return filter
    }

    handleChangeAutocomplete = (e) => {
        let array = [];
        e.forEach((item, i) => { 
            if (item.customOption) {
                firebase.db.collection('categorias').add({nombre: item.nombre, cantidadPersonas: 0});
                let cats = this.state.categorias;
                cats.push({nombre: item.nombre, cantidadPersonas: 0})
                this.setState({ categorias: cats})
                array.push(item.nombre)
            } else { // si no ha seleccionado un perfil nuevo
                if (item.nombre === undefined) {
                    array.push(item)
                }   else {
                    array.push(item.nombre)
                }
            }
        });
        this.setState({tipoPerfiles: array});

        //estaba
        /*e.forEach((item, i) => {  
            if (item.customOption) { // si ha seleccionado un perfil nuevo
                let filter = this.state.categorias.filter(el => { //busco si ese perfirl nuevo ya esta en la coleccion 
                    return el.nombre.toLowerCase() === item.nombre.toLowerCase()
                });
                if(filter.length === 0){ // si no esta lo agrego en la base de datos
                    firebase.db.collection('categorias').add({nombre: item.nombre, cantidadPersonas: 0});
                    array.push(item.nombre)
                }else { // esta dentro del arreglo
                    array.push(filter[0].nombre);
                    this._typeahead.state.selected = array;
                }
            } else { // si no ha seleccionado un perfil nuevo
                if (item.nombre === undefined) {
                    array.push(item)
                }   else {
                    array.push(item.nombre)
                }
            } 
        })
        this.validarAvanzar();
        this.setState({tipoPerfiles: array})*/
    }

    handleChangeIdiomas = (e) => { 
      let array = [];
      e.forEach((item, i) => { 
        if (item.name === undefined) {
          array.push(item)
        } else {
          array.push(item.name)
        }
      });
      this.setState({ idiomas: array})
    }

    _handleSearchIdiomas = (text, e) => { 

    }

    handleChangeUniversidad = (e) => { 
        if(e.length === 0){
            this.setState({ universidad: '' })
        } 
        e.forEach((item, i) => { 
            if (item.customOption) {
                //firebase.db.collection('categorias').add({nombre: item.nombre, cantidadPersonas: 0});
                //deberiamos añadir la universidad
                this.setState({  universidad: item.name })
            } else { // si no ha seleccionado un perfil nuevo
                this.setState({ universidad: item.name })
            }
        });
    }

    verCategoriasDuplicadas(value){
        let filter = this.state.categorias.filter(el => { //busco si ese perfirl nuevo ya esta en la coleccion 
            return el.nombre.toLowerCase() === value.toLowerCase()
        });
        if(filter.length === 0){ // si no esta lo agrego en la base de datos
            return false;
        }else { // esta dentro del arreglo
            return
        }
    }

    handleNewSearch(){
        this.setState({ sinResultadosBusqueda: false, tecnologia: [], nivelIngles: '', tipoPerfil: '', anosExperiencia: 0 });
        this._typeahead.clear();
        this.loadPostulates();
        this.validarAvanzar();
    }

    llenarHistorico(){
        let arrayHistorico = [];
        arrayHistorico = this.props.perfil.historico;
        let estado = this.props.perfil.estado;
        if(this.state.tempPerfil.anosExperiencia !== this.state.anosExperiencia){
            arrayHistorico.push(
            { 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                accion: `Cambio los Años de Experiencia de ${this.state.tempPerfil.anosExperiencia} años a ${this.state.anosExperiencia} años` 
            });
        }
        if(this.state.tempPerfil.apellidos !== this.state.apellidos){
            arrayHistorico.push(
            { 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                accion: `Cambio de apellidos de ${this.state.tempPerfil.anosExperiencia} a ${this.state.anosExperiencia}` 
            });
        }
        if(this.state.tempPerfil.aspiracion !== this.state.aspiracion){
            arrayHistorico.push(
            { 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                accion: this.props.perfil.estado === 'No contratado' ?  `Cambio de aspiracion salarial de ${this.state.tempPerfil.aspiracion}$ a ${this.state.aspiracion}$, 
                tambien se cambia el estado del programador a No revisado ya que se encontraba No Contratado` : `Cambio de aspiracion salarial de ${this.state.tempPerfil.aspiracion}$ a ${this.state.aspiracion}$` 
            });
            estado = this.props.perfil.estado === 'No contratado' ? 'No revisado' :  this.props.perfil.estado;
        }
        if(this.state.tempPerfil.cedula !== this.state.cedula){
            arrayHistorico.push(
            { 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                accion: `Cambio de cedula de ${this.state.tempPerfil.cedula} a ${this.state.cedula}` 
            });
        }
        if(this.state.tempPerfil.ciudad !== this.state.ciudad){
            arrayHistorico.push(
            { 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                accion: `Cambio de ciudad de ${this.state.tempPerfil.ciudad} a ${this.state.ciudad}` 
            });
        }
        if(this.state.tempPerfil.direccion !== this.state.direccion){
            arrayHistorico.push(
            { 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                accion: `Cambio de dirección de ${this.state.tempPerfil.direccion} a ${this.state.direccion}` 
            });
        }
        if(this.state.tempPerfil.idiomas !== this.state.idiomas){
            arrayHistorico.push(
            { 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                accion: `Cambio de idiomas de ${this.state.tempPerfil.idiomas} a ${this.state.idiomas}` 
            });
        }
        if(this.state.tempPerfil.nombres !== this.state.nombres){
            arrayHistorico.push(
            { 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                accion: `Cambio de nombre de ${this.state.tempPerfil.nombres} a ${this.state.nombres}` 
            });
        }
        if(this.state.tempPerfil.tipoPerfiles.length !== this.state.tipoPerfiles.length){ 
            let mensajeQuitar = 'Removio perfiles:';
            let mensajeAñadir = 'Ingresó de perfiles:';
            if(this.state.tempPerfil.tipoPerfiles.length >  this.state.tipoPerfiles.length){ //quito perfiles
                const tipoPerfiles = this.state.tipoPerfiles;
                this.state.tempPerfil.tipoPerfiles.forEach((element, i) => {
                    if (tipoPerfiles[i] === undefined) {
                        mensajeQuitar += ' '  + element;
                        mensajeQuitar +=  i < (this.state.tempPerfil.tipoPerfiles.length -1) ? ', '  : '' ;
                    }
                });
                arrayHistorico.push(
                { 
                    fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                    accion: mensajeQuitar 
                });
            } else { //añadio perfiles
                const tempTipoPerfiles = this.state.tempPerfil.tipoPerfiles;
                this.state.tipoPerfiles.forEach((element, j) => {
                    if (tempTipoPerfiles[j] === undefined) {
                        mensajeAñadir += ' '  + element;
                        mensajeAñadir +=  j < (this.state.tipoPerfiles.length -1) ? ', '  : '' ;
                    }
                });
                arrayHistorico.push(
                { 
                    fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                    accion: mensajeAñadir 
                });
            }
        }
        if(this.state.tempPerfil.titulo !== this.state.titulo){
            arrayHistorico.push(
            { 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                accion: `Cambio de titulo universitario de ${this.state.tempPerfil.titulo} a ${this.state.titulo}` 
            });
        }
        /*if(this.state.tempPerfil.universidad !== this.state.universidad){
            arrayHistorico.push(
            { 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                accion: `Cambio de universidad de ${this.state.tempPerfil.universidad} a ${this.state.universidad}` 
            });
        }*/
        if(this.state.tempPerfil.disponibilidad !== this.state.disponibilidad){
            arrayHistorico.push(
            { 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                accion: this.props.perfil.estado === 'No contratado' ?  `Cambio la disponibilidad ${this.state.tempPerfil.disponibilidad} a ${this.state.disponibilidad}, 
                tambien se cambia el estado del programador a No revisado ya que se encontraba No contratado` : `Cambio la disponibilidad de ${this.state.tempPerfil.disponibilidad} a ${this.state.disponibilidad}`
            });
            estado = this.props.perfil.estado === 'No contratado' ? 'No revisado' :  this.props.perfil.estado;
        }
        if(this.state.tempPerfil.otroTitulo !== this.state.otroTitulo){
            arrayHistorico.push(
            { 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                accion: `Cambio otro estudio avanzado de ${this.state.tempPerfil.otroTitulo} a ${this.state.otroTitulo}` 
            });
        }
        this.setState({historico: arrayHistorico, estado});
    }

    saveDatos() {
        let arrayHistorico = [];
        arrayHistorico = this.props.perfil.historico;
        let estado = this.props.perfil.estado;
        if(this.props.perfil.primerasesioncerrada === true && this.props.perfil.registroCompletado  === true){
            this.llenarHistorico();
            this.setState({updatedat: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')})
        }
        const self = this;
        let datosGenerales = {
            activo: false,
            anosExperiencia: this.state.anosExperiencia,
            apellidos: this.MaysPrimera(this.state.apellidos.toLowerCase()),
            aspiracion: this.state.aspiracion,
            cedula: this.state.cedula,
            ciudad: this.state.ciudad,
            direccion: this.state.direccion,
            email: this.state.email,
            fecha: this.state.fecha,
            nivelIngles: this.state.nivelIngles,
            nombres: this.MaysPrimera(this.state.nombres.toLowerCase()),
            numWhatsapp: this.state.numWhatsapp,
            tipoPerfil: this.state.tipoPerfil,
            tipoPerfiles: this.state.tipoPerfiles,
            titulo: this.state.titulo,
            uid: firebase.auth.currentUser.uid,
            universidad: this.state.universidad,
            disponibilidad: this.state.disponibilidad,
            historico: arrayHistorico,
            estado: estado,
            updatedat: this.state.updatedat,
            idiomas: this.state.idiomas,
            otroTitulo: this.state.otroTitulo,
            ultimaPestana: '3'
        }
        let newProfile = Object.assign({}, this.props.perfil, datosGenerales);
        firebase.db.collection('programadores').doc(`${firebase.auth.currentUser.uid}`).update(datosGenerales)
        .then( () => {
            this.props.setPerfil(newProfile);
            if(self.state.categoriaSeleccionada !== 0) {
                if(this.props.perfil.primerasesioncerrada === false){
                    const body = self.state.categoriaSeleccionada;
                    let objUpdate = { cantidadPersonas: (body.cantidadPersonas + 1)}
                    firebase.db.collection('categorias').doc(`${body.id}`).update(objUpdate)
                        .then( () =>{
                            self.changeStep('3');
                        }).catch(err => {
                            console.log(err.message);
                        });
                }
            } else {
                self.changeStep('3');
            }
        })
        .catch(err => {
            console.log(err.message);
        });
    }

    MaysPrimera(string){
        return string.replace(/\b[a-z]/g,c=>c.toUpperCase());
    }

    changeStep(tab){
        this.validarAvanzar();
        firebase.db.collection("programadores").doc(`${firebase.auth.currentUser.uid}`)
        .update({ultimaPestana: tab})
        .then(querySnapshot => {
            this.props.step(tab);
        });
    }

    toggleTooltip(event){
        this.setState({tooltipPerfilOpen: !this.state.tooltipPerfilOpen})
    }

    toggleTooltipAspiracion(event){
        this.setState({tooltipAspiracionOpen: !this.state.tooltipAspiracionOpen})
    }

    toggleTooltipDisponibilidad(event){
        this.setState({tooltipDisponibilidadOpen: !this.state.tooltipDisponibilidadOpen})
    }

    olnlyNumber(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
         if (/\+|-/.test(keyValue))
           event.preventDefault();
    }

    render (){
        return(
            <div>
                <div className="wizard-header">
                    <h3 className="heading">Datos Generales</h3>
                    <p>
                        Por favor ingrese la siguiente informaci&oacute;n general
                    </p>
                </div>
                <div className="form-group row m-0">
                    <div className="col-md-6">
                        <div className="form-holder">
    
                            <Label>Nombres</Label>
                            <Input type="text" className="form-control" id="nombres" name="nombres"  value={this.state.nombres} placeholder="Nombres completos" required onChange={this.handleChange} onKeyUp={this.validarAvanzar} />
    
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-holder">
    
                            <Label>Apellidos</Label>
                            <Input type="text" className="form-control" id="apellidos" name="apellidos"  value={this.state.apellidos} placeholder="Apellidos completos" required onChange={this.handleChange} onKeyUp={this.validarAvanzar} />
    
                        </div>
                    </div>
    
                </div>
                <div className="form-group row m-0">
                    <div className="col-md-6">
                        <div className="form-holder">
    
                            <Label>Correo electr&oacute;nico</Label>
                            <Input type="email" name="email" id="email"  value={this.state.email} className="form-control" pattern="[^@]+@[^@]+.[a-zA-Z]{2,6}" placeholder="correo@gmail.com" required readOnly={true}
                            onChange={this.handleChange} onKeyUp={this.validarAvanzar} />
    
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-holder ">
    
                            <Label>Numero telef&oacute;nico con Whatsapp</Label>
                            <Input type="tel" className="form-control" id="numWhatsapp" name="numWhatsapp" value={this.state.numWhatsapp}  placeholder="04120000000" required onChange={this.handleChange} onKeyUp={this.validarAvanzar}  />
    
                        </div>
                    </div>
                </div>
                <div className="form-group row m-0">
                    <div className="col-md-6">
                        <div className="form-holder">
    
                            <Label>Cedula de identidad</Label>
                            <input type="text" name="cedula"
                                id="cedula" 
                                className="form-control" 
                                value={this.state.cedula}   
                                placeholder="18000000" 
                                required 
                                pattern="[0-9]{0,13}"
                                onChange={this.handleChange} 
                                onKeyUp={this.validarAvanzar}
                             />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-holder ">

                            <Label>Años de experiencia en total (como profesional)</Label>
                            <Input type="number" className="form-control" id="anosExperiencia" name="anosExperiencia" value={this.state.anosExperiencia} placeholder="Años" required 
                                onChange={event => {
                                    if(event.target.value !== ''){
                                        this.setState({ anosExperiencia: parseInt(event.target.value , 10)}) 
                                    } else {
                                        this.setState({ anosExperiencia: event.target.value }) 
                                    }
                                }}
                                onKeyUp={this.validarAvanzar}  
                            />
    
                        </div>
                    </div>
    
                </div>
                <div className="form-group row m-0">
                    <div className="col-md-6">
                        <div className="form-holder ">
                            <Tooltip className="350px" placement="top" isOpen={this.state.tooltipPerfilOpen} target="tooltiptipoperfil" toggle={this.toggleTooltip}>
                                Seleccione el tipo de perfil que mas le identifique, 
                                Ejemplo de tipo de perfil (Backend Java) (Frontend JavaScript) (Mobile Nativo)..., Las tecnologías o conocimientos como (Android,Angular,Boodtrap...), 
                                no corresponden a tipo de perfil estas deberas indicarlas en la seccion de datos técnicos.
                            </Tooltip>
                            <Label id="tooltiptipoperfil">
                                Tipo de perfil
                                <FaStickyNote  style={{ marginLeft: '5px', color: '#666' }} />
                            </Label>

                            <div className="caja-select">
                                <Input type="select" name="tipoPerfiles"  value={this.state.perfilSingle} id="tipoPerfiles" onChange={this.handleChangeTipoPerfiles}>
                                    <option value="" disabled>Seleccione</option>
                                    {perfilSort.map((fila, index) => (
                                        <option value={fila} key={'perfil'+index} >{fila}</option>
                                    ))}
                                </Input>
                            </div>
                            
                            {/*<Typeahead
                                //filterBy={this.filterByCallback.bind(this)}
                                clearButton
                                id="categorias"
                                labelKey="nombre"
                                emptyLabel="No se encontraron coincidencias."
                                allowNew={this.state.addNew}
                                defaultSelected={this.state.tipoPerfiles}
                                newSelectionPrefix="Añadir Perfil: "
                                options={this.state.categorias}
                                placeholder="Ej: (Backend JAVA) (Backend JavaScript) (Backend PHP)..." 
                                value={this.state.tipoPerfiles} 
                                onChange={this.handleChangeAutocomplete}
                                onInputChange={this._handleSearch}
                                ref={(ref) => this._typeahead = ref}
                                paginationText="Ver mas resultados"
                            /> */}

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-holder ">
                            <Label>
                              Idiomas puede seleccionar varios
                            </Label>
                            <Typeahead
                                //filterBy={this.filterByCallback.bind(this)}
                                clearButton
                                id="idiomas"
                                labelKey="name"
                                multiple={true}
                                emptyLabel="No se encontraron coincidencias."
                                defaultSelected={this.state.idiomas}
                                options={this.state.idiomasList}
                                placeholder="Español, Ingles, Francés" 
                                value={this.state.idiomas} 
                                onChange={this.handleChangeIdiomas}
                                onInputChange={this._handleSearchIdiomas}
                                ref={(ref) => this._typeaheadIdiomas = ref}
                                paginationText="Ver mas resultados"
                            />
                            {/*<div className="caja-select">
                                <Input type="select" name="nivelIngles"  value={this.state.nivelIngles} id="nivelIngles" onChange={this.handleChangeSelect} onKeyUp={this.validarAvanzar}  >
                                    <option value="" disabled>Seleccione</option>
                                    <option value="No">No</option>
                                    <option value="Si">Si</option>
                                </Input> 
                            </div> */}
                        </div>
                    </div>
    
                </div>
                <div className="form-group row m-0">
                    <div className="col-md-6">
                        <div className="form-holder ">
                            <Label>
                                Título académico
                            </Label>
                            <div className="caja-select">
                                <Input type="select" name="titulo"  value={this.state.titulo} id="titulo" onChange={this.handleChangeSelect} onKeyUp={this.validarAvanzar} >
                                    <option value="" disabled>Seleccione</option>
                                    <option value="Analista de Marketing">Analista de Marketing</option>
                                    <option value="Bachiller">Bachiller</option>
                                    <option value="Diseñador Gráfico">Diseñador Gráfico</option>
                                    <option value="Ingeniero de Sistemas">Ingeniero de Sistemas</option>
                                    <option value="Ingeniero de Computacion">Ingeniero de Computacion</option>
                                    <option value="TSU Informatica">TSU Informatica</option>
                                    <option value="Estudios inconclusos">Estudios inconclusos</option>
                                    <option value="Otro">Otro</option>
                                </Input>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-holder">
                            <Label>Estudio avanzado</Label>
                            <input type="text" name="otroTitulo"
                                id="otroTitulo" 
                                className="form-control" 
                                value={this.state.otroTitulo}   
                                placeholder="Postgrado, especilizacion..." 
                                required 
                                onChange={this.handleChange} 
                                onKeyUp={this.validarAvanzar}
                            />
                        </div>
                    </div>
                    {/*<div className="col-md-6">
                        <div className="form-holder ">
                            <Label>
                                Universidad/Institucion
                            </Label>
                            <Typeahead
                                //filterBy={this.filterByCallback.bind(this)}
                                clearButton
                                id="universidad"
                                labelKey="name"
                                //allowNew={true}
                                multiple={false}
                                //newSelectionPrefix="Añadir Universidad: "
                                emptyLabel="No se encontraron coincidencias."
                                defaultSelected={this.state.selectedUni}
                                options={this.state.universidadesList}
                                placeholder="Seleccione una universidad" 
                                value={this.state.selectedUni} 
                                onChange={this.handleChangeUniversidad}
                                //onInputChange={this._handleSearchIdiomas}
                                ref={(ref) => this._typeaheadUniversidad = ref}
                                paginationText="Ver mas resultados"
                            />
                        </div>
                    </div> */}
    
                    <div className="col-md-6">
                        <div className="form-holder ">
    
                            <Label>Ciudad</Label>
                            <Input type="text" className="form-control" id="ciudad" name="ciudad"  value={this.state.ciudad} placeholder="Ciudad" required onChange={this.handleChange} onKeyUp={this.validarAvanzar} />
    
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-holder ">
                            <Label>Direcci&oacute;n</Label>
                            <Input type="text" className="form-control Input-border"  value={this.state.direccion} id="direccion" name="direccion" placeholder="Sector, avenida o calle donde resides" required onChange={this.handleChange} onKeyUp={this.validarAvanzar}  />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-holder">
                            <Tooltip placement="top" isOpen={this.state.tooltipAspiracionOpen} target="tooltipAspiracion" toggle={this.toggleTooltipAspiracion}>
                                Sólo trabajamos en base a costos mensuales, si desea ingresar un monto por horas no se postule
                            </Tooltip>
                            <Label id="tooltipAspiracion">
                                Aspiración salarial MENSUAL en dolares USD
                                <FaStickyNote style={{ marginLeft: '5px', color: '#666' }} />
                            </Label>
                            <Input type="number" name="aspiracion"  value={this.state.aspiracion} id="aspiracion" className="form-control" pattern="[^@]+@[^@]+.[a-zA-Z]{2,6}" placeholder="Monto en dolares" required  onChange={this.handleChange} onKeyUp={this.validarAvanzar} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-holder">
                            <Tooltip placement="top" isOpen={this.state.tooltipDisponibilidadOpen} target="tooltipDisponibilidad" toggle={this.toggleTooltipDisponibilidad}>
                                Sólo trabajamos con especialistas con disponibilidad a tiempo completo, si no la tiene igual registrese y cuando si tenga disponibilidad entre al sistema y cambie esta casilla
                            </Tooltip>
                            <Label id="tooltipDisponibilidad" >
                                Tiene disponibilidad para trabajar 8h diarias lunes-viernes? 
                                <FaStickyNote style={{ marginLeft: '5px', color: '#666' }} />
                            </Label>
                            
                            <div className="caja-select">
                                <Input type="select" name="disponibilidad" value={this.state.disponibilidad} id="disponibilidad" onChange={this.handleChangeSelect} onKeyUp={this.validarAvanzar} onKeyDown={this.validarAvanzar}  onKeyPress={this.validarAvanzar}  >
                                    <option value="" disabled>Seleccione...</option>
                                    <option value="Si, si tengo disponibilidad para trabajo FULL TIME">Si, si tengo disponibilidad para trabajo FULL TIME</option>   
                                    <option value="No tengo, pero apenas tenga disponibilidad lo indicare" >No tengo, pero apenas tenga disponibilidad lo indicare</option>
                                </Input>
                            </div>
                        </div>
                    </div>
    
                </div>

                <div className="form-group row my-0 mt-5">
                    <div className="col-12">
                        <ul className="nav nav-tabs  d-flex align-items-center justify-content-between" role="tablist">
                            <li className="nav-item">
                            <Button color="success" id="btn_prev_generales" className="btn btn-success rounded-circle btn-previous"  onClick={() => {this.changeStep('1'); }}>
                                <i className="zmdi zmdi-arrow-left"></i>
                            </Button>
                                
                            </li>
                            <li className="nav-item">
                                <Button color="success" id="btn_prev_generales" className="btn btn-success rounded-circle btn-next" disabled={this.validarSeguir() === false}
                                     onClick={this.saveDatos}  >
                                    <i className="zmdi zmdi-arrow-right"></i>
                                </Button>
                            </li>
                        </ul>
                    </div>
                </div>

                {this.validarSeguir() === false && 
                    <div className="row mt-1">
                        <div className="col-12" style={{display: 'flex', justifyContent: 'center'}} >
                            <b><Label style={{color: '#dc3545'}}>* Faltan datos por llenar ({this.showError()}) </Label></b>
                        </div>
                        {/*this.state.aspiracion === '0' &&
                            <div className="col-12" style={{display: 'flex', justifyContent: 'center'}} >
                                <b><Label style={{color: '#dc3545'}}>*Aspiración salarial debe ser mayor a 0</Label></b>
                            </div>
                        */}
                    </div>
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return{
        perfil: state.perfil,
        //token: state.token
    }
}

const mapDispatchToProps = {
    setPerfil,
}

export default connect(mapStateToProps, mapDispatchToProps) (TabDatosGenerales);
//export default TabDatosGenerales