import React, { Component } from 'react';
import firebase from '../firebase/firebase';
import { Alert, Input, Button, Card, CardBody, CardHeader, 
    UncontrolledCollapse, Tooltip, Pagination, PaginationItem, PaginationLink,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge    
} from 'reactstrap';
// import createHistory from 'history/createBrowserHistory';
import Menu from '../components/Menu';
import moment from 'moment';
import 'moment/locale/es'
import { FaCheckDouble, FaAngleDown } from 'react-icons/fa'; 
import {Link} from "react-router-dom";
import axios from 'axios';
import config from '../config/index';
moment.locale('es')


class Mensajes  extends Component {

    constructor(props) {
        super(props);

        this.send = this.send.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.closeAlerts = this.closeAlerts.bind(this);
        this.logout = this.logout.bind(this);
        this.toggle = this.toggle.bind(this);
        this.isToolTipOpen = this.isToolTipOpen.bind(this);
        this.cerrarChat = this.cerrarChat.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.dropdownItemClick = this.dropdownItemClick.bind(this);     
        
        this.state = {
            respuestas: [],
            mensajesTodos: [],
            openAlert: false,
            alertType: 'danger',
            alertMessage: '',
            programador: null,
            chats: [],
            contadorChatsSinLeer: 0,
            currentPage: 0,
            pageCount: 0,
            pageSize: 50,
            dropdownOpen: false,
            isOpened: [], 
            autorespuestas: [],
            loader: true
        }
    }

    componentDidMount(){
        let query = firebase.db.collection('programadores').where("admin","==", false);
        query.onSnapshot(querySnapshot => {
            querySnapshot.docChanges().forEach(change => {
                if (change.type === 'modified') {
                    //this.getData(change.doc.data());
                    this.loadMensajes();
                }
            });
        }, err => {
            console.log(`Encountered error: ${err}`);
        });
        this.loadMensajes();
        this.loadAutorespuestas();
    }

    loadAutorespuestas(){
        firebase.db.collection("autorespuestas")
           .orderBy("nombremostrar")
            .get()
            .then(querySnapshot => {
                let objetoAutorepuestas;
                let arrayAutorespuestas = []
                querySnapshot.forEach((doc) => {
                    objetoAutorepuestas = doc.data();
                    objetoAutorepuestas.id = doc.id
                    arrayAutorespuestas.push(objetoAutorepuestas)
                });
                this.setState({ autorespuestas: arrayAutorespuestas })
            });
    }

    loadMensajes(){
        firebase.db.collection("programadores")
            .where("admin","==", false)  
            .get()
            .then(querySnapshot => {
                let objetoProgramador;
                let arrayProgramador = []
                let arrayRespuestas = []
                let arrayChats = [];
                let isOpenedArray = [];
                querySnapshot.forEach((doc) => {
                    objetoProgramador = doc.data();
                    objetoProgramador.id = doc.id
                    arrayProgramador.push(objetoProgramador)
                });
                arrayProgramador.forEach((item) => {
                    if(item.chat.mensajes.length > 0){
                        item.chat.mensajes.sort(this.sortMessages);
                        arrayChats.push(item.chat)
                    }
                });
                arrayChats.forEach((item) => {
                    arrayRespuestas.push('');
                    isOpenedArray.push(false);
                })
                arrayChats = arrayChats.sort(this.sortChat);
                let contador = this.contadorSinLeer(arrayChats);
                this.setState({ 
                    chats: arrayChats, respuestas: arrayRespuestas, contadorChatsSinLeer: contador,
                    pageCount: Math.ceil(arrayChats.length / this.state.pageSize),
                    isOpened: isOpenedArray, loader: false
                })
            });
    }

    sortMessages(a,b){  
        var dateA = new Date(a.fecha).getTime();
        var dateB = new Date(b.fecha).getTime();
        return dateA <= dateB ? 1 : -1;  
    }; 

    sortChat(a,b){ 
        var dateA = new Date(a.actualizado).getTime();
        var dateB = new Date(b.actualizado).getTime();
        return (dateA >= dateB)?-1:1;
    };

    send(e, i){
        const self = this;
        let resp = [];
        let chat = [];
        let idProgramador;
        resp = this.state.respuestas
        chat = this.state.chats;
        idProgramador = chat[i].id
        chat[i].mensajes.push({
            creador: "admin", id: idProgramador, texto: resp[i], leido: 0, 
            fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), })
        const body = {
            actualizado: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            id: chat[i].id,
            cerrado: chat[i].cerrado,
            mensajes: chat[i].mensajes,
            nombres: chat[i].nombres,
            apellidos: chat[i].apellidos,
            email: chat[i].email,
            numWhatsapp: chat[i].numWhatsapp,
            cedula: chat[i].cedula,
            anosExperiencia: chat[i].anosExperiencia,
            nivelIngles: chat[i].nivelIngles,
            titulo: chat[i].titulo,
            universidad: chat[i].universidad,
            ciudad: chat[i].ciudad,
            direccion: chat[i].direccion,
            aspiracion: chat[i].aspiracion,
            fecha: chat[i].fecha,
            tipoPerfiles: chat[i].tipoPerfiles,
            idiomas: chat[i].idiomas ? chat[i].idiomas : []
        };
        const programador = `${chat[i].nombres} ${chat[i].apellidos}`;
        firebase.db.collection('programadores').doc(`${idProgramador}`).update({chat: body })
        .then( () => {

            
            const data = {
                email:  chat[i].email,	
                motivo: `<p>Estimado/a, <b>${programador}</b>, Ha recibido el siguiente mensaje .</p> 
                <p><b>${resp[i]} .</b></p>
                <p>para responder a este mensaje por favor entra en http://postulate.hispanossoluciones.com/ </p>`
            }
            this.notificarProgramador(data);


            this.setState({
                alertType: 'success',
                alertMessage: 'Mensaje Enviado exitosamente',
                openAlert: true,
            });
            this.loadMensajes();
            setTimeout(function(){ self.setState({openAlert: false}) }, 3000);
        })
        .catch(err => {
            console.log(err.message);
        });
        
    }

    async notificarProgramador(data){
        return await axios.post(`${config.notificationUrl}/sendmail`,  data )
    }

    cerrarChat(e, i){
        const self = this;
        let chat = [];
        let idProgramador;
        chat = this.state.chats;
        idProgramador = chat[i].id
        const body = {
            actualizado: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            id: chat[i].id,
            cerrado: 1,
            mensajes: chat[i].mensajes,
            nombres: chat[i].nombres,
            apellidos: chat[i].apellidos,
            email: chat[i].email,
            numWhatsapp: chat[i].numWhatsapp,
            cedula: chat[i].cedula,
            anosExperiencia: chat[i].anosExperiencia,
            nivelIngles: chat[i].nivelIngles,
            titulo: chat[i].titulo,
            universidad: chat[i].universidad,
            ciudad: chat[i].ciudad,
            direccion: chat[i].direccion,
            aspiracion: chat[i].aspiracion,
            fecha: chat[i].fecha,
            tipoPerfiles: chat[i].tipoPerfiles,
            idiomas: chat[i].idiomas ? chat[i].idiomas : []
        };
        firebase.db.collection('programadores').doc(`${idProgramador}`).update({chat: body })
        .then( () => {
            this.setState({
                alertType: 'success',
                alertMessage: 'Chat cerrado exitosamente',
                openAlert: true,
            });
            this.loadMensajes();
            setTimeout(function(){ self.setState({openAlert: false}) }, 3000);
        })
        .catch(err => {
            console.log(err.message);
        });
    }

    handleChange(e, i){
        let respuestasNew = [];
        respuestasNew = this.state.respuestas;
        respuestasNew[i] = e.target.value;
        this.setState({ respuestas:  respuestasNew });
    }

    closeAlerts(){
        this.setState({
            openAlert: false
        })
    }

    logout() {
        // const history = createHistory()
        firebase.logout();
        localStorage.removeItem('redux');
        // history.replace('/');
    }

    mensajesSinLeer(mensajes) {
        let resp = true;
        mensajes.forEach((item, i) => {
            if (item.creador === 'admin') {
                resp = false;
            }
        });
        return resp;
    }

    ultimoMensajeRespondido(chats){
        let ultimo = chats[chats.length -1];
        const ultimafecha = ultimo.mensajes[0].fecha;
        return ultimafecha;
    }

    contadorSinLeer(chats) {
        let contador = 0;
        chats.forEach((item, i) => {
            let resp = true;
            item.mensajes.forEach((msg, j) => {
                if (msg.creador === 'admin') {
                    resp = false;
                    return;
                }
            });
            if(resp === true){
                contador++;
            }
        });
        return contador;
    }

    toggle = targetName => {
        if (!this.state[targetName]) {
          this.setState({
            ...this.state,
            [targetName]: {
              tooltipOpen: true
            }
          });
        } else {
          this.setState({
            ...this.state,
            [targetName]: {
              tooltipOpen: !this.state[targetName].tooltipOpen
            }
          });
        }
    };
    
    isToolTipOpen = targetName => {
        return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
    };

    colorChat(chat){
        if(this.mensajesSinLeer(chat.mensajes) === true &&  chat.cerrado === 1){
            return '#d4edda';
        } else {
            if(chat.cerrado === 1) {
                return '#d4edda';
            } else if(this.mensajesSinLeer(chat.mensajes) === true){
                return '#f8d7da';
            } else {
                return '#fff3cd';
            }
        }
    }

    fontColorChat(chat){
        if(this.mensajesSinLeer(chat.mensajes) === true &&  chat.cerrado === 1){
            return '#155724';  
        } else {
            if(chat.cerrado === 1) {
                return '#155724';
            } else if(this.mensajesSinLeer(chat.mensajes) === true){
                return '#721c24';
            } else {
                return '#856404';
            }
        }
    }

    handlePageClick = (e, index) => {
        e.preventDefault();
        this.setState({currentPage: index});
    };

    toggleDropdown(event,  index){
        let total = this.state.isOpened;
        let actual = !this.state.isOpened[index];
        total[index] = actual;
        this.setState({isOpened: total})
    }

    dropdownItemClick(event , texto, indexPadre){
        let respuestas  = this.state.respuestas;
        respuestas[indexPadre] = texto;
        this.setState({ respuestas});
    }

    splitNombres(value){

        const cortado = value.split(' ');

        if(cortado[0] === ''){
            return cortado[1];
        } else {
            return cortado[0];
        }
        // return value.split(' ')[0];
    }

    itemsPerPage(){
        let array = []
        array = this.state.chats.slice(this.state.currentPage * this.state.pageSize, (this.state.currentPage + 1) * this.state.pageSize)
        return  (this.state.currentPage * this.state.pageSize) + array.length;
    }

    render(){
        return(
            <div> 
                <Menu link='mensajes' logout={this.logout} ></Menu>
                {this.state.loader &&
                    <div id="loader">
                        <div className="lds-heart"><div></div></div>
                    </div>
            }
            {!this.state.loader &&
                <main className="container d-flex align-items-center justify-content-center topMain mb-3">
                    <div className="row h-100 align-items-center justify-content-center w-100">
                        <div className="row p-0 w-100">
                             <div className="col-12 p-0 list-postul rounded border border-secondary shadow-lg">
                                <div className="list-postul-header text-center p-3">
                                    <h2>Mensajes</h2>
                                </div>
                                <div className="list-postul-content py-3 px-2 px-md-5 text-left">
                                    <div className="datos-content w-100 py-3">
                                        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 15}}>
                                            <h5 style={{color: '#3e4061'}}>Conversaciones sin responder  <Badge color="success">{this.state.contadorChatsSinLeer}</Badge></h5>
                                            {/*<h5 style={{color: '#3e4061'}}>Fecha utimo mensaje <Badge color="success">{this.state.contadorChatsSinLeer}</Badge></h5>*/}
                                        </div>
                                        
                                    {this.state.chats 
                                        .slice (this.state.currentPage * this.state.pageSize, (this.state.currentPage + 1) * this.state.pageSize) 
                                        .map ((chat, key) => { 
                                        return ( 

                                            <Card key={key} style={{marginBottom: 10}} >
                                                <CardHeader style={{backgroundColor: this.colorChat(chat), color: this.fontColorChat(chat), display: 'flex'}} >
                                                    <div style={{flex: 'auto', display: 'flex', justifyContent: 'space-between'}} className="card-mensajes" >
                                                        <b><Link style={{ color: this.fontColorChat(chat)}} target="_blank" to={{ pathname: `/detalle/${chat.id}`,}}>
                                                            {this.splitNombres(chat.nombres)}  {this.splitNombres(chat.apellidos)}</Link>
                                                        </b> 
                                                        <span style={{marginLeft: 10}}>{chat.ciudad}</span>
                                                        <span style={{marginLeft: 10}}>Idiomas: {chat.idiomas  ? chat.idiomas.join(',') :  'Español' }</span>
                                                        <span>{/*chat.tipoPerfiles.length !== 0 &&
                                                            chat.tipoPerfiles.map((per, k) => ( 
                                                                k !== (chat.tipoPerfiles.length -1)  ? `${per}, ` : `${per}` 
                                                            ))
                                                            */}
                                                        {chat.tipoPerfiles[0]}
                                                        </span>
                                                        <span style={{marginLeft: 10}}>{moment(chat.actualizado).fromNow()}</span>
                                                    </div>
                                                    <div style={{marginLeft: 20}}>
                                                        <Tooltip
                                                            placement="top"
                                                            isOpen={this.isToolTipOpen(`cerrar-${key}`)}
                                                            target={`cerrar-${key}`}
                                                            toggle={() => this.toggle(`cerrar-${key}`)}>
                                                            Cerrar comunicación
                                                        </Tooltip>
                                                        <Button color="danger" id={'cerrar-'+key} size="sm" style={{ float: 'right', marginLeft: 5 }} onClick={e => this.cerrarChat(e, key)}  >
                                                            <FaCheckDouble />
                                                        </Button>
                                                        <Tooltip
                                                            placement="top"
                                                            isOpen={this.isToolTipOpen(`toggle-${key}`)}
                                                            target={`toggle-${key}`}
                                                            toggle={() => this.toggle(`toggle-${key}`)}>
                                                            Ver detalle
                                                        </Tooltip>
                                                        <Button color="primary" size="sm" id={'toggle-'+key} style={{ float: 'right' }}>
                                                            <FaAngleDown />
                                                        </Button>
                                                        </div>
                                                </CardHeader>
                                                <UncontrolledCollapse toggler={'#toggle-'+key}>
                                                    <CardBody>
                                                        {chat.mensajes.map((msg, j) => (                                           
                                                            <Alert color={ msg.creador === 'programador' ? 'info' : 'success' } key={j} >
                                                                {msg.creador === 'programador' &&
                                                                <div style={{display: 'flex', flexDirection: 'column'}} className='text-left'>
                                                                    <span><b><Link target="_blank" to={{ pathname: `/detalle/${chat.id}`,}} style={{color: '#0c5460'}} >{this.splitNombres(chat.nombres)}  {this.splitNombres(chat.apellidos)}</Link> </b><label style={{fontSize:13}}>{moment(new Date(msg.fecha)).format('DD-MM-YYYY HH:mm:ss')}</label></span>
                                                                    <span>{msg.texto}</span>
                                                                </div>
                                                                }
                                                                {msg.creador === 'admin' &&
                                                                    <div style={{display: 'flex', flexDirection: 'column'}} className='text-left'>
                                                                        <span><b>Yo </b><label style={{fontSize:13}}>{moment(new Date(msg.fecha)).format('DD-MM-YYYY HH:mm:ss')}</label></span>
                                                                        <span>{msg.texto}</span>
                                                                    </div>
                                                            }

                                                            </Alert>
                                                        ))}
                                                        <div style={{display: 'flex', flexDirection:'column'}} >
                                                            <div style={{display: 'flex', flexDirection:'column'}}>
                                                                <Alert color={this.state.alertType} isOpen={this.state.openAlert} toggle={this.closeAlerts} >
                                                                    {this.state.alertMessage}
                                                                </Alert>
                                                            </div>
                                                            <div style={{display: 'flex', flexDirection:'row'}} >
                                                                <Input type="text" className="form-control" id={'respuesta'+key} name={'respuesta'+[key]} value={this.state.respuestas[key]} style={{marginRight: 5 }} onChange={e => this.handleChange(e, key)} /> 
                                                                <Button color="success" onClick={e => this.send(e, key)}  disabled={this.state.respuestas[key] === ''} >Responder</Button>
                                                            </div>
                                                        </div>

                                                        {<div style={{display: 'flex', flexDirection:'row', justifyContent:'flex-end', marginTop: 5}} key={key} >
                                                            <Dropdown isOpen={this.state.isOpened[key]} toggle={e => this.toggleDropdown(e, key)} size="sm" >
                                                                <DropdownToggle caret>
                                                                    Respuesta rápida
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    {this.state.autorespuestas.map((auto, k) => ( 
                                                                        <DropdownItem key={k} onClick={e => this.dropdownItemClick(e, auto.texto, key)} >
                                                                            { auto.nombremostrar }
                                                                        </DropdownItem>
                                                                    ))}
                                                                </DropdownMenu>
                                                            </Dropdown>
                                                        </div>}

                                                    </CardBody>
                                                </UncontrolledCollapse>
                                                
                                            </Card>
                                           );
                                        })}

                                        <div style={{display: 'flex', justifyContent: 'space-between'}} >
                                            <span>Mostrando<b> {(this.state.currentPage * this.state.pageSize) + 1}&nbsp;</b> 
                                                a <b>{this.itemsPerPage()}&nbsp;</b> 
                                                de <b>{this.state.chats.length}</b>
                                            </span>
                                            <Pagination size="sm" aria-label="Page navigation example">
                                                {[...Array(this.state.pageCount)].map((page, i) => (
                                                    <PaginationItem active={i === this.state.currentPage} key={i}>
                                                        <PaginationLink onClick={e => this.handlePageClick(e, i)} href="#">
                                                            {i + 1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                ))}
                                            </Pagination>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </main>
            }
            </div>
        )
    }
}

export default Mensajes