import React  from 'react';
import { Input, Label  } from 'reactstrap';

function Experiencia(props) {

    return <div className="form-group row m-0">
    <div className="col-12">
        <div className="form-holder">
            <Label>Empresa</Label>
            <Input type="text" className="form-control"  name="empresaNombre" value={props.experiencia.empresaNombre}
                onChange={(e) => {props.valueInput(e.target.value, e.target.name, props.indice )}} 
                readOnly={!props.edit} />
        </div>
    </div>
    <div className="col-12">
        <div className="form-holder">
            <Label><b>Descripcion de las actividades</b></Label>
            {!props.edit &&
                <p style={{textAlign: 'justify'}}>
                    {props.experiencia.empresaDescripcion}
                </p>
            }
            {props.edit &&
                <Input type="textarea"  name="empresaDescripcion" id="empresaDescripcion" cols="90" rows="4"    
                    value={props.experiencia.empresaDescripcion} 
                    onChange={(e) => {props.valueInput(e.target.value, e.target.name, props.indice )}}
                />
            }
        </div>
    </div>
</div>
}

export default Experiencia;