import React, { Component } from 'react';
import { Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Toast, ToastBody, ToastHeader, 
    Alert, Card, CardBody, Tooltip   } from 'reactstrap';
import firebase from '../firebase/firebase';
import { FaEdit } from 'react-icons/fa';
import { FaTrashAlt } from 'react-icons/fa';
import { FaPlus, FaFileExport, FaFileImport } from 'react-icons/fa';
import Menu from '../components/Menu';
import CSVReader from "react-csv-reader";
import LoadingOverlay from 'react-loading-overlay';
import {exportCSVFile} from '../utilidades/exportCsv';
import moment from 'moment';

const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
};
  
class Configuracion  extends Component {

    constructor(props) {
        super(props);

        this.toggle  = this.toggle.bind(this);
        this.toggleToast  = this.toggleToast.bind(this);
        this.add  = this.add.bind(this);
        this.edit  = this.edit.bind(this);
        this.delete  = this.delete.bind(this);
        this.guardar  = this.guardar.bind(this);
        this.cancelar  = this.cancelar.bind(this);
        this.handleChange  = this.handleChange.bind(this);
        this.onDismiss  = this.onDismiss.bind(this);
        this.logout  = this.logout.bind(this);
        this.addCategoria  = this.addCategoria.bind(this);
        this.editarCategoria  = this.editarCategoria.bind(this);
        this.deleteCategoria  = this.deleteCategoria.bind(this);
        this.cancelarCategoria  = this.cancelarCategoria.bind(this);
        this.toggleCategoria  = this.toggleCategoria.bind(this);
        this.guardarCategoria  = this.guardarCategoria.bind(this);
        this.addAutorespuesta = this.addAutorespuesta.bind(this);
        this.editAutorespuesta  = this.editAutorespuesta.bind(this);
        this.deleteAutorespuesta  = this.deleteAutorespuesta.bind(this);
        this.cancelarAutorespuesta  = this.cancelarAutorespuesta.bind(this);
        this.guardarAutorespuesta  = this.guardarAutorespuesta.bind(this);
        this.toggleAutorespuesta  = this.toggleAutorespuesta.bind(this);
        this.importTecnologias = this.importTecnologias.bind(this);
        this.exportTecnologias = this.exportTecnologias.bind(this);
        this.importCategorias = this.importCategorias.bind(this);
        this.exportCategorias = this.exportCategorias.bind(this);
        this.handleForce = this.handleForce.bind(this);
        this.handleForceCategorias = this.handleForceCategorias.bind(this);
        this.toggleTooltipExportarTecnologia = this.toggleTooltipExportarTecnologia.bind(this);
        this.toggleTooltipImportarTecnologia = this.toggleTooltipImportarTecnologia.bind(this);
        this.toggleTooltipExportarCategoria= this.toggleTooltipExportarCategoria.bind(this);
        this.toggleTooltipImportarCategoria = this.toggleTooltipImportarCategoria.bind(this);
        this.handleErrorTecnologias =   this.handleErrorTecnologias.bind(this);
        this.handleErrorCategorias =   this.handleErrorCategorias.bind(this);
        this.changeUserAdminEmpresas =   this.changeUserAdminEmpresas.bind(this);

        this.state = {
            perfil: null,
            tecnologias: [],
            tecnologiasFilter: [],
            modal: false,
            edit: false,
            name: '',
            idEditar: '',
            toast: false,
            toastTitulo: 'ejemplo',
            toastMensaje: 'ejemplo',
            iconToast: 'success',
            openAlert: false,
            mensajeAlert: '',
            colorAlert: 'success',
            categorias: [],
            categoriasFilter: [],
            modalCategorias: false,
            editCategoria: false,
            nombre: '',
            idEditarCategoria: '',
            search: '',
            searchCategoria: '',
            autorespuestas: [],
            modalAutorespuestas: false,
            editAutorespuestas: false,
            nombremostrar: '',
            texto: '',
            idEditarAutorespuesta: '',
            tooltipExportarTecnologia: false,
            tooltipImportarTecnologia: false,
            tooltipExportarCategoria: false,
            tooltipImportarCategoria: false,
            loading: false,
            isAdminEmpresas: false,
            loader: true
        }
    }

    componentDidMount(){
        this.verificarExistencia(firebase.auth.currentUser.uid);
        this.loadTecnologias();
        this.loadCategorias();
        this.loadAutorespuestas();
    }

    toggleTooltipExportarTecnologia(){
        this.setState({ tooltipExportarTecnologia: !this.state.tooltipExportarTecnologia})
    } 

    toggleTooltipImportarTecnologia(){
        this.setState({ tooltipImportarTecnologia: !this.state.tooltipImportarTecnologia})
    }

    toggleTooltipExportarCategoria(){
        this.setState({ tooltipExportarCategoria: !this.state.tooltipExportarCategoria})
    } 

    toggleTooltipImportarCategoria(){
        this.setState({ tooltipImportarCategoria: !this.state.tooltipImportarCategoria})
    }

    loadTecnologias(){
        firebase.db.collection("tecnologias")
           .orderBy("name")
            .get()
            .then(querySnapshot => {
                let objetoTecnologia;
                let arrayTecnologia = []
                querySnapshot.forEach((doc) => {
                    objetoTecnologia = doc.data();
                    objetoTecnologia.id = doc.id
                    arrayTecnologia.push(objetoTecnologia)
                });
                this.setState({ tecnologias: arrayTecnologia, tecnologiasFilter: arrayTecnologia, loader: false })
            });
    }
  
    loadCategorias(){
        firebase.db.collection("categorias")
           .orderBy("nombre")
            .get()
            .then(querySnapshot => {
                let objetoCategoria;
                let arrayCategoria = []
                querySnapshot.forEach((doc) => {
                    objetoCategoria = doc.data();
                    objetoCategoria.id = doc.id
                    arrayCategoria.push(objetoCategoria)
                });
                this.setState({ categorias: arrayCategoria, categoriasFilter: arrayCategoria, loader: false })
            });
    }

    loadAutorespuestas(){
        firebase.db.collection("autorespuestas")
           .orderBy("nombremostrar")
            .get()
            .then(querySnapshot => {
                let objetoAutorepuestas;
                let arrayAutorespuestas = []
                querySnapshot.forEach((doc) => {
                    objetoAutorepuestas = doc.data();
                    objetoAutorepuestas.id = doc.id
                    arrayAutorespuestas.push(objetoAutorepuestas)
                });
                this.setState({ autorespuestas: arrayAutorespuestas })
            });
    }

    toggle(){
        this.setState({modal: !this.state.modal});
    }

    toggleToast(){
        this.setState({toast: !this.state.toast});
    }

    add(){
        this.setState({edit: false, modal: true});
    }

    edit(data){
        this.setState({name: data.name, idEditar: data.id, edit: true, modal: true });
    }

    delete(data){
        const self = this;
        this.setState({name: data.name, idEditar: data.id });

        firebase.db.collection("tecnologias").doc(data.id)
            .delete()
            .then(function() {
                self.setState({
                    toastTitulo: 'tecnología',
                    toastMensaje: 'tecnología eliminada exitosamente',
                    iconToast: 'success',
                    edit: false,
                    name:  '', 
                    idEditar: '',
                    toast: true
                })
                setTimeout(function(){ self.setState({toast: false}) }, 2000)
                self.loadTecnologias()
            }).catch(function(err) {
                console.log(err.message);
            });
    }

    guardar(){
        const self = this;
       if(this.state.name  === '') {
            this.setState({mensajeAlert:'El nombre es requerido', colorAlert: 'danger', openAlert: true })
       } else {
        const body = {name: this.state.name };
        if (this.state.edit === true) {
            firebase.db.collection("tecnologias").doc(`${this.state.idEditar}`)
            .update({name: this.state.name})
            .then(result => {
                this.setState({
                    toastTitulo: 'tecnología',
                    toastMensaje: 'tecnología actualizada exitosamente',
                    iconToast: 'success',
                    edit: false,
                    name:  '', 
                    idEditar: '',
                    modal: false,
                    toast: true
                })
                setTimeout(function(){ self.setState({toast: false}) }, 2000)
                this.loadTecnologias()

            }).catch(err => {
                console.log(err.message);
            });
        } else {
            firebase.db.collection('tecnologias').add(body)
            .then( () =>{
                this.setState({
                    toastTitulo: 'tecnología',
                    toastMensaje: 'tecnología creada exitosamente',
                    iconToast: 'success',
                    edit: false, 
                    name:  '', 
                    idEditar: '',
                    modal: false,
                    toast: true
                })
                setTimeout(function(){ self.setState({toast: false}) }, 2000)
                this.loadTecnologias()
            })
            .catch(err => {
                console.log(err.message);
            });
        }
       }
    }

    cancelar(){
        this.setState({modal: false, name:  '', idEditar: '', openAlert: false });
    }

    handleChange(e){
        //this.setState({name: e.target.value, openAlert: false})
        this.setState({[e.target.name]: e.target.value, openAlert: false});
    }

    onDismiss(){
        this.setState({openAlert: !this.state.openAlert})
    }

    logout() {
        // const history = createHistory();
        // const history = createHistory.createBrowserHistory();
        firebase.logout();
        localStorage.removeItem('redux');
        // history.replace('/');
    }

    addCategoria(){
        this.setState({editCategoria: false, modalCategorias: true});
    }

    editarCategoria(data){
        this.setState({nombre: data.nombre, idEditarCategoria: data.id, editCategoria: true, modalCategorias: true });
    }

    deleteCategoria(data){
        const self = this;
        this.setState({name: data.name, idEditar: data.id });

        firebase.db.collection("categorias").doc(data.id)
            .delete()
            .then(function() {
                self.setState({
                    toastTitulo: 'categoría',
                    toastMensaje: 'categoría eliminada exitosamente',
                    iconToast: 'success',
                    editCategoria: false,
                    nombre:  '', 
                    idEditarCategoria: '',
                    toast: true
                })
                setTimeout(function(){ self.setState({toast: false}) }, 2000)
                self.loadCategorias();
            }).catch(function(err) {
                console.log(err.message);
            });
    }

    cancelarCategoria(){
        this.setState({modalCategorias: false, nombre:  '', idEditarCategoria: '', openAlert: false });
    }

    toggleCategoria(){
        this.setState({modalCategorias: !this.state.modalCategorias});
    }

    guardarCategoria(){
        const self = this;
        if(this.state.nombre  === '') {
             this.setState({mensajeAlert:'El nombre es requerido', colorAlert: 'danger', openAlert: true })
        } else {
            if (this.state.editCategoria === true) {
                firebase.db.collection("categorias").doc(`${this.state.idEditarCategoria}`)
                .update({nombre: this.state.nombre})
                .then(result => {
                    this.setState({
                        toastTitulo: 'categoría',
                        toastMensaje: 'categoría actualizada exitosamente',
                        iconToast: 'success',
                        editCategoria: false,
                        nombre:  '', 
                        idEditarCategoria: '',
                        modalCategorias: false,
                        toast: true
                    })
                    setTimeout(function(){ self.setState({toast: false}) }, 2000)
                    this.loadCategorias();
    
                }).catch(err => {
                    console.log(err.message);
                });
            } else {
                firebase.db.collection('categorias').add({nombre: this.state.nombre, cantidadPersonas: 0 })
                .then( () =>{
                    this.setState({
                        toastTitulo: 'categoría',
                        toastMensaje: 'categoría creada exitosamente',
                        iconToast: 'success',
                        editCategoria: false, 
                        nombre:  '', 
                        idEditarCategoria: '',
                        modalCategorias: false,
                        toast: true
                    })
                    setTimeout(function(){ self.setState({toast: false}) }, 2000)
                    this.loadCategorias();
                })
                .catch(err => {
                    console.log(err.message);
                });
            }
        }
    }

    toggleAutorespuesta(){
        this.setState({modalAutorespuestas: !this.state.modalAutorespuestas});
    }

    addAutorespuesta(){
        this.setState({editAutorespuestas: false, modalAutorespuestas: true});
    }

    editAutorespuesta(data){
        this.setState({nombremostrar: data.nombremostrar, texto: data.texto, idEditarAutorespuesta: data.id, 
            editAutorespuestas: true, modalAutorespuestas: true });
    }

    deleteAutorespuesta(data){
        const self = this;
        this.setState({name: data.name, idEditar: data.id });

        firebase.db.collection("autorespuestas").doc(data.id)
            .delete()
            .then(function() {
                self.setState({
                    toastTitulo: 'mensaje de autrespuesta',
                    toastMensaje: 'mensaje de autrespuesta eliminada exitosamente',
                    iconToast: 'success',
                    editAutorespuesta: false,
                    nombremostrar:  '', 
                    texto:  '', 
                    idEditarAutorespuesta: '',
                    toast: true
                })
                setTimeout(function(){ self.setState({toast: false}) }, 2000)
                self.loadAutorespuestas()
            }).catch(function(err) {
                console.log(err.message);
            });
    }

    guardarAutorespuesta(){
        const self = this;
       if(this.state.nombremostrar  === '' || this.state.texto  === '') {
            this.setState({mensajeAlert:'Faltan campos por llenar', colorAlert: 'danger', openAlert: true })
       } else {
        const body = {nombremostrar: this.state.nombremostrar, texto: this.state.texto };
        if (this.state.editAutorespuestas === true) {
            firebase.db.collection("autorespuestas").doc(`${this.state.idEditarAutorespuesta}`)
            .update(body)
            .then(result => {
                this.setState({
                    toastTitulo: 'Autorespuesta',
                    toastMensaje: 'mensaje de autorespuesta  actualizado exitosamente',
                    iconToast: 'success',
                    editAutorespuesta: false,
                    nombremostrar:  '', 
                    texto: '',
                    idEditarAutorespuesta: '',
                    modalAutorespuestas: false,
                    toast: true
                })
                setTimeout(function(){ self.setState({toast: false}) }, 2000)
                this.loadAutorespuestas()

            }).catch(err => {
                console.log(err.message);
            });
        } else {
            firebase.db.collection('autorespuestas').add(body)
            .then( () =>{
                this.setState({
                    toastTitulo: 'Autorespuesta',
                    toastMensaje: 'mensaje de autorespuesta creado exitosamente',
                    iconToast: 'success',
                    editAutorespuesta: false, 
                    nombremostrar:  '', 
                    texto: '', 
                    idEditarAutorespuesta: false,
                    modalAutorespuestas: false,
                    toast: true
                })
                setTimeout(function(){ self.setState({toast: false}) }, 2000)
                this.loadAutorespuestas()
            })
            .catch(err => {
                console.log(err.message);
            });
        }
       }
    }

    cancelarAutorespuesta(){
        this.setState({modalAutorespuestas: false, nombremostrar:  '', texto: '',  idEditarAutorespuesta: '', openAlert: false });
    }

    importTecnologias(){
        const fileinput = document.getElementById('filetecnologias');
        fileinput.click();
    }

    exportTecnologias(){
        const headers = { name: 'name'};
        let data= [];
        this.state.tecnologiasFilter.forEach(item =>{
            data.push({name: item.name})
        });
        const filename = 'tecnologias';
        exportCSVFile(headers, data, filename);
    }

    importCategorias(){
        const fileinput = document.getElementById('filecategorias');
        fileinput.click();
    }

    exportCategorias(){
        const headers = { nombre: 'nombre', cantidadPersonas: 'cantidadPersonas'};
        let data = [];
        this.state.categoriasFilter.forEach(item =>{
            data.push({nombre: item.nombre, cantidadPersonas: item.cantidadPersonas })
        });
        const filename = 'categorias';
        exportCSVFile(headers, data, filename);
    }

    async handleForce(data, fileInfo){
        this.setState({ loading: true });
        const promises = [];
        data.forEach((value) => {
            promises.push(firebase.db.collection('tecnologias').add({ name: value.name }));
        });
        await Promise.all(promises);
        this.loadTecnologias();
        this.setState({ loading: false });
    }

    async handleForceCategorias(data, fileInfo){
        this.setState({ loading: true });
        const promises = [];
        data.forEach((value) => {
            promises.push(firebase.db.collection('categorias').add({ nombre: value.nombre, cantidadPersonas: 0 }));
        });
        await Promise.all(promises);
        this.loadCategorias();
        this.setState({ loading: false });
    }

    handleErrorTecnologias(event){
        console.log(event)
    }

    handleErrorCategorias(event){
        console.log(event)
    }

    changeUserAdminEmpresas(e){
        console.log(e.target.checked);
        if(e.target.checked) {
            this.saveDatos();
        } else {
            this.deleteAdminEmpresas();
        }
    }

    verificarExistencia(id){
        var docRef = firebase.db.collection('empresas').doc(id);
        docRef.get().then((doc) =>{
            if (doc.exists) {
                this.setState({ isAdminEmpresas: true })
            } else {
                this.setState({ isAdminEmpresas: false })
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    deleteAdminEmpresas(){
        firebase.db.collection("empresas").doc(firebase.auth.currentUser.uid).delete().then(() => {
            this.setState({isAdminEmpresas: false});
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    }

    saveDatos() {
        let datosEmpresa = {
            admin: true,
            contrasena: "",
            email: firebase.auth.currentUser.email,
            fechaCreacion: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            nombreEmpresa: '',
            numWhatsapp: '',
            pais: 'Venezuela',
            representanteLegal: '',
            rol: 'empresa',
            userId: firebase.auth.currentUser.uid,
            usuario: firebase.auth.currentUser.email,
            datosFiscales: {
                identifacionFiscal: "",
                direccion: "", 
                ciudad: ""
            },
            ocultos: []
        }
        firebase.db.collection('empresas').doc(`${firebase.auth.currentUser.uid}`).set(datosEmpresa)
        .then( () =>{
            this.setState({isAdminEmpresas: true});
        })
        .catch(err => {
            console.log(err.message);
        });
    }

    render(){
        const closeBtn = <button className="close" onClick={this.toggle}>&times;</button>;
        const closeBtnCategoria = <button className="close" onClick={this.toggleCategoria}>&times;</button>;
        const closeBtnAutorespuesta = <button className="close" onClick={this.toggleAutorespuesta}>&times;</button>;

        return(
            <div>
                <Menu link='configuracion' logout={this.logout} ></Menu>
            <LoadingOverlay
                active={this.state.loading}
                spinner
                text='Cargando...'
            >
            {this.state.loader &&
                <div id="loader">
                    <div className="lds-heart"><div></div></div>
                </div>
            }
            {!this.state.loader &&
                <main className="container d-flex align-items-center justify-content-center topMain mb-3">
                    <div className="row h-100 align-items-center justify-content-center w-100">
                        <div className="row p-0 w-100">
                            <div className="col-12 p-0 list-postul rounded border border-secondary shadow-lg">
                                <div className="list-postul-header text-center p-3">
                                    <h2>Configuración</h2>
                                </div>
                                <div className="list-postul-content py-3 px-2 px-md-5 text-left">
                                    <div className="row mb-5 justify-content-between">


                                        <div className="col-12" style={{marginBottom: 10}} >
                                            <Card>
                                                <CardBody>
                                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
                                                        <label><b>Usuario administrador en el sistema de empresas</b></label> 
                                                        <Input type="checkbox" id="useradminempresa"  
                                                            checked={this.state.isAdminEmpresas}
                                                            onChange={this.changeUserAdminEmpresas}
                                                            style={{position: 'relative', marginLeft: 10, marginTop: 0}}
                                                         />
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>

                                        <div className="col-12" style={{marginBottom: 10}} >
                                            <Card>
                                                <CardBody>
                                                    <h5>Mensajes de autorespuesta</h5>  
                                                    
                                                    <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="d-table-cell">Nombre</th>
                                                            <th scope="col" className="d-table-cell">Texto del mensaje</th>
                                                            <th scope="col" className="d-table-cell">
                                                                <Button color="primary" style={{marginRight: 5}} size="sm" onClick={this.addAutorespuesta} ><FaPlus /></Button>
                                                                Add
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                        <tbody>
                                                            {
                                                                this.state.autorespuestas.map((item, j) => (  
                                                                    <tr key={j}>
                                                                        <td>{item.nombremostrar}</td>
                                                                        <td>{item.texto}</td>
                                                                        <td>
                                                                            <Button color="primary" style={{marginRight: 5}} size="sm" onClick={() => {this.editAutorespuesta(item); }} ><FaEdit /></Button>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </CardBody>
                                            </Card>
                                        </div>

                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <Card>
                                                <CardBody>
                                                    <h5>Listado de tecnologías</h5>
                                                    <table className="table table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="d-table-cell">Nombre</th>
                                                                <th scope="col" className="d-table-cell">
                                                                    <Button color="primary" style={{marginRight: 5}} size="sm" onClick={this.add} ><FaPlus /></Button>
                                                                    Add
                                                                    <Button id="exportarTecnologia" color="info" size="sm" style={{ float: 'right', marginLeft: 5}} onClick={this.exportTecnologias}>
                                                                        <FaFileExport />
                                                                    </Button>
                                                                    <Tooltip
                                                                        placement="top"
                                                                        isOpen={this.state.tooltipExportarTecnologia}
                                                                        target="exportarTecnologia"
                                                                        toggle={this.toggleTooltipExportarTecnologia}
                                                                    >
                                                                        Exportar Tecnologias a CSV
                                                                    </Tooltip>

                                                                    <Button id="importarTecnologia" color="success" size="sm" style={{ float: 'right', marginLeft: 5}} onClick={this.importTecnologias}>
                                                                        <FaFileImport />
                                                                    </Button>
                                                                    <Tooltip
                                                                        placement="top"
                                                                        isOpen={this.state.tooltipImportarTecnologia}
                                                                        target="importarTecnologia"
                                                                        toggle={this.toggleTooltipImportarTecnologia}
                                                                    >
                                                                        Importar CSV
                                                                    </Tooltip>
                                                                    
                                                                    <CSVReader
                                                                        inputStyle={{display: 'none'}}
                                                                        inputId="filetecnologias"
                                                                        cssClass="react-csv-input"
                                                                        label=""
                                                                        onFileLoaded={this.handleForce}
                                                                        onError={this.handleErrorTecnologias}
                                                                        parserOptions={papaparseOptions}
                                                                    />
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.tecnologiasFilter.map((data, key) => { 
                                                                return (
                                                                    /// this.state.tecnologias.map((data, key) => (
                                                                    <tr key={key}>
                                                                        <td className="d-table-cell">{data.name}</td>
                                                                        <td className="d-table-cell">
                                                                            <Button color="primary" style={{marginRight: 5}} size="sm" onClick={() => {this.edit(data); }} ><FaEdit /></Button>
                                                                            <Button color="danger" size="sm"  onClick={() => {this.delete(data); }}><FaTrashAlt /></Button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}                                      
                                                        </tbody>
                                                    </table>
                                                </CardBody>
                                            </Card>
                                        </div>

                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <Card>
                                                <CardBody>
                                                    <h5>Listado de categorías</h5>
                                                    <table className="table table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="d-table-cell">Nombre</th>
                                                                <th scope="col" className="d-table-cell">
                                                                    <Button color="primary" style={{marginRight: 5}} size="sm" onClick={this.addCategoria} ><FaPlus /></Button>
                                                                    Add
                                                                    <Button id="exportarCategoria" color="info" size="sm" style={{ float: 'right', marginLeft: 5}} onClick={this.exportCategorias}>
                                                                        <FaFileExport />
                                                                    </Button>
                                                                    <Tooltip
                                                                        placement="top"
                                                                        isOpen={this.state.tooltipExportarCategoria}
                                                                        target="exportarCategoria"
                                                                        toggle={this.toggleTooltipExportarCategoria}
                                                                    >
                                                                        Exportar Categorías a CSV
                                                                    </Tooltip>

                                                                    <Button id="importarCategoria" color="success" size="sm" style={{ float: 'right', marginLeft: 5}} onClick={this.importCategorias}>
                                                                        <FaFileImport />
                                                                    </Button>
                                                                    <Tooltip
                                                                        placement="top"
                                                                        isOpen={this.state.tooltipImportarCategoria}
                                                                        target="importarCategoria"
                                                                        toggle={this.toggleTooltipImportarCategoria}
                                                                    >
                                                                        Importar CSV
                                                                    </Tooltip>
                                                                    
                                                                    <CSVReader
                                                                        inputStyle={{display: 'none'}}
                                                                        inputId="filecategorias"
                                                                        cssClass="react-csv-input"
                                                                        label=""
                                                                        onFileLoaded={this.handleForceCategorias}
                                                                        onError={this.handleErrorCategorias}
                                                                        parserOptions={papaparseOptions}
                                                                    />
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.categoriasFilter.map((data, key) => { 
                                                                return (
                                                                    /// this.state.tecnologias.map((data, key) => (
                                                                    <tr key={key}>
                                                                        <td className="d-table-cell">{data.nombre}</td>
                                                                        <td className="d-table-cell">
                                                                            <Button color="primary" style={{marginRight: 5}} size="sm" onClick={() => {this.editarCategoria(data); }} ><FaEdit /></Button>
                                                                            <Button color="danger" size="sm"  onClick={() => {this.deleteCategoria(data); }}><FaTrashAlt /></Button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}                                      
                                                        </tbody>
                                                    </table>
                                                </CardBody>
                                            </Card>

                                            <Toast isOpen={this.state.toast} style={{minWidth: 350, bottom: 5, position: 'fixed', right: 5}} >
                                                    <ToastHeader toggle={this.toggleToast} icon={this.state.iconToast}>{this.state.toastTitulo}</ToastHeader>
                                                <ToastBody>
                                                    {this.state.toastMensaje}
                                                </ToastBody>
                                            </Toast>
                                            
                                        </div>                   
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            }
            </LoadingOverlay>

                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle} close={closeBtn}>{this.state.edit === true ? 'Editar' : 'Registrar'}</ModalHeader>
                    <ModalBody>
                        <Label>Nombre de la tecnología</Label>
                        <Input type="text" className="form-control" id="name" name="name" value={this.state.name} onChange={this.handleChange} 
                                onKeyUp={this.handleChange}  />

                        <Alert color={this.state.colorAlert} isOpen={this.state.openAlert} toggle={this.onDismiss} style={{marginTop: 5}} >
                            {this.state.mensajeAlert}
                        </Alert>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.guardar}>Guardar</Button>
                        <Button color="danger" onClick={this.cancelar}>Cancelar</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.modalCategorias} toggle={this.toggleCategoria}>
                    <ModalHeader toggle={this.toggleCategoria} close={closeBtnCategoria}>{this.state.editCategoria === true ? 'Editar' : 'Registrar'}</ModalHeader>
                    <ModalBody>
                        <Label>Nombre de la categoría</Label>
                        <Input type="text" className="form-control" id="nombre" name="nombre" value={this.state.nombre} onChange={this.handleChange} 
                                onKeyUp={this.handleChange}  />

                        <Alert color={this.state.colorAlert} isOpen={this.state.openAlert} toggle={this.onDismiss} style={{marginTop: 5}} >
                            {this.state.mensajeAlert}
                        </Alert>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.guardarCategoria}>Guardar</Button>
                        <Button color="danger" onClick={this.cancelarCategoria}>Cancelar</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.modalAutorespuestas} toggle={this.toggleAutorespuesta} size="lg">
                    <ModalHeader toggle={this.toggleAutorespuesta} close={closeBtnAutorespuesta}>{this.state.editAutorespuestas === true ? 'Editar' : 'Registrar'}</ModalHeader>
                    <ModalBody>
                        <Label>Nombre</Label>
                        <Input type="text" className="form-control" id="nombremostrar" name="nombremostrar" value={this.state.nombremostrar} onChange={this.handleChange} 
                                onKeyUp={this.handleChange}  />
                        <Label>Texto del mensaje</Label>
                        <Input type="textarea" className="form-control" id="texto" name="texto" value={this.state.texto} onChange={this.handleChange} 
                                onKeyUp={this.handleChange}  />

                        <Alert color={this.state.colorAlert} isOpen={this.state.openAlert} toggle={this.onDismiss} style={{marginTop: 5}} >
                            {this.state.mensajeAlert}
                        </Alert>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.guardarAutorespuesta}>Guardar</Button>
                        <Button color="danger" onClick={this.cancelarAutorespuesta}>Cancelar</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default Configuracion;