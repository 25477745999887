import React, { useState, useEffect } from 'react';
import firebase from '../firebase/firebase';
import { useSelector, useDispatch } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Menu from '../components/Menu';
import { Badge, Pagination, PaginationItem, PaginationLink, Input, Button } from 'reactstrap';
import moment from 'moment';
import {Link} from "react-router-dom";
import { ExportToCsv } from 'export-to-csv';
import { sinAsteriscos } from '../utilidades/exportCsv';

const optionsExport = { 
  fieldSeparator: ',',
  quoteStrings: '',
  decimalSeparator: '.',
  filename: 'postulados',
  showLabels: true, 
  showTitle: false,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['correo']
};

const ListadoComputrabajo = () => {
  
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [postulados, setPostulados] = useState([]);
  const [filtrados, setFIltrados] = useState([]);
  const [search, setSearch] = useState('');
  const [estado, setEstado] = useState('Todos');
  const [fechas, setFechas] = useState({desde: '', hasta: ''});
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(150);
  const [busco, setBusco] = useState(false);
  const [orderBy, setOrderBy] = useState('fechapostulacion');
  const [ofertas, setOfertas] = useState([]);
  const [filterOferta, setFIlterOferta] = useState('Todos');
  const programadorestodos = useSelector((state) => state.general.programadorestodos);
  
  useEffect(() => {
    const getData = () => {
      firebase.db.collection('registradoscomputrabajo')
      .orderBy("fechapostulacion", "desc")
      .get()
      .then(data => {
          let arreglo = []
          data.forEach((doc) => {
              arreglo.push(doc.data())
          });
          setPostulados(arreglo);
          setFIltrados(arreglo);
          setLoader(false);
          setPageCount(Math.ceil(arreglo.length / pageSize))

          const lisatdoOfertas = Array.from(new Set(arreglo.map(s => s.oferta)))
            .map(oferta => {
              return arreglo.find(x => x.oferta === oferta).oferta
            })
          setOfertas(lisatdoOfertas)
      })
    } 
    getData()
  }, [])

  const logout = () => {
    firebase.logout();
  }

  const colorBadge = (estado) => {
    switch (estado) {
      case 'No contratado':
        return 'warning';
      case 'Contratado':
          return 'secondary';
      case 'No revisado':
          return 'info';
      case 'Habilitado':
          return 'success';
      case 'Penalizado':
          return 'danger';
      case 'No cumple los requisitos':
          return 'dark';
      case 'Iniciado': 
        return 'iniciado';
      default:
        return 'no-inscrito';
    }
  }

  const itemsPerPage = () =>{
    let array = []
    array = filtrados.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
    return  (currentPage * pageSize) + array.length;
  }

  const handlePageClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  const handleNextClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(currentPage + 1);
  }

  const handlePreviousClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(currentPage - 1);
  }

  const buscarEstado = (email) => {
    const filter = programadorestodos.filter(fila => fila.email === email)
    if(filter.length > 0) {
      return filter[0].estado;
    } else {
      return 'No inscrito';
    }
  }

  const handleChange = (e) => {
    setSearch(e.target.value)
  }
  const handleChangeFecha = (e) => {
    const {name, value} = e.target
    setFechas({...fechas, [name]: value})
  }

  const searchResults = () => {
    filter();
  }

  const changeFilterEstado = (e) => {
    const value = e.target.value;
    setEstado(value)
    filter(value, null);
  }

  const filter = (stateRecived, ofertaRecibida) => {
    /* filtro por estado */
    setBusco(true);
    let estadoAFiltrar = '';
    if(stateRecived){
      estadoAFiltrar = stateRecived;
    } else {
      estadoAFiltrar = estado;
    }
    let todos = postulados;
    if(estadoAFiltrar === 'Todos') {
      todos = postulados;
    } else {
      let filtered = [];
      postulados.forEach(item => {
        const state = buscarEstado(item.correo);
        if(state === estadoAFiltrar){
          filtered.push(item);
        }
      });
      todos = filtered;
    }
    /* filtro por fecha y nombre de tecnologia */
    let concatArray = [];
    let filterData = [];
    if(search === '') {
      if(fechas.desde  === '' && fechas.hasta === '') {
        filterData = todos;
      } else if(fechas.desde  === '' && fechas.hasta !== '') {
        filterData = todos.filter((item) =>
          item.fechapostulacion <= fechas.hasta
        );
      } else if(fechas.desde  !== '' && fechas.hasta === '') {
        filterData = todos.filter((item) =>
          item.fechapostulacion >= fechas.desde
        );
      } else {
        filterData = todos.filter((item) =>
          item.fechapostulacion >= fechas.desde && item.fechapostulacion <= fechas.hasta
        );
      }
    } else {
      let array = search.split(/[ ,]+/);
      console.log(array)
      array.forEach(item => {
        let filter;
        /*if(item.includes("")){
          const newArray = item.split(/['"]+/g);
          console.log(newArray[1])
          filter = todos.filter(el => {
            if(el.curriculum.toLowerCase().indexOf(newArray[1].toLowerCase()) !== -1){
              const arrayF = el.curriculum.toLowerCase().split(newArray[1]).join('-')
              console.log(arrayF)
              if(!arrayF.includes('script')){
                return el.curriculum
              }
            }
          })
        } else {
          filter = todos.filter(el => el.curriculum.toLowerCase().indexOf(item.toLowerCase()) !== -1)
        }*/
        filter = todos.filter(el => el.curriculum.toLowerCase().indexOf(item.toLowerCase()) !== -1)
        concatArray = concatArray.concat(filter);
      })
      if(fechas.desde  === '' && fechas.hasta === '') {
        filterData = concatArray;
      } else if(fechas.desde  === '' && fechas.hasta !== '') {
        filterData = concatArray.filter((item) =>
          item.fechapostulacion <= fechas.hasta
        );
      } else if(fechas.desde  !== '' && fechas.hasta === '') {
        filterData = concatArray.filter((item) =>
          item.fechapostulacion >= fechas.desde
        );
      } else {
        filterData = concatArray.filter((item) =>
          item.fechapostulacion >= fechas.desde && item.fechapostulacion <= fechas.hasta
        );
      }
    }
    todos = filterData;
    //filtar por ofertas
    let ofertaAFiltrar = '';
    if(ofertaRecibida){
      ofertaAFiltrar = ofertaRecibida;
    } else {
      ofertaAFiltrar = filterOferta;
    }
    let filteredOfertas = [];
    if(ofertaAFiltrar === 'Todos') {
      filteredOfertas = todos;
    } else {
      filteredOfertas = todos.filter(row => row.oferta === ofertaAFiltrar)
    }
    todos = filteredOfertas;
    setFIltrados(todos);
    setPageCount(Math.ceil(todos.length / pageSize))
    setCurrentPage(0)
  }

  const exportarCSV = () => {
    let formatData = [];
    filtrados.forEach(item => {
      const estado = buscarEstado(item.correo);
      if(sinAsteriscos(item.correo)){
        if(estado === 'No inscrito' || estado === 'Iniciado' || estado === 'Penalizado' ){
          formatData.push({correo: item.correo});
        }
      }
    });
    const csvExporter = new ExportToCsv(optionsExport);
    csvExporter.generateCsv(formatData);
  }

  const changeOrder = (e) => {
    const value = e.target.value;
    setOrderBy(value);
    let ordenado = [];
    let newList = filtrados;

    if(value === 'fechapostulacion'){
      ordenado = newList.sort(sortByDate);
    } else {
      ordenado = newList.sort((prev, next) => {
          if( prev[value].toLowerCase()  > next[value].toLowerCase()){
              return 1;
          }
          if( prev[value].toLowerCase() < next[value].toLowerCase()){
              return -1;
          }
          return 0;
      });
    }
    setFIltrados(ordenado);
  }

  const changeFilterOferta = e => {
    const value = e.target.value;
    setFIlterOferta(value);
    setTimeout(() => {  filter(null, value) }, 500);
  }

  const sortByDate = (a,b) =>{  
    var dateA = new Date(a.fechapostulacion).getTime();
    var dateB = new Date(b.fechapostulacion).getTime();
    return dateA < dateB ? 1 : -1;  
  }

  return ( 
    <div>
      <Menu link='computrabajo' admin={true} logout={logout} ></Menu>
      {loader &&
        <div id="loader">
          <div className="lds-heart"><div></div></div>
        </div>
      }
        <LoadingOverlay
          active={loading}
          spinner
          text='Cargando...'
        >

          {!loader &&
            <main className="container d-flex align-items-center justify-content-center topMain mb-3">
              <div className="row h-100 align-items-center justify-content-center w-100">
                <div className="row p-0 w-100">
                  <div className="col-12 p-0 list-postul rounded border border-secondary shadow-lg w-100">                               
                    <div className="list-postul-header text-center p-3">
                      <h2>Listados de Postulados</h2>
                    </div>
                    <div className="list-postul-content py-3 px-2 px-md-3 w-100">

                      <div className="row mb-2 justify-content-between">
                        <div className="col-12 col-md-3 align-items-center mb-1">
                          <span>Ordenar</span><br/>
                          <Input type="select" name="orderBy" id="orderBy" value={orderBy} className="w-100" onChange={changeOrder}  >
                            <option value="correo">Correo</option>
                            <option value="fechapostulacion">Fecha de Postulación</option>
                            <option value="nombres">Nombres</option>
                          </Input>
                        </div>
                      </div>

                      <div className="row mb-5 justify-content-between">
                        <div className="col align-items-center mb-1">
                          <span>Estado</span>
                          <Input type="select" name="estado" id="estado" value={estado} className="w-100"
                              onChange={changeFilterEstado}
                          >
                              <option value="Todos">Todos</option>
                              <option value="Habilitado">Habilitado</option>
                              <option value="Iniciado">Iniciado</option>
                              <option value="No contratado">No contratado</option>
                              <option value="No cumple los requisitos">No cumple los requisitos</option>
                              <option value="Contratado">Contratado</option>
                              <option value="No inscrito">No inscrito</option>
                              <option value="No revisado">No revisado</option>
                              <option value="Penalizado">Penalizado</option>
                          </Input>
                        </div>
                        <div className="col align-items-center mb-1">
                          <span>Desde</span>
                          <Input className="w-100 rounded" type="date" name="desde" id="desde" value={fechas.desde}  
                            onChange={handleChangeFecha} />
                        </div>
                        <div className="col align-items-center mb-1">
                          <span>Hasta</span>
                          <Input className="w-100 rounded" type="date" name="hasta" id="hasta" value={fechas.hasta}  
                            onChange={handleChangeFecha} />
                        </div>
                        <div className="col align-items-center mb-1">
                          <span>Oferta</span>
                          <Input type="select" name="estado" id="estado" value={filterOferta} className="w-100"
                              onChange={changeFilterOferta}
                          >
                              <option value="Todos" >Todos</option>
                              {ofertas.map((oferta, i) => (
                                <option value={oferta} key={i}>{oferta}</option>
                              ))}
                          </Input>
                        </div>
                        <div className="col-12 col-md-3 div-search mb-1" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }} >
                          <Input className="w-100 rounded" type="search" name="search" id="search" value={search} placeholder="Nombre de tecnología" aria-label="Search" 
                            onChange={handleChange} style={{paddingRight: '40px'}} />
                          <div className="input-group-append">
                            <Button className="search position-absolute btn btn-info" onClick={searchResults} >
                              <i className="zmdi zmdi-search" aria-hidden="true"></i>
                            </Button>
                          </div>
                        </div>
                      </div>
                      
                      {busco &&
                        <div className="row mb-2">
                          <div className="col-12 text-center">
                            <Button className="btn btn-info" onClick={e =>exportarCSV()} >
                              Exportar a CSV
                            </Button>
                          </div>
                        </div>
                      }

                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col" className="d-table-cell">Nombres</th>
                            <th scope="col" className="d-table-cell">Correo</th>
                            <th scope="col" className="d-none d-lg-table-cell">Teléfono</th>
                            <th scope="col" className="d-none d-sm-table-cell">Fecha</th>
                            <th scope="col" className="text-center d-xs-table-cell">Estado</th>
                            <th scope="col" className="text-center">Ver</th> 
                          </tr>
                        </thead>
                        <tbody>
                            {filtrados
                                .slice (currentPage * pageSize, (currentPage + 1) * pageSize) 
                                .map ((data, key) => { 
                                return ( 
                                  <tr key={key}>
                                    <td className="d-table-cell">{data.nombres}</td>
                                    <td className="d-table-cell">{data.correo}</td>
                                    <td className="d-none d-sm-table-cell">{data.numerotelefono}</td>
                                    <td className="d-none d-sm-table-cell">{moment(data.fechapostulacion).format('DD-MM-YYYY')}</td>
                                    <td className="text-center d-xs-table-cell">
                                      <Badge color={colorBadge(buscarEstado(data.correo))}> {buscarEstado(data.correo)}</Badge>
                                    </td>
                                    <td className="text-center"> 
                                      <Link className="btn btn-info btn-sm" to={{ 
                                          pathname: `/computrabajo/${data.correo}`,
                                      }} target={"_blank"} >
                                        Ver  
                                      </Link>
                                    </td>
                                  </tr>
                                );
                            })}
                            
                        </tbody>
                      </table>
                      <div style={{display: 'flex', justifyContent: 'space-between'}} >
                        <span>Mostrando<b> {(currentPage * pageSize) + 1}&nbsp;</b> 
                            a <b>{itemsPerPage()}&nbsp;</b> 
                            de <b>{filtrados.length}</b>
                        </span>
                        <Pagination size="sm" aria-label="Page navigation example">
                            {[...Array(pageCount)].map((page, i) => (
                                <PaginationItem active={i === currentPage} key={i}>
                                    <PaginationLink onClick={e => handlePageClick(e, i)} href="#">
                                        {i + 1}
                                    </PaginationLink>
                                </PaginationItem>
                            ))}
                        </Pagination>
                        
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </main>

          }
        </LoadingOverlay>
    </div>
  );
}
 
export default ListadoComputrabajo;