const initialState = {
    programadorestodos: []
}


const generalReducer = (state = initialState, action) => {
    switch (action.type){
        case 'PROGRAMADORES_TODOS':
            return {
                ...state,
                programadorestodos: action.payload
            }
        default :
            return initialState;
    }
}

export default generalReducer