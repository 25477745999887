import React, {Component} from 'react';

import firebase from '../firebase/firebase';
import { Button, Nav, NavItem, NavLink, TabContent, TabPane, Tooltip } from 'reactstrap';
import classnames from 'classnames';

import TabDatosGenerales from '../components/TabDatosGenerales';
import TabTerminos from '../components/TabTerminos';
import TabDatosTecnicos from '../components/TabDatosTecnicos';
import TabFinalizado from '../components/TabFinalizado';
import { FaSignOutAlt } from 'react-icons/fa';


class Tabprincipal extends Component {
    constructor(props){
        super(props);
        this.logout =  this.logout.bind(this);
        this.toggle =  this.toggle.bind(this);
        this.toggleTooltip1 =  this.toggleTooltip1.bind(this);
        this.toggleTooltip2 =  this.toggleTooltip2.bind(this);
        this.toggleTooltip3 =  this.toggleTooltip3.bind(this);
        this.toggleTooltip4 =  this.toggleTooltip4.bind(this);
        this.changeTerms = this.changeTerms.bind(this);
        this.changeStep = this.changeStep.bind(this);

        this.state = {
            activeTab: this.props.profile.ultimaPestana,
            acceptTerms: false,
            perfil: this.props.profile,
            tooltip1Open: false,
            tooltip2Open: false,
            tooltip3Open: false,
            tooltip4Open: false,
        }
    }

    componentDidMount(){

    }

    changeTerms({target}){
        if (target.checked){
           this.setState({acceptTerms: true})
        } else {
            this.setState({acceptTerms: false})
        }
    }

    changeStep(tab){ 
        firebase.db.collection("programadores").doc(`${firebase.auth.currentUser.uid}`)
        .update({ultimaPestana: tab})
        .then(querySnapshot => {
            this.toggle(tab);
        });
    }

    async logout() {
        if(this.state.perfil.registroCompletado === true){
            firebase.db.collection('programadores').doc(`${firebase.auth.currentUser.uid}`).update({primerasesioncerrada: true })
        }
        await firebase.logout();
        localStorage.removeItem('redux');
        this.props.history.replace('/');
    }

    toggle = (tab) => {
        if(this.state.activeTab !== tab) {
            this.changeStep(tab);
            this.setState({
                activeTab: tab
            })
        }
    }

    toggleTooltip1(event){
        this.setState({tooltip1Open: !this.state.tooltip1Open})
    }

    toggleTooltip2(event){
        this.setState({tooltip2Open: !this.state.tooltip2Open})
    }

    toggleTooltip3(event){
        this.setState({tooltip3Open: !this.state.tooltip3Open})
    }

    toggleTooltip4(event){
        this.setState({tooltip4Open: !this.state.tooltip4Open})
    }

    clickStep = (tab) => {
        this.toggle(tab)
    }

    render(){
        return(

            <main className="container d-flex align-items-center justify-content-center my-lg-5">
                <div className="row h-100 align-items-center justify-content-center">
                    <div>
                        <form id="form" className="form-register rounded border border-secondary shadow-lg" action="#" >
                            
                        <div role="tabpanel" className="tabpanel">

                            <Nav tabs id="tabPostulaciones">
                                <NavItem>
                                    <NavLink id="condiciones_lab-tab"
                                        className={classnames({ active: this.state.activeTab === '1' }, {disabled: this.state.activeTab !== '1'},  'd-flex align-items-center justify-content-lg-center')}
                                        onClick={() => { this.toggle('1'); }}
                                    >
                                        <span className="step-icon">01</span>Condiciones Laborales
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink id="datos_gen-tab"
                                        className={classnames({ active: this.state.activeTab === '2' }, {disabled: this.state.activeTab !== '2'}, 'd-flex align-items-center justify-content-lg-center')}
                                        onClick={() => { this.toggle('2'); }}
                                    >
                                        <span className="step-icon">02</span>Datos Generales
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink id="datos_tec-tab"
                                        className={classnames({ active: this.state.activeTab === '3' }, {disabled: this.state.activeTab !== '3'}, 'd-flex align-items-center justify-content-lg-center')}
                                        onClick={() => { this.toggle('3'); }}
                                    >
                                        <span className="step-icon">03</span>Datos Técnicos
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink id="postulacion_comp-tab"
                                        className={classnames({ active: this.state.activeTab === '4' }, {disabled: this.state.activeTab !== '4'}, 'd-flex align-items-center justify-content-lg-center')}
                                        onClick={() => { this.toggle('4'); }}
                                    >
                                        <span className="step-icon">04</span>Postulación Completa
                                    </NavLink>
                                </NavItem>
                            </Nav>


                            <TabContent activeTab={this.state.activeTab} id="tabPostulacionesContent">
                                <TabPane tabId="1" className={classnames({ show: this.state.activeTab === '1' }, 'fade p-3 p-lg-5')} >
                                    <FaSignOutAlt id="tooltip1cerrar" className="boton-salir" onClick={this.props.salir}/>
                                    <Tooltip placement="bottom" isOpen={this.state.tooltip1Open} target="tooltip1cerrar" toggle={this.toggleTooltip1}>
                                        Cerrar sesión
                                    </Tooltip>
                                    <TabTerminos  />

                                    <div className="form-group row my-0 mt-5">
                                        <div className="col-md-6 d-flex align-items-center">
                                            <input type="checkbox" name="terminos" id="terminos" className="mr-3" 
                                            checked={this.state.acceptTerms} onChange={this.changeTerms} /> 
                                            Acepto las condiciones de laborales.
                                        </div>
                                        <div className="col-md-6 d-flex justify-content-end">
                                            <ul className="nav nav-tabs d-flex align-items-center justify-content-md-between" role="tablist">
                                                <li className="nav-item">
                                                    
                                                    <Button color="success" id="btn_prev_generales" className="btn btn-success rounded-circle btn-next" disabled={!this.state.acceptTerms}
                                                        onClick={() => {this.changeStep('2'); }}>
                                                            <i className="zmdi zmdi-arrow-right"></i>
                                                    </Button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </TabPane>
                                <TabPane tabId="2"  className={classnames({ show: this.state.activeTab === '2' }, 'fade p-3 p-lg-5')}  >
                                    <FaSignOutAlt id="tooltip2cerrar"  className="boton-salir" onClick={this.props.salir} />
                                    <Tooltip placement="bottom" isOpen={this.state.tooltip2Open} target="tooltip2cerrar" toggle={this.toggleTooltip2}>
                                        Cerrar sesión
                                    </Tooltip>
                                    <TabDatosGenerales  step={this.clickStep}  userprofile={this.state.profile} />
                                    
                                </TabPane>
                                <TabPane tabId="3" className={classnames({ show: this.state.activeTab === '3' }, 'fade p-3 p-lg-5')} >
                                    <FaSignOutAlt  id="tooltip3cerrar"  className="boton-salir" onClick={this.props.salir} />
                                    <Tooltip placement="bottom" isOpen={this.state.tooltip3Open} target="tooltip3cerrar" toggle={this.toggleTooltip3}>
                                        Cerrar sesión
                                    </Tooltip>
                                    <TabDatosTecnicos stepTecnicos={this.clickStep}  userprofile={this.state.profile}  />
                                    
                                </TabPane>
                                <TabPane tabId="4" className={classnames({ show: this.state.activeTab === '4' }, 'fade p-3 p-lg-5')} >
                                    <TabFinalizado stepFinal={this.clickStep}  userprofile={this.state.profile}  salirse={this.props.salir}  />                                                
                                </TabPane>
                            </TabContent>

                            </div>
                                
                        </form>
                    </div>
                </div>
            </main>

        );
    }
}

export default Tabprincipal;