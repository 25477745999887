import React from "react";

function TabTerminos() {
  return (
    <div>
      <div className="wizard-header">
        <h3 className="heading">Condiciones Laborales</h3>
        <p>
          Si desea trabajar con nosotros por favor lea atentamente y acepte las
          siguientes condiciones de trabajo.
        </p>
      </div>
      <div className="wizard-body">
        <ul className="list-unstyled">
          <li>
            <b>¿Quienes somos?</b>: Hispanos Soluciones C.A. es una empresa de
            software, diseño y marketing venezolana con clientes en diversos
            países, posee sede oficial en la ciudad de Maturín y cuenta con
            aproximadamente 51 profesionales activos (agosto 2022) distribuidos
            en toda Venezuela, es dirigida por los socios y hermanos Jonathan
            Araul y Adelys Araul, con el apoyo de Candy Herrera como jefa de
            contrataciones y Nairelys Salazar como supervisora de rendimiento.
            Hispanos Soluciones C.A. ha generado trabajo para más de 400
            profesionales en 8 años de trayectoria.
          </li>
          <li>
            <b>Forma de trabajo</b>: Se trabaja en la modalidad de teletrabajo,
            lo que implica un cumplimiento de horario lo que es opuesto al
            trabajo freelance, únicamente laboramos a TIEMPO COMPLETO, si usted
            no dispone del tiempo necesario para cumplir jornadas de 8h diarias
            de lunes a viernes por favor desista de trabajar con nosotros o
            post&uacute;lese cuando si cumpla con las condiciones, NO BUSCAMOS
            ESPECIALISTAS POR HORAS si no a tiempo completo.
          </li>
          <li>
            <b>Horarios de trabajo</b>: La hora de inicio puede variar entre las
            7AM a las 11AM dependiendo de la ubicaci&oacute;n del cliente, sin
            embargo lo m&aacute;s com&uacute;n es iniciar a las 9AM, con una
            hora para el almuerzo y 8h diarias de jornada de lunes a viernes,
            firmamos acuerdos comerciales de 8h diarias y 40h semanales con
            nuestros clientes por lo cual todo trabajo adicional a eso se paga
            como hora extra de acuerdo a la legislación venezolana.
          </li>
          <li>
            <b>Días feriados</b>: Solemos trabajar todos los días feriados
            venezolanos que se den entre lunes y viernes, salvo los días
            24,25,31 de diciembre y 1ero de enero. Los días feriados que sean
            trabajados son recompensados como lo establece la ley del trabajo
            vigente.
          </li>
          <li>
            <b>Fallos eléctricos y problemas de conexi&oacute;n</b>: Cuando se
            presentan este tipo de dificultades los especialistas deben
            recuperar sus horas en horario nocturno, pero siempre se debe
            trabajar 8h diarias, de lunes a viernes sin excepci&oacute;n.
          </li>
          <li>
            <b>Formas de pago</b>: Pagamos en d&oacute;lares (USD) a todos los
            miembros, por ende su aspiraci&oacute;n salarial mensual
            deber&aacute; ser en esta moneda, se efectúan pagos con periodicidad
            quincenal, el 04 y el 19 de cada mes, a trav&eacute;s de la
            plataforma{" "}
            <a
              href="https://www.binance.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Binance.com
            </a>{" "}
            donde es posible vender las divisas en cuesti&oacute;n de minutos,
            si usted no conoce esta plataforma{" "}
            <a
              href="https://www.youtube.com/watch?v=5yNbLGCg3rA"
              target="_blank"
              rel="noopener noreferrer"
            >
              haga clic aqu&iacute;
            </a>{" "}
            para ver un tutorial, tambi&eacute;n nos puede suministrar un
            c&oacute;digo de recepci&oacute;n de bitcoin en caso de que desee
            almacenar sus fondos en otra plataforma, en caso de no estar de
            acuerdo en usar estos métodos de pago podría solicitar el pago al
            cambio en alguna cuenta bancaria o pago m&oacute;vil, sin embargo le
            solicitamos a todos los miembros por lo menos ver el tutorial del
            uso de binance.
          </li>
          <li>
            <b>Conocimientos mínimos para programadores</b>: A los
            desarrolladores de software se les exige dominar GIT como control de
            versiones y SCRUM como metodología de trabajo, si usted desea
            aplicar en esta área y no los conoce al 100%{" "}
            <a
              href="https://drive.google.com/drive/folders/16my0DBWODHKSmuBR2fW5NqEiBLEGrEk3?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              haga clic aqu&iacute;
            </a>{" "}
            para ver un tutorial.
          </li>
          <li>
            <b>Seguimiento</b>: Utilizamos{" "}
            <a
              href="https://hubstaff.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Hubstaff.com
            </a>{" "}
            un programa ligero que se instala en Windows, Linux o macOS y toma
            capturas de pantalla de forma aleatoria para verificar que el
            especialista este trabajando durante sus 8h diarias de forma
            exclusiva con nosotros, esta aplicaci&oacute;n se puede poner en
            pausa para realizar cualquier actividad personal, se debe cerrar en
            horario de almuerzo y al finalizar la jornada laboral, estas
            capturas generan informes que son visualizados por nuestros
            clientes. Adem&aacute;s cada desarrollador debe vaciar un reporte
            diario en hispanossoluciones.com y subir por lo mínimo un commit
            diario, en donde debe justificar sus 8h diarias. Nos preocupamos por
            mantener satisfechos a nuestros clientes por eso exigimos pruebas de
            trabajo a todos nuestros miembros sin excepci&oacute;n. El
            incumplimiento de este reglamento acarrea penalizaciones en el
            salario.
          </li>
          <li>
            <b>¿Como ser contratado?</b>: Para tener &eacute;xito; en este
            proceso de postulaci&oacute;n usted deber&aacute; ser extremadamente
            detallista a la hora de describir sus habilidades y experiencias
            previas. Considere que lo que ingrese en este formulario ser&aacute;
            comparado con los registros de otros profesionales, usted no debe
            dejar de lado ninguna de sus habilidades o experiencias, se le
            recomienda ingresar links o URLs a sitios webs, aplicaciones,
            repositorios o portafolios p&uacute;blicos, mientras m&aacute;s sea
            la evidencia de su experiencia m&aacute;s posibilidades
            tendr&aacute; de ser contactado r&aacute;pidamente, adem&aacute;s
            deber&aacute; ingresar un n&uacute;mero telef&oacute;nico con
            whatsapp v&aacute;lido y por &uacute;ltimo su aspiraci&oacute;n
            salarial deber&aacute; coincidir con nuestros rangos salariales,
            estos montos no son p&uacute;blicos, con el objetivo de tener
            seguridad de que su aspiraci&oacute;n hace match con nuestras
            posibilidades econ&oacute;micas. Luego de que sea evaluado recibirá
            una respuesta en su correo electrónico indicando si será tomado en
            cuenta para trabajar en algún proyecto con nuestros clientes y en
            caso contrario se le explicará la razón por la cual no fue
            considerado, estas razones podrían ser falta de conocimientos
            técnicos, aspiración salarial por encima de nuestras posibilidades o
            ambas.
          </li>
          <li>
            <b>Sinceridad</b>: Se le agradece llenar la información con
            honestidad, si consideramos que le falta conocimientos técnicos
            podría recibir cursos o tutoriales para mejorar su nivel técnico, de
            igual modo poseemos un gran número de clientes por lo cual las
            probabilidades de ser contactado para algún proyecto son altas, una
            vez un profesional muestra que puede trabajar de manera efectiva
            procuramos proporcionarles proyectos de forma permanente, tenemos
            especialistas con 5 años trabajando con nosotros de forma continua.
            Valoramos a quienes se esmeran en hacer un buen trabajo y que
            procuran llevar una buena comunicación.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default TabTerminos;
