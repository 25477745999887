import React, { useEffect} from 'react';
import firebase from '../firebase/firebase';
import { ExportToCsv } from 'export-to-csv';
import {exportCSVFile} from '../utilidades/exportCsv';

const optionsExport = { 
    fieldSeparator: ',',
    quoteStrings: '',
    decimalSeparator: '.',
    filename: 'jorge',
    showLabels: true, 
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: false,
    // headers: ['correo']
};

const Jorge = () => {

    useEffect(() => {
        loadProgrammers();
    }, [])

    const loadProgrammers = async () => {
        try {
            const querySnapshot = await firebase.db.collection("programadores").where("admin","==", false).get()
            let objetoProgramador;
            let arrayProgramador = []
            querySnapshot.forEach((doc) => {
                objetoProgramador = doc.data();
                objetoProgramador.id = doc.id
                arrayProgramador.push(objetoProgramador)
            });
            console.log(arrayProgramador)
            const csvExporter = new ExportToCsv(optionsExport);
            csvExporter.generateCsv(arrayProgramador);
        } catch (error) {
            console.log(error)
        } 
    }
     
    return ( 
        <h1>Jorge</h1>
    );
}
 
export default Jorge;