import React, {Component} from 'react';
import firebase from '../firebase/firebase';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import TabPrincipal from '../components/TabPrincipal';
import  ListadoProgramadores from '../pagesadmin/ListadoProgramadores';

import { setPerfil } from '../actions';
import { connect }  from  'react-redux';


class Home extends Component {
    constructor(props){
        super(props);

        this.logout = this.logout.bind(this);
        this.toggle  = this.toggle.bind(this);
    
        this.state = {
            perfil: {
                activo: null,
                admin: null,
                anosExperiencia: 0,
                apellidos: '',
                aspiracion: '',
                cedula: '',
                ciudad: '',
                cursos: [],
                direccion: '',
                email: '',
                experiencias: [],
                fecha: '',
                links: [],
                nivelIngles: '',
                nombres: '',
                numWhatsapp: '',
                registroCompletado: null,
                rol: '',
                tecnologias: [],
                tipoPerfil: '',
                tipoPerfiles: [],
                titulo: '',
                uid: '',
                ultimaPestana: '',
                universidad: '',
                costoMes: 0,
                datosPersonales: '',
                mensajes: [],
                historico: [],
                chat: null,
                updatedat:  '',
                primerasesioncerrada: false,
                idiomas: []
            },
            modal: false
        }
    }

    
    componentDidMount(){
        if(firebase.auth.currentUser){ // si esta logueado en firebase
            if(this.props.perfil){
                const data = this.props.perfil;
                this.setState({perfil: data, activeTab: data.ultimaPestana});
            } else {
                firebase.db.collection("programadores").doc(`${firebase.auth.currentUser.uid}`) //optener los datos de perfil del usuario logueado
                .get()
                .then(doc => {
                    const data = doc.data();
                    if (typeof data === 'undefined') {
                        this.props.setPerfil(null);
                        this.setState({ modal: true });
                    } else {
                        this.props.setPerfil(data);
                        this.setState({perfil: data, activeTab: data.ultimaPestana});
                    }
                });
            }
            
        } else { // si no esta logueado en firebase
            this.props.history.replace('/login');
        } 
    }

    logout() {
        if(this.state.perfil.registroCompletado === true){
            firebase.db.collection('programadores').doc(`${firebase.auth.currentUser.uid}`).update({primerasesioncerrada: true })
        }
        firebase.logout();
        localStorage.removeItem('redux');
        this.props.history.replace('/login');
    }
    
    toggle(){
        this.setState({modal: !this.state.modal});
        this.props.history.replace('/login');
    }

    render() {
        // const closeBtn = <button className="close" onClick={this.toggle}>&times;</button>;
        if(!firebase.getCurrentUser()){
            this.props.history.replace('/login');
        }
        let content;
        if (this.state.perfil.admin === true) {
            content = <ListadoProgramadores perfil={this.state.perfil} />;
        } else if (this.state.perfil.admin === false)  {
            content = <TabPrincipal profile={this.state.perfil} salir={this.logout}  />
        }else {
            content = <div id="loader"><div className="lds-heart"><div></div></div></div>
        }
        return (
            <div>
                {content}
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Advertencia</ModalHeader>
                    <ModalBody>
                        Este usuario no pertenece a este sistema, por esta razon sera enviado nuevamente a la pantalla de Inicio de Sesión.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="info" onClick={this.toggle}>Aceptar</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        perfil: state.perfil,
        //token: state.token
    }
}

const mapDispatchToProps = {
    setPerfil,
}

export default connect(mapStateToProps, mapDispatchToProps) (Home);
// export default Home;