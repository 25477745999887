export const perfiles = [
    'FrontEnd',
    'BackEnd',
    'Full Stack',
    'Diseñador UX/UI',
    'Mobile Developer',
    'QA',
    'DBA',
    'Marketing Digital',
    'Arquitecto de Software',
    'Scrum Master',
    'Project Manager',
    'Analista de sistema',
    'Community Manager',
    'Redactor Creativo',
    'Lead Tech',
    'Soporte Técnico',
    'Cybersecurity', 
    'Diseñador Gráfico',
    'Diseñador Audiovisual',
    'Analista Desarrollador', 
    'Data Analitycs',
    'Otro',
]