import React, {useState}  from 'react';
import firebase from '../../firebase/firebase';
import Menu from '../Menu';
import { Input, Button, Label, Alert, Form} from 'reactstrap';
import moment from 'moment';
import CSVReader from "react-csv-reader";
import { FaFileImport} from 'react-icons/fa';
import LoadingOverlay from 'react-loading-overlay';
import config from '../../config/index';
import axios from 'axios';

const toParseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
};

const Programadores = (props) => {

    const [values, setValues] = useState({email: '', password: '', repeatPassword: ''});
    const [error, setError] = useState('');
    const [alerttype, setAlerttype,] = useState('danger');
    const [valid, setValid] = useState(false);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageAlert, setMessage] = useState('');
    const [visible2, setVisible2] = useState(false);

    const onDismiss = () => setVisible(false);
    const onDismiss2 = () => setVisible2(false);

    function logout() {
        firebase.logout();
        localStorage.removeItem('redux');
    }

    const handleInputChange = e => {
        const {name, value} = e.target
        setValues({...values, [name]: value})
    }

    const register = async (e) => {
        e.preventDefault();
        if (values.email === '' || values.password === ''  || values.repeatPassword === '' ) {
            setAlerttype('danger');
            setError('*Por favor introduzca todos los campos.');
            setVisible(true);
        } else if(!valid) {
            setAlerttype('danger');
            setError('*Contraseñas no coinciden.');
            setVisible(true);    
        } else {
            if(values.password.length < 6) {
                setAlerttype('danger');
                setError('*La contraseña debe tener una longitud minima de 6 caracteres.');
                setVisible(true);
            } else {
                const email = values.email.trim();
                const password = values.password.trim();
                try {
                    /*let response = await firebase.auth.createUserWithEmailAndPassword(email, password);
                    let {user} =  response;*/
                    let response = await createUser(email, password);
                    if(response.data.success === true){
                        let {user} =  response.data;
                        saveDatos(user);
                    } else {
                        buildErrorMesagge(response.data.error)
                    }
                } catch (err){
                    //buildErrorMesagge(err)
                    setError('*Error de servidor, intenta nuevamente') 
                    setVisible(true);
                }
            }
        }
    }

    const buildErrorMesagge = (err) => {
        setAlerttype('danger');
        switch (err.code) {
            case 'auth/email-already-in-use':
                setError('*Email ya se encuentra registrado en nuestra plataforma');
                break;
            case 'auth/invalid-email':
                setError('*Formato de email invalido');
                break;
            case 'auth/operation-not-allowed':
                setError('*App no tiene habilitada la autenticacion mediante correo y contraseña.');
                break;
            case 'auth/weak-password':
                setError('*La contraseña no es lo suficientemente segura.');
                break;
            case 'auth/email-already-exists':
                setError('*Email ya se encuentra registrado en nuestra plataforma');
                break;
            default: 
                break;
        }
        setVisible(true);
    }

    const createUser = async (email, password) => {
        const data = {email, password};
        return await axios.post(`${config.notificationUrl}/createUser`,  data );    
    }

    const validatePassword = (e) =>{
        setError('');
        setVisible(false);
        if (values.password === values.repeatPassword) {
            setValid(true);
        } else {
            setValid(false);
        }
    }

    const saveDatos = async (user) => {
        let datosGenerales = {
            activo: false,
            admin: false,
            registroCompletado: false,
            rol: 'programador',
            ultimaPestana: '1',
            anosExperiencia: 0,
            apellidos: '',
            aspiracion: 0,
            cedula: '',
            ciudad: '',
            direccion: '',
            email: user.email,
            estado: 'Iniciado',
            fecha:  moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            nivelIngles: '',
            nombres: '',
            numWhatsapp: '',
            tipoPerfil: '',
            tipoPerfiles: [],
            titulo: '',
            uid: user.uid,
            universidad: '',
            tecnologias: [],
            experiencias: [],
            links: [],
            cursos: [],
            costoMes: 0,
            disponibilidad: '',
            datosPersonales: '',
            mensajes: [],
            historico: [],
            imagenes: [],
            chat: {
                actualizado: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                cerrado: 0,
                id: `${user.uid}`,
                mensajes: [],
                nombres: '',
                apellidos: '',
                email: '',
                numWhatsapp: '',
                cedula: '',
                anosExperiencia: '',
                nivelIngles: '',
                titulo: '',
                universidad: '',
                ciudad: '',
                direccion: '',
                aspiracion: '',
                fecha: '',
                tipoPerfiles: [],
                idiomas: []
            },
            updatedat:  moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            primerasesioncerrada: false,
            datosCompletos: false,
            idiomas: []
        }
        await firebase.db.collection('programadores').doc(`${user.uid}`).set(datosGenerales)
        .then(res =>{
            setValues({
                ...values,
                email: '',
                password: '',
                repeatPassword: ''
            });
            setAlerttype('success');
            setError('*Programador registrado exitosamente.');
            setVisible(true);
        }).catch(err => console.log(err));
        
    }

    const handleForce = async (data, fileInfo) =>{
        setLoading(true);
        let contrasena = '';
        let errorsCount = 0;
        for (let index = 0; index < data.length; index++) {
            const value = data[index];
            if(typeof value.email === 'undefined' || typeof value.password === 'undefined') {
                setAlerttype('danger');
                setMessage('Alguna columna del archivo no tiene el formato correcto, deben tener el siguiente formato: nombres,email,password.');
                setVisible2(true);
                setLoading(false);
                return;
            } else {
                if(typeof value.password === 'number'){
                    contrasena = value.password.toString();
                } else {
                    contrasena  = value.password;
                }
                const username = value.email.trim();
                const password = contrasena.trim();
                try {
                    /*let response = await firebase.auth.createUserWithEmailAndPassword(username, password); //creo un usuario
                    let { user } = response; // capturo el usuario creado*/
                    let response = await createUser(username, password);
                    if(response.data.success === true){
                        let {user} =  response.data;
                        let datosGenerales = {
                            activo: false,
                            admin: false,
                            registroCompletado: false,
                            rol: 'programador',
                            ultimaPestana: '1',
                            anosExperiencia: 0,
                            apellidos: '',
                            aspiracion: 0,
                            cedula: '',
                            ciudad: '',
                            direccion: '',
                            email: user.email,
                            estado: 'Iniciado',
                            fecha:  moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                            nivelIngles: '',
                            idiomas: [],
                            nombres: '',
                            numWhatsapp: '',
                            tipoPerfil: '',
                            tipoPerfiles: [],
                            titulo: '',
                            uid: user.uid,
                            universidad: '',
                            tecnologias: [],
                            experiencias: [],
                            links: [],
                            cursos: [],
                            costoMes: 0,
                            disponibilidad: '',
                            datosPersonales: '',
                            mensajes: [],
                            historico: [],
                            chat: {
                                actualizado: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                                cerrado: 0,
                                id: `${user.uid}`,
                                mensajes: [],
                                nombres: '',
                                apellidos: '',
                                email: '',
                                numWhatsapp: '',
                                cedula: '',
                                anosExperiencia: '',
                                nivelIngles: '',
                                idiomas: [],
                                titulo: '',
                                universidad: '',
                                ciudad: '',
                                direccion: '',
                                aspiracion: '',
                                fecha: '',
                                tipoPerfiles: []
                            },
                            updatedat:  moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                            primerasesioncerrada: false,
                            datosCompletos: false
                        }
                        await firebase.db.collection('programadores').doc(`${user.uid}`).set(datosGenerales)
                    } else {
                        errorsCount ++;
                    }
                } catch (err){
                    errorsCount ++;
                    setAlerttype('danger');
                }
            }
        }
        setLoading(false);
        if(errorsCount === 0){
            setAlerttype('success');
            setMessage('Datos cagados exitosamente');
            setVisible2(true);
        } else {
            setAlerttype('warning');
            setMessage(`${errorsCount} registros no se cargaron correctamente`);
            setVisible2(true);
        }
    }

    const handleError = (e) =>{
        console.log(e);
    }

    const readCSV = (e) =>{
        const fileinput = document.getElementById('fileempresas');
        fileinput.click();
    }

    return(
        <div>
            <Menu link='programadores' logout={logout} ></Menu>
            <LoadingOverlay
                active={loading}
                spinner
                text='Cargando...'
            >
            <main className="container d-flex align-items-center justify-content-center topMain mb-3">
                <div className="row h-100 align-items-center justify-content-center  w-100">
                    <div className="row p-0 w-100">
                        <div className="col-12 p-0 list-postul rounded border border-secondary shadow-lg">
                            <div className="list-postul-header text-center p-3">
                                <h2>Programadores</h2>
                            </div>
                            <div className="list-postul-content py-3 px-2 px-md-5 text-left">

                                <div className="row">
                                    <div className="col"></div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <Form onSubmit={register} >
                                            <div className="row w-100">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <Label for="email">Correo electrónico:</Label>
                                                        <Input id="email" name="email" className="form-control" type="email" 
                                                            placeholder="Correo@ejemplo.com" 
                                                            onChange={handleInputChange}
                                                            value={values.email}
                                                            onKeyUp={validatePassword}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <Label for="password">Contraseña:</Label>
                                                        <Input id="password" name="password" className="form-control" type="password" 
                                                            placeholder="Contraseña" 
                                                            onChange={handleInputChange}
                                                            value={values.password} 
                                                            onKeyUp={validatePassword}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <Label for="repeatPassword">Repetir contraseña:</Label>
                                                        <Input id="repeatPassword" name="repeatPassword" className="form-control" type="password" 
                                                            placeholder="Repita la contraseña" 
                                                            onChange={handleInputChange}
                                                            value={values.repeatPassword}
                                                            onKeyUp={validatePassword}
                                                         />
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <Alert color={alerttype}  isOpen={visible} toggle={onDismiss} >
                                                            {error}
                                                        </Alert>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-group text-center">
                                                        <Button color="success">Enviar</Button>
                                                    </div>
                                                </div>

                                            </div>
                                        </Form>
                                        <div className="row w-100">
                                            <div className="col-12">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="row w-100">
                                            <div className="col-12" style={{ textAlign: 'center' }}>
                                                <Button id="importarTecnologia" color="success" size="sm" onClick={readCSV}>
                                                    <FaFileImport /> Importar CSV
                                                </Button>
                                                <CSVReader
                                                    inputStyle={{display: 'none'}}
                                                    inputId="fileempresas"
                                                    cssClass="react-csv-input"
                                                    label=""
                                                    onFileLoaded={handleForce}
                                                    onError={handleError}
                                                    parserOptions={toParseOptions}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mt-3">
                                            <div className="col">
                                                <Alert color={alerttype} isOpen={visible2} toggle={onDismiss2} >
                                                    {messageAlert}
                                                </Alert>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            </LoadingOverlay>
        </div>
    )

}

export default Programadores;