export default {
    listado:[
        'Universidad Central de Venezuela',
        'Universidad de Los Andes',
        'Universidad de Carabobo',
        'Universidad de Oriente',
        'Universidad del Zulia',
        'Universidad Latinoamericana y del Caribe',
        'Universidad Nacional Experimental Politécnica de la Fuerza Armada Nacional',
        'Universidad Nacional Experimental de la Seguridad',
        'Universidad Pedagógica Experimental Libertador',
        'Universidad Nacional Experimental de las Artes',
        'Universidad Central de Diseño',
        'Universidad Nacional Experimental de Guayana',
        'Universidad Nacional Experimental de Los Llanos Centrales Rómulo Gallegos',
        'Universidad Nacional Experimental de los Llanos Occidentales Ezequiel Zamora',
        'Universidad Experimental Venezolana de los Hidrocarburos',
        'Universidad Nacional Experimental del Táchira',
        'Universidad Nacional Experimental Francisco de Miranda',
        'Universidad Nacional Experimental Politécnica Antonio José de Sucre',
        'Universidad Nacional Experimental Rafael María Baralt',
        'Universidad Nacional Experimental Simón Rodríguez',
        'Universidad Nacional Experimental Marítima del Caribe',
        'Universidad Nacional Experimental de Yaracuy',
        'Universidad Nacional Experimental Sur del Lago Jesús María Semprum',
        'Universidad Nacional Experimental del Magisterio Samuel Robinson',
        'Universidad Nacional Experimental de la Gran Caracas',
        'Universidad Nacional Experimental de las Telecomunicaciones e Informática',
        'Universidad Nacional Experimental del Transporte',
        'Universidad Simón Bolívar',
        'Universidad Centroccidental Lisandro Alvarado',
        'Universidad Bolivariana de Venezuela',
        'Universidad Nacional Abierta',
        'Universidad de los Pueblos del Sur',
        'Universidad Indígena de Venezuela',
        'Universidad Politécnica Territorial del Norte de Monagas Ludovico Silva',
        'Universidad Politécnica Territorial del estado Portuguesa Juan de Jesús Montilla',
        'Universidad Politécnica Territorial del Oeste de Sucre Clodosbaldo Russián',
        'Universidad Politécnica Territorial de Paria Luis Mariano Rivera',
        'Universidad Politécnica Territorial del estado Mérida Kléber Ramírez',
        'Instituto Universitario Politécnico de las Fuerzas Armadas Nacionales',
        'Instituto Universitario de Tecnología José Antonio Anzoátegui',
        'Instituto Universitario de Tecnología Bomberil',
        'Instituto Nacional de Capacitación y Educación Socialista',
        'Universidad Militar Bolivariana de Venezuela',
        'Universidad Gran Mariscal de Ayacucho',
        'Universidad Alejandro de Humboldt',
        'Universidad Alonso de Ojeda',
        'Universidad Arturo Michelena',
        'Universidad Bicentenaria de Aragua',
        'Universidad Rafael Urdaneta',
        'Universidad Católica Andrés Bello',
        'Universidad Católica Cecilio Acosta',
        'Universidad Politécnica Territorial de los Altos Mirandinos Cecilio Acosta (uptamca)',
        'Universidad Católica del Táchira',
        'Universidad Católica Santa Rosa',
        'Universidad de Falcón',
        'Universidad de Margarita',
        'Universidad del Norte',
        'Universidad Dr. José Gregorio Hernández',
        'Universidad Dr. Rafael Belloso Chacín',
        'Universidad Fermín Toro',
        'Universidad José Antonio Páez',
        'Universidad José María Vargas',
        'Universidad Politécnica de Valencia',
        'Universidad Metropolitana',
        'Universidad Monteávila',
        'Universidad Nueva Esparta',
        'Universidad Panamericana del Puerto',
        'Universidad Santa Inés',
        'Universidad Santa María',
        'Universidad Tecnológica del Centro',
        'Universidad Valle del Momboy',
        'Universidad Yacambú',
        'Instituto Universitario Eclesiástico Santo Tomás de Aquino',
        'Instituto Universitario de Tecnología Industrial',
        'Instituto Universitario Politécnico Santiago Mariño',
        'Instituto Universitario de Tecnología de Valencia',
        'Instituto Universitario de Tecnología Juan Pablo Pérez Alfonso',
        'Instituto Universitario de Tecnología de Administración',
        'Instituto Universitario de Tecnología Antonio Ricaurte',
        'Instituto Universitario Tecnológico Américo Vespucio',
        'Instituto Universitario de Tecnología Industrial Rodolfo Loero Arismendi',
        'Instituto Universitario de Mercadotecnia',
        'Instituto Universitario de Nuevas Profesiones',
        'Instituto Universitario Tecnológico de Seguridad Industrial',
        'Instituto Universitario de Tecnología Antonio José de Sucre',
        'Instituto Universitario Carlos Soublette',
        'Colegio Universitario de Administración y Mercadeo',
        'Colegio Universitario Fermín Toro',
        'Colegio Universitario Monseñor de Talavera',
        'Instituto de Especialidades Aeronáuticas',
        'Instituto Universitario de Tecnología Rufino Blanco Fombona',
        'Instituto Universitario de Tecnología Readic',
        'Colegio Universitario de Caracas',
        'Colegio Universitario Francisco de Miranda',
        'Colegio Universitario José Lorenzo Pérez Rodríguez',
        'No poseo estudios universitarios',
        'Universidad Politécnica Territorial de Caracas Mariscal Sucre'
    ]
}