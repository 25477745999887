import React from 'react';
import Programadores from '../components/programadores/programadores';


const ProgramadoresContainer = (props) => {


    function registro(email, password){

    }

    return(
        <Programadores resgister={registro} />
    )

}

export default ProgramadoresContainer;