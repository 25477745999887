import React  from 'react';
import { Input, Label } from 'reactstrap';


function Curso(props) {

    return <div className="form-group row m-0">
        <div className="col-12">
            <div className="form-holder">
                <Label>Nombre del curso</Label>
                <Input type="text" className="form-control" name="cursoNombre" value={props.curso.cursoNombre} 
                    readOnly={!props.edit}
                    onChange={(e) => {props.valueInput(e.target.value, e.target.name, props.indice )}} />
            </div>
        </div>
        <div className="col-12">
            <div className="form-holder">
                <Label><b>Descripción</b></Label>
                {!props.edit &&
                    <p style={{textAlign: 'justify'}}>{props.curso.cursoDescripcion}</p>
                }
                {props.edit &&
                    <Input type="textarea"  name="cursoDescripcion" id="cursoDescripcion" cols="90" rows="4"    
                        value={props.curso.cursoDescripcion} 
                        onChange={(e) => {props.valueInput(e.target.value, e.target.name, props.indice )}}
                />
                }
            </div>
        </div>
    </div>;
}

export default Curso;