import React, {useEffect, useState} from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/opensans-font.css';
import './fonts/material-design-iconic-font/css/material-design-iconic-font.min.css'

import { useSelector, useDispatch } from 'react-redux'
import { todosProgramadores } from './actions';

import firebase from './firebase/firebase';
import Login from './auth/Login';
import Register from './auth/Register';
import ForgotPassword from './auth/ForgotPassword';
import Home from './containers/Home';
import DettalleProgramador from './pagesadmin/DettalleProgramador';
import Noexiste from './components/Noexiste';
import Configuracion from './pagesadmin/Configuracion';
import Mensajes from './pagesadmin/Mensajes';
import ListadoComputrabajo from './pagesadmin/ListadoComputrabajo';
import ComputrabajoDetalle from './pagesadmin/ComputrabajoDetalle';
import Requisitos from './components/Requisitos';
import ProgramadoresContainer from './containers/ProgramadoresContainer';
import DatosIncompletos from './components/programadores/DatosIncompletos';
import Logs from './pagesadmin/Logs';
import ProtectedRoute from './ProtectedRoute';
import Jorge from './components/jorge';


function App() {
  const dispatch = useDispatch();
  const [firebaseInitialized, setFirebaseInitialized] = useState(false);
  const programadorestodos = useSelector((state) => state.general.programadorestodos);
  
  useEffect(()=>{
    firebase.isInitialized().then(val => {
      setFirebaseInitialized(val);
    })
  })

  useEffect(() => {

   
    const loadProgrammers = () => {

      console.log(programadorestodos.length)
      if(programadorestodos.length === 0) {
        firebase.db.collection("programadores")
          .where("admin","==", false)
          .get()
          .then(querySnapshot => {
            let objetoProgramador;
            let arrayProgramador = []
            querySnapshot.forEach((doc) => {
                objetoProgramador = doc.data();
                objetoProgramador.id = doc.id
                arrayProgramador.push(objetoProgramador)
            });
            dispatch({ type: 'PROGRAMADORES_TODOS', payload: arrayProgramador });
          }); 
      } 
    }
    loadProgrammers();
  }, [])

  return firebaseInitialized !== false ? (
    <Router>
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route  path="/home" component={Home}></Route>
        <Route  path="/login" component={Login}></Route>
        <Route  path="/register" component={Register}></Route>
        <Route  path="/forgotpassword" component={ForgotPassword}></Route>
        <Route  path="/requisitos" component={Requisitos}></Route>
        <ProtectedRoute path="/detalle/:id" component={DettalleProgramador} />
        <ProtectedRoute path="/configuracion" component={Configuracion} />
        <ProtectedRoute path="/mensajes" component={Mensajes} />
        <ProtectedRoute path="/programadores" component={ProgramadoresContainer} />
        <ProtectedRoute path="/todos" component={DatosIncompletos} />
        <ProtectedRoute path="/logs" component={Logs} />
        <ProtectedRoute path="/computrabajo/:email" component={ComputrabajoDetalle} />
        <ProtectedRoute path="/computrabajo" component={ListadoComputrabajo} />
        <ProtectedRoute path="/jorge" component={Jorge} />
        {/*<Route  path="/detalle/:id" component={DettalleProgramador}></Route>
        <Route  path="/configuracion" component={Configuracion}></Route>
        <Route  path="/mensajes" component={Mensajes}></Route>
        <Route  path="/programadores" component={ProgramadoresContainer}></Route>*/}
        <Route  path='*' exact={true} component={Noexiste}></Route>
      </Switch>
    </Router>
  ) : <div id="loader">
        <div className="lds-heart"><div></div></div>
      </div>
}

export default App;
