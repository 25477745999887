import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";

const Requisitos = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <main className="container d-flex align-items-center justify-content-center">
      <div className="row h-100 align-items-center justify-content-center  w-100">
        <div className="row p-0 w-100">
          <div className="col-12 p-0 list-postul rounded border border-secondary shadow-lg">
            <div className="list-postul-header text-center p-3">
              <h2>¿Qué buscamos?</h2>
            </div>
            <div className="list-postul-content py-3 px-2 px-md-5 text-left">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    <i class="fab fa-php"></i> PHP Frameworks
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    <i class="fab fa-js"></i> Javascript Angular/React/Vue.js
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    <i class="fas fa-mobile-alt"></i> Mobile
                    Kotlin/Flutter/Switch
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    <i class="fab fa-react"></i> Mobile Ionic/React
                    Native/Xamarin
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      toggle("5");
                    }}
                  >
                    <i class="fab fa-java"></i> Java
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "6" })}
                    onClick={() => {
                      toggle("6");
                    }}
                  >
                    <i class="fas fa-network-wired"></i> .NET
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "7" })}
                    onClick={() => {
                      toggle("7");
                    }}
                  >
                    <i class="fab fa-python"></i> Python/Django
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "8" })}
                    onClick={() => {
                      toggle("8");
                    }}
                  >
                    <i class="far fa-copyright">++</i> C/C++
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "9" })}
                    onClick={() => {
                      toggle("9");
                    }}
                  >
                    <i class="fas fa-atom"></i> Ruby on Rails
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "10" })}
                    onClick={() => {
                      toggle("10");
                    }}
                  >
                    <i class="fas fa-database"></i> BDs relacionales/No
                    relacionales
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "11" })}
                    onClick={() => {
                      toggle("11");
                    }}
                  >
                    <i class="fab fa-wordpress"></i>{" "}
                    .Wordpress/Drupal/Magento/Joomla
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "12" })}
                    onClick={() => {
                      toggle("12");
                    }}
                  >
                    <i class="fab fa-spotify"></i> Prestashop/Spotify
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "13" })}
                    onClick={() => {
                      toggle("13");
                    }}
                  >
                    <i class="fas fa-server"></i> Data Science/Big Data
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "14" })}
                    onClick={() => {
                      toggle("14");
                    }}
                  >
                    <i class="fas fa-pen-fancy">ux</i>
                    <i class="fas fa-pencil-ruler"></i> Diseño UX/UX
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "15" })}
                    onClick={() => {
                      toggle("15");
                    }}
                  >
                    <i class="fas fa-sitemap"></i> Community Managers/SEOs
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="row mb-5">
                    <div className="col-12">
                      <h5 className="text-center mt-3">
                        ¿Tienes conocimientos o experiencias en algunos de los
                        siguientes temas?
                      </h5>
                      <p className="mt-3">
                        <ul>
                          <li> Laravel</li>
                          <li> Symfony</li>
                          <li> Phalcon</li>
                          <li> CodeIgniter</li>
                          <li> FuelPHP</li>
                          <li> Zend Framework</li>
                          <li> CakePHP</li>
                          <li> Yii/Yii 2</li>
                          <li> Slim</li>
                          <li> Fat-Free Framwork</li>
                          <li> PHPixie</li>
                          <li> Aura</li>
                          <li> Flight</li>
                          <li> Medoo</li>
                          <li> POP PHP</li>
                          <li> Zikula</li>
                          <li> Kohana</li>
                          <li> PHP Mini</li>
                          <li> Simple PHP framework</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="row mb-5">
                    <div className="col-12">
                      <h5 className="text-center mt-3"></h5>
                      <p className="mt-3"></p>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="row mb-5">
                    <div className="col-12">
                      <h5 className="text-center mt-3">
                        ¿Tienes conocimientos o experiencias en algunos de los
                        siguientes temas?
                      </h5>
                      <p className="mt-3">
                        <ul>
                          <li>¿Has trabajado con geolocalización?</li>
                          <li>¿Has trabajado con material design?</li>
                          <li>
                            ¿Tiempo de experiencia en el consumo de API REST?
                          </li>
                          <li>¿Qué librerías de API REST has utilizado?</li>
                          <li>¿Conoces Retrofit?</li>
                          <li>¿Has trabajado con la arquitectura MVP?</li>
                          <li>¿Has usado RXJava o Dager?</li>
                          <li>
                            Experiencia creando paneles administrativos y
                            webservices de apps.
                          </li>
                          <li>
                            ¿Has trabajado con control de versiones git/github?
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="4">
                  <div className="row mb-5">
                    <div className="col-12">
                      <h5 className="text-center mt-3"></h5>
                      <p className="mt-3"></p>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="5">
                  <div className="row mb-5">
                    <div className="col-12">
                      <h5 className="text-center mt-3">
                        ¿Tienes conocimientos o experiencias en algunos de los
                        siguientes temas?
                      </h5>
                      <p className="mt-3">
                        <ul>
                          <li> ¿Tienes experiencia en Java EE?</li>
                          <li>
                            ¿Tienes experiencia en Java Server Pages (JSP)?
                          </li>
                          <li> ¿Has trabajado con Spring Framework?</li>
                          <li> ¿Has trabajado con Hibernate?</li>
                          <li> ¿Conoces Retrofit?</li>
                          <li> ¿Has trabajado con la arquitectura MVP?</li>
                          <li> ¿Has usado RXJava o Dager?</li>
                          <li> ¿Conoces Tomcat?</li>
                          <li>
                            Experiencia creando paneles administrativos y
                            webservices de apps.
                          </li>
                          <li>
                            ¿Has trabajado con control de versiones git/github?
                          </li>
                          <li>
                            ¿Qué sistemas de gestión de bases de datos conoces?
                          </li>
                          <li>¿Qué quieres decir con Constructor?</li>
                          <li>
                            Diferencia entre String, String Builder y String
                            Buffer. Explique sobre los especificadores de acceso
                            público y privado. Diferencia entre los
                            especificadores de acceso predeterminados y
                            protegidos. Diferencia entre HashMap y HashTable.
                            Diferencia entre HashSet y TreeSet. Diferencia entre
                            clase abstracta e interfaz.
                          </li>
                          <li>
                            ¿Qué es el significado de Collections (Colecciones)
                            en Java?
                          </li>
                          <li>
                            ¿Cuáles son todas las clases e interfaces que están
                            disponibles en las colecciones?
                          </li>
                          <li>
                            ¿Qué se entiende por ordenado (Ordered) y arreglado
                            en colecciones (Sorted in collections)?
                          </li>
                          <li>
                            Explique sobre las diferentes listas disponibles en
                            la colección.
                          </li>
                          <li>
                            ¿Explica sobre Set y sus tipos en una colección?
                          </li>
                          <li>Hablemos sobre 'map' y sus tipos.</li>
                          <li>¿Qué es la media por excepción?</li>
                          <li>¿Cuáles son los tipos de excepciones?</li>
                          <li>
                            ¿Cuáles son las diferentes formas de manejar las
                            excepciones?
                          </li>
                          <li>
                            ¿Cuáles son las ventajas del manejo de excepciones?
                          </li>
                          <li>
                            Cuáles son las palabras clave de manejo de
                            excepciones en Java?
                          </li>
                          <li>
                            Explique acerca de la propagación de excepciones
                            (Exception Propagation)
                          </li>
                          <li>¿Que implica la palabra clave final en Java?</li>
                          <li>
                            ¿Qué es un hilo?,¿Cómo se hace un hilo en Java?
                          </li>
                          <li>¿Qué hace el método yield de la clase Thread?</li>
                          <li>Hablemos sobre el método wait().</li>
                          <li>
                            Hablemos de la diferencia entre el método de
                            notify() y el método de notifyAll () en Java.
                          </li>
                          <li>
                            ¿Cómo se detiene un hilo en java? Hablemos sobre el
                            método sleep () en un hilo.
                          </li>
                          <li>
                            ¿Cuándo se usa la interfaz Runnable y Thread en
                            Java?
                          </li>
                          <li>
                            Cuál es la diferencia entre el método de start () y
                            el método run () de la clase thread.
                          </li>
                          <li>¿Qué es multi-threading?</li>
                          <li>Explica el ciclo de vida del hilo en Java.</li>
                          <li>¿Qué es la sincronización?</li>
                          <li>¿Cuál es la desventaja de la sincronización?</li>
                          <li>
                            ¿Qué se entiende por serialización (serialization)?
                          </li>
                          <li>
                            ¿Cuál es el propósito de una variable transitoria?
                          </li>
                          <li>
                            ¿Qué métodos se usan durante el proceso de
                            serialización y deserialización?{" "}
                          </li>
                          <li>
                            ¿Cuál es el propósito de una variable volátil?
                          </li>
                          <li>
                            Diferencia entre serialización y deserialización en
                            Java.
                          </li>
                          <li>¿Qué es SerialVersionUID?</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="6">
                  <div className="row mb-5">
                    <div className="col-12">
                      <h5 className="text-center mt-3">
                        ¿Tienes conocimientos o experiencias en algunos de los
                        siguientes temas?
                      </h5>
                      <p className="mt-3">
                        <ul>
                          <li>
                            ¿Tiene experiencia en desarrollo de aplicaciones
                            desktop conectadas a SQL Server?
                          </li>
                          <li>
                            ¿Has conectado aplicaciones desktops o móviles a
                            servicios REST o SOAP?
                          </li>
                          <li>¿Conoce la tecnología ASP?</li>
                          <li>
                            ¿Cuál fue la primera y la última versión de ASP.NET
                            MVC que utilizó?
                          </li>
                          <li>¿Has utilizado Entity Framework y Linq?</li>
                          <li>
                            ¿Has creado vistas de Razor que usen HTML, C# y
                            Javascript?
                          </li>
                          <li>
                            ¿Conoce el modelo MVC y la programación por capas?
                          </li>
                          <li>
                            ¿Has utilizado expresiones lambda para crear
                            delegados y funciones anónimas?
                          </li>
                          <li>
                            ¿Has trabajado con la tecnología móvil Xamarin?
                          </li>
                          <li>¿Has trabajado con el patrón de diseño MVVM?</li>
                          <li>
                            ¿Cuántos lenguajes son compatibles con Microsoft NET
                            Framework?
                          </li>
                          <li>
                            ¿Cuál es la vida útil de los elementos en ViewState?
                          </li>
                          <li>¿Qué es "CTS"?</li>
                          <li>Defina "encapsulación"</li>
                          <li>
                            ¿Cuál es la diferencia entre una "clase" y un
                            "objeto"?
                          </li>
                          <li>
                            ¿Existe alguna diferencia entre "debug" y "trace"?
                          </li>
                          <li>
                            ¿Cuál es la diferencia entre "in-process" y
                            "out-of-process"?
                          </li>
                          <li>¿Qué es MSIL?</li>
                          <li>¿Qué es "inheritance"?</li>
                          <li>
                            ¿Cuál es la diferencia entre "managed code" y
                            "unmanaged code"?
                          </li>
                          <li>
                            ¿Existe alguna diferencia entre “int” y “System?
                            Int32"
                          </li>
                          <li>Defina "caching"</li>
                          <li>¿Qué es un "ensamblaje"?</li>
                          <li>
                            ¿Microsoft NET Framework se basa en OOP o AOP?
                          </li>
                          <li>
                            ¿Es posible ajustar manualmente un temporizador de
                            "session out"?
                          </li>
                          <li>
                            ¿Puedes mezclar diferentes lenguajes en la carpeta
                            "App Code"?
                          </li>
                          <li>¿Qué es un archivo ".DLL"?</li>
                          <li>
                            ¿Existe alguna diferencia entre "stack" y "queue"?
                          </li>
                          <li>¿Qué es "boxing"?</li>
                          <li>
                            ¿Se puede cambiar o modificar el índice de la clave
                            primaria?
                          </li>
                          <li>
                            ¿Cómo validamos los datos ubicados en el sitio web?
                          </li>
                          <li>
                            ¿Cuál es la diferencia entre "funciones" y
                            "procedimientos de almacenaje"?
                          </li>
                          <li>
                            ¿Existe alguna diferencia entre "stacks" y "heaps"?
                          </li>
                          <li>¿Qué es un Recolector de Basura?</li>
                          <li>
                            ¿Puedes especificar modificadores de acceso en la
                            interfaz?
                          </li>
                          <li>¿Qué es un "tuple"?</li>
                          <li>
                            ¿Cuántos índices existen en Microsoft NET Framework?
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="7">
                  <div className="row mb-5">
                    <div className="col-12">
                      <h5 className="text-center mt-3">
                        ¿Tienes conocimientos o experiencias en algunos de los
                        siguientes temas?
                      </h5>
                      <p className="mt-3">
                        <ul>
                          <li> Django</li>
                          <li> CubicWeb</li>
                          <li> Giotto</li>
                          <li> Pylons Framework</li>
                          <li> Pyramid</li>
                          <li> TurboGears</li>
                          <li> Web2Py</li>
                          <li> Bottle</li>
                          <li> CherryPy</li>
                          <li> Dash</li>
                          <li> Falcon</li>
                          <li> Flask</li>
                          <li>Hug</li>
                          <li>MorePath</li>
                          <li>Pycnic</li>
                          <li>AIOHTTP</li>
                          <li>Growler</li>
                          <li>Sanic</li>
                          <li>Tornado</li>
                          <li>¿Conoces ODOO?</li>
                          <li>¿Sabes modificar módulos en ODOO?</li>
                          <li>¿Sabes desarrollar módulos en ODOO?</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="8">
                  <div className="row mb-5">
                    <div className="col-12">
                      <h5 className="text-center mt-3"></h5>
                      <p className="mt-3"></p>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="9">
                  <div className="row mb-5">
                    <div className="col-12">
                      <h5 className="text-center mt-3">
                        ¿Tienes conocimientos o experiencias en algunos de los
                        siguientes temas?
                      </h5>
                      <p className="mt-3">
                        <ul>
                          <li> ¿Qué es request.xhr?</li>
                          <li>
                            ¿Cuál es la diferencia entre un Scaffolding dinámico
                            y estático?
                          </li>
                          <li>¿Cuál es la diferencia entre Symbol y String?</li>
                          <li> ¿Qué es una sesión y una cookie?</li>
                          <li> ¿Por qué Ruby on Rails?</li>
                          <li> ¿Qué es MVC y cómo funciona?</li>
                          <li> ¿Qué cosas puedes definir en el modelo?</li>
                          <li> ¿Qué es ORM en Rails?</li>
                          <li>¿Cuántos tipos de relaciones tiene un modelo?</li>
                          <li>
                            ¿Cuál es la diferencia entre has_and_belongs_to_many
                            y has_many: through?
                          </li>
                          <li>
                            ¿Qué diferencias tiene rails version 2.2.2 con las
                            anteriores?
                          </li>
                          <li>
                            ¿Cuál es la diferencia entre render y redirect?
                          </li>
                          <li>
                            ¿Cómo podemos utilizar una base de datos sql o mysql
                            sin definirla en el database.yml?
                          </li>
                          <li>
                            ¿Qué son los helpers y cómo se utilizan en Rails?
                          </li>
                          <li>¿Qué es Active Record?</li>
                          <li>
                            ¿Ruby soporta herencia simple, herencia múltiple o
                            las dos?
                          </li>
                          <li>
                            ¿Cuántos tipos de callbacks están disponibles en
                            Rails?
                          </li>
                          <li>
                            Supongamos que en mi método estoy actualizando
                            atributos de una tabla, en mi modelo tengo definido
                            after_create hacer X, y after_save hacer Y. ¿Cuál
                            metodo será ejecutado?
                          </li>
                          <li>
                            ¿Cómo utilizas dos bases de datos en una sola
                            aplicación?
                          </li>
                          <li>
                            Dime, ¿Cuáles son las diferencias entre la version 2
                            y 3 de Rails?
                          </li>
                          <li>¿Qué es un bundler?</li>
                          <li>
                            ¿Cuál es la nueva forma de encontrar (:all) en Rails
                            3?
                          </li>
                          <li>¿Qué es un Gemfile y Gemfile.lock?</li>
                          <li>
                            ¿Cuál es la notación utilizada para denotar las
                            variables de clase en Ruby?
                          </li>
                          <li>¿Cuál es el uso de un método destructivo?</li>
                          <li>¿Cuál es el uso de load y require en Ruby?</li>
                          <li>
                            ¿Cuál es el uso de variables globales en Ruby?
                          </li>
                          <li>¿Cuál es la diferencia entre null y false?</li>
                          <li>
                            ¿Cómo cambias el alcance de los métodos en Ruby?
                          </li>
                          <li>¿Qué es una variable de instancia de clase?</li>
                          <li>
                            ¿Cuáles son las reglas y convenciones que se deben
                            seguir en Ruby para nombrar a un método?
                          </li>
                          <li>¿Cuál es el uso de Super?</li>
                          <li>
                            ¿Cuáles son los operadores disponibles en Ruby?
                          </li>
                          <li>
                            ¿Cuál es la estructura de bucle disponible en Ruby?
                          </li>
                          <li>¿Cuál es el alcance de la variable local?</li>
                          <li>
                            ¿Qué elementos de Programación Orientada a Objetos
                            soporta Ruby?
                          </li>
                          <li>Ruby sobre PHP, ¿por qué?</li>
                          <li>¿Cómo funciona Garbage collection en Ruby?</li>
                          <li>¿Cuáles son las variables de entorno en Ruby?</li>
                          <li>¿Qué es Float, Dig y Max?</li>
                          <li>¿Qué tipo de condiciones soporta Ruby?</li>
                          <li>¿Cuál es la diferencia entre puts y print?</li>
                          <li>
                            ¿Qué es la metodología ágil? ¿Cuáles son sus
                            procesos?
                          </li>
                          <li>
                            ¿Hay alguna tecnología, aparte de ágil que podemos
                            utilizar?
                          </li>
                          <li>
                            ¿Qué es la Meta-programación? ¿Cómo la utilizas
                            dentro de tu aplicación Rails?
                          </li>
                          <li>¿Qué es has_many?</li>
                          <li>¿Qué es TDD y BDD?</li>
                          <li>
                            ¿Qué es rspec, cucumber y Watir?, y ¿Qué tienen que
                            ver con TDD y BDD?
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="10">
                  <div className="row mb-5">
                    <div className="col-12">
                      <h5 className="text-center mt-3"></h5>
                      <p className="mt-3"></p>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="11">
                  <div className="row mb-5">
                    <div className="col-12">
                      <h5 className="text-center mt-3"></h5>
                      <p className="mt-3"></p>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="12">
                  <div className="row mb-5">
                    <div className="col-12">
                      <h5 className="text-center mt-3"></h5>
                      <p classN=" mt-3"></p>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="13">
                  <div className="row mb-5">
                    <div className="col-12">
                      <h5 className="text-center mt-3"></h5>
                      <p className="mt-3"></p>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="14">
                  <div className="row mb-5">
                    <div className="col-12">
                      <h5 className="text-center mt-3"></h5>
                      <p className="mt-3"></p>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="15">
                  <div className="row mb-5">
                    <div className="col-12">
                      <h5 className="text-center mt-3"></h5>
                      <p className="mt-3"></p>
                    </div>
                  </div>
                </TabPane>
              </TabContent>

              <div
                className="w-100 "
                style={{ position: "absolute", bottom: 0 }}
              >
                <div className="row mb-3 w-100">
                  <div className="col-12 text-center">
                    <Link to="/login">¿Ya tengo una cuenta?</Link>
                  </div>
                  <div className="col-12 text-center">
                    ¿No tienes una cuenta? Registrate{" "}
                    <Link to="/register">aquí</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Requisitos;
