import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Input, Label, Button, Alert } from 'reactstrap';
import firebase from '../firebase/firebase';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import moment from 'moment';
import Tecnologia from '../components/parciales/tecnologiaForm';
import Experiencia from '../components/parciales/experienciaForm';
import Links from '../components/parciales/linkForm';
import Curso from '../components/parciales/cursoForm';
import Menu from '../components/Menu';
import notificaciones from '../mocks/notificaciones';
import {Typeahead} from 'react-bootstrap-typeahead';
import universidades from '../mocks/universidades';
import LoadingOverlay from 'react-loading-overlay';  
import Chip from '../components/chip';
import config from '../config/index';

class DetalleProgramador  extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.back = this.back.bind(this);
        this.viewPDF = this.viewPDF.bind(this);
        this.actualizarCostos = this.actualizarCostos.bind(this);
        this.closeAlerts = this.closeAlerts.bind(this);
        this.logout = this.logout.bind(this);
        this.changeEstado = this.changeEstado.bind(this);
        this.changeMotivo = this.changeMotivo.bind(this);
        this.actualizarEstado = this.actualizarEstado.bind(this);
        this.notificarProgramador = this.notificarProgramador.bind(this);
        this.match = this.match.bind(this);

        this.guardarDatosGenerales = this.guardarDatosGenerales.bind(this);
        this.guardarTecnologias = this.guardarTecnologias.bind(this);
        this.guardarExperiencias = this.guardarExperiencias.bind(this);
        this.guardarLinks = this.guardarLinks.bind(this);
        this.guardarCursos = this.guardarCursos.bind(this);
        this.handleChangeGenerales = this.handleChangeGenerales.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeAutocomplete = this.handleChangeAutocomplete.bind(this);
        this._handleSearch = this._handleSearch.bind(this);
        this.valueInputTecnologia = this.valueInputTecnologia.bind(this);
        this.valueInputExperiencia = this.valueInputExperiencia.bind(this);
        this.valueInputLink = this.valueInputLink.bind(this);
        this.valueInputCurso = this.valueInputCurso.bind(this);
        this.addTag = this.addTag.bind(this);
        this.selected = this.selected.bind(this);
        this.cancelarTag = this.cancelarTag.bind(this);
        this.remove = this.remove.bind(this);
        this.handleChangeIdiomas = this.handleChangeIdiomas.bind(this);
        this._handleSearchIdiomas = this._handleSearchIdiomas.bind(this);
        
        this.state = {
            datosGenerales: {
                activo: null,
                admin: null,
                anosExperiencia: 0,
                apellidos: '',
                aspiracion: '',
                cedula: '',
                chat: null,
                ciudad: '',
                costosMes: 0,
                cursos: [],
                datosPersonales: '',
                direccion: '',
                disponibilidad: '',
                email: '',
                experiencias: [],
                fecha: '',
                links: [],
                mensajes: [],
                idiomas: [],
                nivelIngles: '',
                nombres: '',
                numWhatsapp: '',
                registroCompletado: null,
                rol: '',
                tecnologias: [],
                tipoPerfil: '',
                tipoPerfiles: [],
                titulo: '',
                uid: '',
                ultimaPestana: '',
                universidad: ''
            },
            tag: '',
            tags: [],
            editTag: false,
            indexTag: -1,
            alertTypeTag: 'danger',
            openAlertTag: false,
            messageAlertTag: '',
            tecnologias: [],
            experiencias: [],
            links: [],
            cursos: [],
            imagenes: [],
            historico: [],

            salario: 0,
            costoMes: 0,
            openAlert: false,
            alertType: 'danger',
            alertMessage: '',
            costoMesString: '0',
            estado: 'No contratado',
            motivo: notificaciones.noContratado.motivos[0],

            openAlertEstado: false,
            openAlertDatos: false,
            openAlertDatosTecnologias: false,
            alertTypeEstado: 'danger',
            alertMessageEstado: '',
            alertMessageDatos: '',
            alertMessageDatosTecnologias: '',
            vistaPdf: false,
            loader: true,
            loading: false,
            busquedas: [],
            matches: [],
            isMatch: false,
            routeId: '',
            editDatosGenerales : false,
            editDatosTecnologias: false,
            multiple: false,
            categorias: [],
            universidades: universidades.listado.sort(),
            tempTecnologias: [],
            editDatosExperiencias: false,
            openAlertDatosEXperiencias: false,
            alertMessageEXperiencia: '',

            editDatosLinks: false,
            openAlertDatosLinks: false,
            alertMessageLinks: '',
            editDatosCursos: false,
            openAlertDatosCursos: false,
            alertMessageCursos: '',
            addNew: false,
            idiomasList: [
                {id: 'Árabe', name:'Árabe'},
                {id: 'Chino mandarín', name:'Chino mandarín'},
                {id: 'Español', name:'Español'},
                {id: 'Francés', name:'Francés'},
                {id: 'Ingles', name:'Ingles'},
                {id: 'Italiano', name:'Italiano'},
                {id: 'Portugués', name:'Portugués'}
            ]
        }
    }

    componentDidMount(){
        const arreglo = this.props.location.pathname.split('/');
        const id = arreglo[2];
        this.setState({ routeId: id });
        this.loadBusquedas();
        this.getData(id);
        this.loadCategorias();
    }

    loadCategorias(){
        firebase.db.collection("categorias")
           .orderBy("nombre")
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => doc.data());
                this.setState({ categorias: data })
            });
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    handleChangeGenerales = (e) => {
        let datosG = this.state.datosGenerales;
        datosG[e.target.name] = e.target.value;
        this.setState({datosGenerales: datosG});
    }

    handleChangeSelect = (e) =>{
        let datosG = this.state.datosGenerales;
        datosG[e.target.name] = e.target.value;
        this.setState({datosGenerales: datosG});
        //this.setState({[e.target.name]: e.target.options[e.target.selectedIndex].text});
    }

    _handleSearch(text, e){
        let value = text;
        if(text.indexOf(',') !== -1){
            const array = text.split(',');
            value = array[0];
            let search = [];
            search = this.state.categorias.filter(el => el.nombre.toLowerCase().indexOf(value.toLowerCase()) !== -1);
            if(search.length === 0){
                this._typeahead._handleSelectionAdd({customOption: true, id: Math.random().toString(20).substr(2, 6), nombre: search[0].nombre})
            } else{
                this._typeahead._handleSelectionAdd({nombre: search[0].nombre})
            }
        }
        let filter = [];
        filter = this.state.categorias.filter(el => el.nombre.toLowerCase().indexOf(value.toLowerCase()) !== -1);
        if(filter.length > 0){
            this.setState({addNew: false});
        } else {
            this.setState({addNew: true});
        }
    }

    handleChangeAutocomplete = (e) => {
        let array = [];
        e.forEach((item, i) => { 
            if (item.customOption) {
                firebase.db.collection('categorias').add({nombre: item.nombre, cantidadPersonas: 0});
                let cats = this.state.categorias;
                cats.push({nombre: item.nombre, cantidadPersonas: 0})
                this.setState({ categorias: cats})
                array.push(item.nombre)
            } else { // si no ha seleccionado un perfil nuevo
                if (item.nombre === undefined) {
                    array.push(item)
                }   else {
                    array.push(item.nombre)
                }
            }
        });
        let datosG = this.state.datosGenerales;
        datosG.tipoPerfiles = array;
        this.setState({datosGenerales: datosG});

        /*antes
        let array = [];
        e.forEach((item, i) => {  
            if (item.customOption) { // si ha seleccionado un perfil nuevo
                let filter = this.state.categorias.filter(el => { //busco si ese perfirl nuevo ya esta en la coleccion 
                    return el.nombre.toLowerCase() === item.nombre.toLowerCase()
                });
                if(filter.length === 0){ // si no esta lo agrego en la base de datos
                    firebase.db.collection('categorias').add({nombre: item.nombre, cantidadPersonas: 0});
                    array.push(item.nombre)
                }else { // esta dentro del arreglo
                    array.push(filter[0].nombre);
                    this._typeahead.state.selected = array;
                }
            } else { // si no ha seleccionado un perfil nuevo
                if (item.nombre === undefined) {
                    array.push(item)
                }   else {
                    array.push(item.nombre)
                }
            } 
        })
        let datosG = this.state.datosGenerales;
        datosG.tipoPerfiles = array;
        this.setState({datosGenerales: datosG});*/
    }

    handleChangeIdiomas = (e) => { 
        let array = [];
        e.forEach((item, i) => { 
          if (item.name === undefined) {
            array.push(item)
          } else {
            array.push(item.name)
          }
        });
        let datosG = this.state.datosGenerales;
        datosG.idiomas = array;
        this.setState({datosGenerales: datosG});
    }
  
    _handleSearchIdiomas = (text, e) => { 
  
    }

    back = () => {
        this.props.history.replace('/home');
    }

    actualizarCostos(){
        
        if(this.state.datosGenerales.aspiracion === '' || this.state.datosGenerales.aspiracion ===  0 || this.state.costoMesString === '' || this.state.costoMesString === '0' ){
            this.setState({
                alertType: 'danger',
                alertMessage: 'Debe Llenar todos los datos',
                openAlert: true,
            });
        } else {
            const costoMesString = this.state.costoMesString;
            const costoMesNumber = parseInt(costoMesString, 10)
            firebase.db.collection("programadores").doc(`${this.state.routeId}`)
            .update({aspiracion: this.state.salario,  costoMes: costoMesNumber })
            .then(result => {
                this.setState({
                    alertType: 'success',
                    alertMessage: 'Costos actualizados',
                    openAlert: true,
                })
                this.getData(this.state.routeId);
            }).catch(err => {
                console.log(err.message);
            });
        }
    }

    closeAlerts(){
        this.setState({
            openAlert: false,
            openAlertEstado: false,
            openAlertDatos: false,
            openAlertDatosTecnologias: false,
            openAlertTag: false,
            openAlertDatosEXperiencias: false,
            openAlertDatosLinks: false,
            openAlertDatosCursos: false
        })
    }

    getData(id){
        let datos;
        firebase.db.collection("programadores").doc(`${id}`)
        .get()
        .then(doc => {
            datos = doc.data();
            this.setState({
                datosGenerales: doc.data(),
                tecnologias: datos.tecnologias.sort(this.compare),
                tempTecnologias: Array.from(new Set(datos.tecnologias.sort(this.compare))),
                experiencias: datos.experiencias,
                links: datos.links,
                cursos: datos.cursos,
                estado: datos.estado,
                salario:  datos.aspiracion,
                historico: datos.historico.sort(this.sortHistorico),
                imagenes: datos.imagenes ? datos.imagenes : [],
                tags: datos.tags ? datos.tags.sort() : [],
                loader: false,
                motivo: datos.motivoNocontratado ? datos.motivoNocontratado : notificaciones.noContratado.motivos[0]
            });
            var costo = this.state.datosGenerales.costoMes;
            var costoString  = costo.toString();
            this.setState({ costoMesString: costoString });
        }, err => { 
            console.log(err)
            this.setState({ loader: false});
        });

    }

    sortHistorico(a,b){  
        var dateA = new Date(a.fecha).getTime();
        var dateB = new Date(b.fecha).getTime();
        return dateA < dateB ? 1 : -1;  
    };

    compare(a, b) {
        const tecnologiaA = a.tecnologia.toUpperCase();
        const tecnologiaB = b.tecnologia.toUpperCase();
        let comparison = 0;
        if (tecnologiaA > tecnologiaB) {
          comparison = 1;
        } else if (tecnologiaA < tecnologiaB) {
          comparison = -1;
        }
        return comparison;
    }

    logout() {
        // const history = createHistory()
        firebase.logout();
        localStorage.removeItem('redux');
        // history.replace('/');
    }

    changeEstado(e){
        this.setState({[e.target.name]: e.target.value});
    }

    changeMotivo(e){
        this.setState({[e.target.name]: e.target.value});
    }

    actualizarEstado(){
        let data;
        let arrayHistorico = [];
        arrayHistorico = this.state.datosGenerales.historico;
        arrayHistorico.push({ fecha: this.formatDate(new Date()), accion: 'El estado del programador ha cambiado ha No contratado'  });
        if(this.state.estado === 'No contratado'){
            data = {estado: this.state.estado, historico: arrayHistorico, motivoNocontratado: this.state.motivo };
        } else {
            data = {estado: this.state.estado, motivoNocontratado: null};
        }

        firebase.db.collection("programadores").doc(`${this.state.routeId}`)
            .update(data)
            .then(result => {
                this.setState({
                    alertTypeEstado: 'success',
                    alertMessageEstado: 'Estado actualizado exitosamente',
                    openAlertEstado: true,
                });
                const programador = `${this.state.datosGenerales.nombres} ${this.state.datosGenerales.apellidos}`;
                let motivo = `Estimado/a, <b>${programador}</b> le informamos que la empresa <b> Hispanos Soluciones C.A</b>, `;
                switch (this.state.estado)
                {
                    case "Habilitado":
                        motivo +=  notificaciones.habilitado.texto;
                        break;
                    case "No contratado":
                        motivo +=  notificaciones.noContratado.texto + ` <b>${this.state.motivo}</b>
                                    <p>Le recordamos que puede modificar su perfil en la siguiente url http://postulate.hispanossoluciones.com/, 
                                    y sera evaluado nuevamente.</p>`;
                        break;
                    case "Contratado":
                        motivo +=  notificaciones.contratado.texto; 
                        break;
                    case "Penalizado": 
                        motivo +=  notificaciones.penalizado.texto; 
                        break;
                    case 'No cumple los requisitos':
                        motivo +=  notificaciones.noCumpleRequisitos.texto; 
                        break;
                    case 'Tiene trabajo':
                        motivo +=  notificaciones.tieneTrabajo.texto; 
                        break;
                    default: 
                        break;
                }
                const data = {
                    email:  this.state.datosGenerales.email,	
                    motivo
                }
                this.getData(this.state.routeId);
                this.notificarProgramador(data);
            }).catch(err => {
                console.log(err.message);
            });
    }

    async notificarProgramador(data){
        return await axios.post(`${config.notificationUrl}/sendmail`,  data )
    }

    formatDate(date){
		let day = date.getDate()
		let month = date.getMonth() + 1
		let year = date.getFullYear()
		if(month < 10){
			if(day <10){
				return `0${year}-0${month}-${day}`;
			} else{
				return `${year}-0${month}-${day}`;
			}
		}else{
			if(day <10){
				return `0${year}-${month}-${day}`;
			} else{
				return `${year}-${month}-${day}`;
			}
		}
    }
    
    viewPDF = async () => {
        const self = this;
        const addFooters = doc => {
            const pageCount = doc.internal.getNumberOfPages()
            doc.setFont('helvetica', 'italic')
            doc.setFontSize(10)
            for (var i = 0; i < pageCount; i++) {
                /*doc.text('Hispanos Soluciones C.A. / contacto: info@hispanossoluciones.com', doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 30, {
                    align: 'center'
                  })*/
                doc.setTextColor(0,0,0);
                doc.text('Hispanos Soluciones C.A. / contacto: ', 146, doc.internal.pageSize.height - 20, { });
                doc.setTextColor(51,122,183);
                doc.text('info@hispanossoluciones.com', 316, doc.internal.pageSize.height - 20, {});
            }
          }
        this.setState({ loading: true  }); 
        var doc = new jsPDF('p', 'pt', 'A4', true);
        const llena = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAjCAIAAABzZz93AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGmSURBVEhL7Za7SgNBFIZT+wQK9l4ewPgCSR+0VBAstLKIrQop1cJGwUqsbMROrKzSitiKsMoiKmJhqzbjjwvD+M/uOXOWECyErwgzO/PtucxsGvf5s8pD/pL9/Mjyp3DcSuPj82to/HnZ69s7jSRSR7a8tl3PZ5ZBMzLe3js8pXEV55xZdnRyAVmzvUrjKnVk80tbkIG77JGmZMyyIocF1kyaZRB42eTsIs3KmGUzrRUvA1c3t/SAgE2GIoUm0N3Yp2cEbLIwhwWmTP6Sofi9nePebiXYmmRgffOAHgsJ88yRYW50qkPb1QavHm5ekkbE15rr0jIreOOz8z7tXFkzVJ7Wp4OOLT3vUoPgWqqRUuGOlmQAJaSDJSPfKYoM4DX9ZSiAHKgHXJeB4pqXQU/RqpgkGcpAW5dSVSpPkiyxTZAAWkjoMlSCNq0CCaC1hC6LDxz687J/HY+PTXdoLaHLqPXDGwjKieZCOCs3pCILvymwxnuhKcIQ5S+OIvNNT1cq4UOU/wUpMhzn0oBifIjCvyBFJgcUgxABDXr0Bhkg/7KBMFyZc982987J93mLyAAAAABJRU5ErkJggg=='
        const vacia = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAiCAIAAAC4O+zSAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAF1SURBVEhL7ZbdSgMxEEZ9/1cTRCmKSqFUl61W19ZarfVm/TAwjF+SySSU4oXlXJT8ncxkkvbkc/91NP687H23pxYnLbLp/LnNVy2D5nRy3z2sqd1DtWzx9ArZ5HZB7Tbjz6dadjNbQgY22x11GbTIQg4DVZlskUEgsovrnnoNWmQ4KpGB9eaDBuSoluGQtAnMuoHG5KiW6RwG/JlkGQ5/3r/c9ascWJpkAMHRMI3kmWUAfWeXHS3XDLYuKydkAPFdTR9pWi3Y8XJ408umZQEkh+b7QcXG992SATxLDSnNvdEFGcAR0sWyMd6Usgxgm/IYGiAH9gV3yUB45m1QUzSL8MpwDLR0kuRRCV6Zs0yQAJqocclwErRoDiSA5mpcsvjCoT6H1TZuRwJorsYlo9LXLxCU579fS6MgyzL9mwJrvBaKQoeI7zRAKMuk6HVAMRIiNkRdQlmG65wMKEZCzP0LKsvsgGIQIqDGQFl2QP5lB+HosnH8BtAvjT7SGunQAAAAAElFTkSuQmCC'
        doc.autoTable({
            head: [[`INTERNO ${this.state.datosGenerales.nombres.split(' ')[0]} ${this.state.datosGenerales.apellidos.split(' ')[0]} ${this.state.datosGenerales.cedula}`]],
            body: [
            ],
            headStyles:{
                valign: 'middle',
                halign : 'center',
                fontSize: 18,
                fillColor: [255, 255, 255],
                textColor: [40,167,69],
            },
        })

        let perfiles = [];
        let perfilAcomodado = '';
        perfiles = this.state.datosGenerales.tipoPerfiles;
        perfiles.forEach((item, index) =>{
            if(index !== perfiles.length-1){
                perfilAcomodado +=  item + ', ';
            } else {
                perfilAcomodado +=  item;
            }
        })
     
        doc.autoTable({
            head: [[perfilAcomodado]],
            body: [
            ],
            headStyles:{
                valign: 'middle',
                halign : 'center',
                fontSize: 18,
                fillColor: [255, 255, 255],
                textColor: [40,167,69],
            },
        })
        doc.autoTable({
            head: [['Atributo', 'Valor',]],
            body: [
              ['Nombres', this.state.datosGenerales.nombres],
              ['Apellidos', this.state.datosGenerales.apellidos],
              ['Cédula', this.state.datosGenerales.cedula],
              ['Email', this.state.datosGenerales.email],
              ['Número de contácto', this.state.datosGenerales.numWhatsapp],
              ['Ciudad', this.state.datosGenerales.ciudad],
              ['Dirección', this.state.datosGenerales.direccion],
              ['Fecha Postuación', this.state.datosGenerales.fecha],
              ['Años de Experiencia', this.state.datosGenerales.anosExperiencia],
              ['Título académico', this.state.datosGenerales.titulo],
              ['Estudio Avanzado', this.state.datosGenerales.otroTitulo],
              ['Universidad', this.state.datosGenerales.universidad],
              ['Tipo de Perfil', this.state.datosGenerales.tipoPerfiles],
              ['Idiomas', this.state.datosGenerales.idiomas.join(',')],
              ['Aspiración Salarial', this.state.datosGenerales.aspiracion],
              ['Costo Mensual', this.state.datosGenerales.costoMes],
              ['Estado', this.state.datosGenerales.estado],
            ],
            headStyles: {
                fillColor: [62, 64, 97],
            },
        })
        doc.autoTable({
            head: [['Tecnologías']],
            body: [
            ],
            headStyles:{
                valign: 'middle',
                halign : 'center',
                fontSize: 18,
                fillColor: [255, 255, 255],
                textColor: [40,167,69],
            }
        })
        let arrayTec = [];
        this.state.datosGenerales.tecnologias.forEach((item, i) => {
            arrayTec.push([item.tecnologia, item.tecnologiaAños + ' ' + item.tecnologiaTipo , '', item.tecnologiaVersion]);
        });
        doc.autoTable({
            head: [['Tecnología', 'Tiempo de experiencia', 'Dominio (1-5)', 'Versión']],
            body: arrayTec,
            allSectionHooks: true,
            headStyles: {
                fillColor: [62, 64, 97],
            },
            didDrawCell:  (data) => {
                const arreglo = self.state.datosGenerales.tecnologias;
                const value = arreglo[data.row.index] ? arreglo[data.row.index].dominio : 0;
                if(data.column.index === 2 && data.row.section !== 'head'){
                    for(let i=1; i<=5; i++ ){
                        if(i > value){
                            doc.addImage(vacia, "JPEG", data.cell.x + (15 * i) + 5, data.cell.y + 2, 15, 15);
                        } else {
                            doc.addImage(llena, "JPEG", data.cell.x + (15 * i) + 5 , data.cell.y + 2, 15, 15);
                        }
                    }
                }
            }
        });
        doc.autoTable({
            head: [['Experiencias Laborales']],
            body: [
            ],
            headStyles:{
                valign: 'middle',
                halign : 'center',
                fontSize: 18,
                fillColor: [255, 255, 255],
                textColor: [40,167,69],
            }
        })
        let arrayEmp = [];
        this.state.experiencias.map((item, i) => {
            arrayEmp.push([item.empresaNombre, item.empresaDescripcion]);
            return arrayEmp;
        });
        doc.autoTable({
            head: [['Empresa', 'Actividades']],
            body: arrayEmp,
            headStyles: {
                fillColor: [62, 64, 97],
            },
        });
        doc.autoTable({
            head: [['Links de conocimientos o proyectos realizados']],
            body: [
            ],
            headStyles:{
                valign: 'middle',
                halign : 'center',
                fontSize: 18,
                fillColor: [255, 255, 255],
                textColor: [40,167,69],
            }
        })
        let arrayLin = [];
        this.state.links.map((item, i) => {
            arrayLin.push([item.link, item.linkDescripcion]);
            return arrayLin;
        });
        doc.autoTable({
            head: [['Link', 'Descripción']],
            body: arrayLin,
            headStyles: {
                fillColor: [62, 64, 97],
            },
            columnStyles: {
                0: {
                    fontStyle: 'bold',
                    textDecoration: 'undeerline',
                    textColor: [51,122,183]                
                },
            }
        });

        if(this.state.cursos.length > 0){
            doc.autoTable({
                head: [['Cursos realizados']],
                body: [
                ],
                headStyles:{
                    valign: 'middle',
                    halign : 'center',
                    fontSize: 18,
                    fillColor: [255, 255, 255],
                    textColor: [40,167,69],
                }
            })
            let arrayCur = [];
            this.state.cursos.map((item, i) => {
                arrayCur.push([item.cursoNombre, item.cursoDescripcion]);
                return arrayCur;
            });
            doc.autoTable({
                head: [['Curso', 'Descripción']],
                body: arrayCur,
                headStyles: {
                    fillColor: [62, 64, 97],
                },
            });
        }
        let arrayImagesHtml = []
        const images = this.state.imagenes;
        // let promises = [];
        for (let i = 0; i < images.length; i++) {
            const url = images[i].url;
            try {
                const data = await this.loadImage(url,i);
                arrayImagesHtml.push(data);
            } catch (error) {
                console.log('error', error)
            }
        }
        var width = doc.internal.pageSize.getWidth();
        let inicioY = 40; 
        arrayImagesHtml.forEach((img, vuelta) => {
            const ancho = img.width;
            const alto = img.height;
            if(vuelta % 2 === 0){
                doc.addPage();
                if(alto > ancho){
                    doc.addImage(img, 'JPEG', 200, inicioY, (width-400), 300);
                } else {
                    doc.addImage(img, 'JPEG', 40, inicioY, (width-80), 270);
                }
            } else {
                if(alto > ancho){
                    doc.addImage(img, 'JPEG', 200, (40 + 330), (width-400), 300);
                } else {
                    doc.addImage(img, 'JPEG', 40, (40 + 310), (width-80), 270);
                }
            }
        })
        addFooters(doc);
        this.setState({ loading: false  });
        // window.open(doc.output('bloburl'), '_blank');
        doc.save(`${this.state.datosGenerales.nombres} ${this.state.datosGenerales.apellidos}.pdf`);
    }

    loadImage(url, i) {
        return new Promise((resolve, rejected) => {
            let img = new Image();
            img.setAttribute('crossOrigin', 'anonymous');
            img.onload = () => {
                resolve(img);
            }
            img.onerror = function() {
                rejected('url no valida')
            };
            img.src = url
        })
    }

    match(){
        let array = this.compareBusquedas(this.state.datosGenerales);
        this.setState({ matches: array, isMatch: true });
    }

    loadBusquedas(){
        let objetoBusqueda = null;
        let arrayBusquedas = [];
        firebase.db.collection("busquedas")
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach((doc) => {
                    objetoBusqueda = doc.data();
                    objetoBusqueda.id = doc.id
                    arrayBusquedas.push(objetoBusqueda);
                })
                this.setState({ busquedas: arrayBusquedas });
            });
    }

    compareBusquedas(programador){ // recibo un programador
        let busquedas = [];
        let tecnologias = [];
        let final = [];
        busquedas = this.state.busquedas;
        tecnologias = programador.tecnologias;
        tecnologias.forEach((tec, index) => { // recorro las tecnologias del programador
            let filter = [];
            busquedas.forEach((busqueda, j) => { // recorro las busquedas
                filter = busqueda.tecnologias.filter(row => (row.nombre === tec.tecnologia && row.periodo === tec.tecnologiaTipo && parseInt(tec.tecnologiaAños, 10) >= row.tiempo ))
                if(filter.length > 0){
                    final.push(busqueda);
                }
            }); 
        });
        let distinc = Array.from(new Set(final.map(s => s.id)))
            .map(id => {
                return final.find(s => s.id === id)
            })
        let sortArray = distinc.sort(function(a, b){
            return a.empresa.nombreEmpresa === b.empresa.nombreEmpresa ? 0 : +(a.nombre > b.nombre) || -1;
        });
        return sortArray;
    }

    guardarDatosGenerales(e){
        let datosGenerales = Object.assign({}, this.state.datosGenerales, {updatedat: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')});
        if(!this.validarSeguir){
            this.setState({ alertType: 'danger', alertMessageDatos: '*Faltan datos Generales por llenar', openAlertDatos: true });
        } else {
            firebase.db.collection('programadores').doc(`${datosGenerales.uid}`).update(datosGenerales)
            .then( () => {
                this.setState({ 
                    alertType: 'success', 
                    alertMessageDatos: 'Datos guardados exitosamente', 
                    openAlertDatos: true, 
                    editDatosGenerales: false 
                }); 
            })
            .catch(err => {
                console.log(err.message);
                this.setState({ alertType: 'danger', alertMessageDatos: '*Error al intentar guardar los datos', openAlertDatos: true });
            });
        }
    }

    guardarTecnologias(e){
        let tecs = [];
        tecs = [...this.state.tecnologias];
        let dataGuardar = {updatedat: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), tecnologias: tecs};
        if(!this.validarTecnologia()){
            this.setState({ 
                alertType: 'danger', 
                alertMessageDatosTecnologias: '*Faltan datos por llenar', 
                openAlertDatosTecnologias: true 
            });
        } else {
            firebase.db.collection('programadores').doc(`${this.state.datosGenerales.uid}`).update(dataGuardar)
            .then( () => {
                this.setState({ 
                    alertType: 'success', 
                    alertMessageDatosTecnologias: 'Datos guardados exitosamente', 
                    openAlertDatosTecnologias: true,
                    editDatosTecnologias: false 
                }); 
            })
            .catch(err => {
                console.log(err.message);
                this.setState({ 
                    alertType: 'danger', 
                    alertMessageDatosTecnologias: '*Error al intentar guardar los datos', 
                    openAlertDatosTecnologias: true 
                });
            });
        }
    }

    guardarExperiencias(e){
        let exp = [];
        exp = [...this.state.experiencias];
        let dataGuardar = {updatedat: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), experiencias: exp};
        if(!this.validarExperiencia()){
            this.setState({ 
                alertType: 'danger', 
                alertMessageEXperiencia: '*Faltan datos por llenar', 
                openAlertDatosEXperiencias: true 
            });
        } else {
            firebase.db.collection('programadores').doc(`${this.state.datosGenerales.uid}`).update(dataGuardar)
            .then( () => {
                this.setState({ 
                    alertType: 'success', 
                    alertMessageEXperiencia: 'Datos guardados exitosamente', 
                    openAlertDatosEXperiencias: true,
                    editDatosExperiencias: false 
                }); 
            })
            .catch(err => {
                console.log(err.message);
                this.setState({ 
                    alertType: 'danger', 
                    alertMessageEXperiencia: '*Error al intentar guardar los datos', 
                    openAlertDatosEXperiencias: true 
                });
            });
        }
    }

    guardarLinks(e){
        let lnks= [];
        lnks = [...this.state.links];
        let dataGuardar = {updatedat: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), links: lnks};
        const valido = this.validarLink();
        if(!valido){
            this.setState({ 
                alertType: 'danger', 
                alertMessageLinks: '*Link no valido', 
                openAlertDatosLinks: true 
            });
        } else {
            firebase.db.collection('programadores').doc(`${this.state.datosGenerales.uid}`).update(dataGuardar)
            .then( () => {
                this.setState({ 
                    alertType: 'success', 
                    alertMessageLinks: 'Datos guardados exitosamente', 
                    openAlertDatosLinks: true,
                    editDatosLinks: false 
                }); 
            })
            .catch(err => {
                console.log(err.message);
                this.setState({ 
                    alertType: 'danger', 
                    alertMessageLinks: '*Error al intentar guardar los datos', 
                    openAlertDatosLinks: true 
                });
            });
        }
    }

    guardarCursos(e){
        let curs = [];
        curs = [...this.state.cursos];
        let dataGuardar = {updatedat: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), cursos: curs};
        if(!this.validarCurso()){
            this.setState({ 
                alertType: 'danger', 
                alertMessageCursos: '*Faltan datos por llenar', 
                openAlertDatosCursos: true 
            });
        } else {
            firebase.db.collection('programadores').doc(`${this.state.datosGenerales.uid}`).update(dataGuardar)
            .then( () => {
                this.setState({ 
                    alertType: 'success', 
                    alertMessageCursos: 'Datos guardados exitosamente', 
                    openAlertDatosCursos: true,
                    editDatosCursos: false 
                }); 
            })
            .catch(err => {
                console.log(err.message);
                this.setState({ 
                    alertType: 'danger', 
                    alertMessageCursos: '*Error al intentar guardar los datos', 
                    openAlertDatosCursos: true 
                });
            });
        }
    }

    validarSeguir(){
        if(this.state.nombres === '' || this.state.apellidos === '' || this.state.email === '' || this.state.numWhatsapp === '' || this.state.cedula === '' || this.state.anosExperiencia === null || this.state.anosExperiencia === ''
        || this.state.tipoPerfiles.length === 0 || this.state.idiomas.length === 0 || this.state.titulo === '' || this.state.ciudad === '' || this.state.direccion === '' ||
        this.state.aspiracion === '' || this.state.aspiracion === null || this.state.fecha === '' || this.state.disponibilidad === '' ) {
            return false;
        }else{
            return true;
        }
    }

    validarTecnologia(){
        let tecs = [];
        let valido = true;
        tecs = [...this.state.tecnologias];
        tecs.forEach((item, pos) =>{
            if(item.tecnologia === ''|| item.tecnologiaAños === '' || item.dominio === 0 ){
                valido = false; 
            }
        })
        return valido;
    }

    validarExperiencia(){
        let exp = [];
        let valido = true;
        exp = [...this.state.experiencias];
        exp.forEach((item, pos) =>{
            if(item.empresaNombre === ''|| item.empresaDescripcion === '' ){
                valido =  false; 
            }
        })
        return valido;
    }

    validarLink(){
        let exp = [];
        let validurl = true;
        exp = [...this.state.links];
        exp.forEach((item, pos) =>{
            if(!this.isValidURL(item.link)){
                validurl = false; 
            }
        })
        if(exp.length === 0){
            validurl = false; 
        }
        return validurl;
    }

    isValidURL(url){
        var RegExp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        if(RegExp.test(url)){
            return true;
        }else{
            return false;
        }
    }

    validarCurso(){
        let exp = [];
        let valido = true;
        exp = [...this.state.cursos];
        exp.forEach((item, pos) =>{
            if(item.cursoNombre === '' || item.cursoDescripcion === '' ){
                valido =  false; 
            }
        })
        return valido;
    }

    valueInputTecnologia(value, namePorperty, index){
        let arrayTecnologias = [];
        arrayTecnologias = [...this.state.tecnologias];
        arrayTecnologias[index][namePorperty] = value;
        this.setState({ tecnologias: arrayTecnologias });
    }

    valueInputExperiencia(value, namePorperty, index){
        let arrayExperiencias = [];
        arrayExperiencias = [...this.state.experiencias];
        arrayExperiencias[index][namePorperty] = value;
        this.setState({ experiencias: arrayExperiencias });
    }

    valueInputLink(value, namePorperty, index){
        let arrayLinks = [];
        arrayLinks = [...this.state.links];
        arrayLinks[index][namePorperty] = value;
        this.setState({ links: arrayLinks });
    }

    valueInputCurso(value, namePorperty, index){
        let arrayCursos = [];
        arrayCursos = [...this.state.cursos];
        arrayCursos[index][namePorperty] = value;
        this.setState({ cursos: arrayCursos });
    }

    addTag(index = -1){
        const self = this;
        if(this.state.tag === '' ){
            this.setState({ messageAlertTag: 'Debe ingresar el nombre del Tag', alertTypeTag: 'danger', openAlertTag: true  })
            setTimeout(function(){ self.setState({openAlertTag: false}) }, 3000);
        } else {
            let array = this.state.tags;
            if(this.state.editTag){
                array[index] = this.state.tag;
            } else {
                array.push(this.state.tag);
            }
            firebase.db.collection("programadores").doc(`${this.state.datosGenerales.uid}`)
                .update({tags: array})
                .then(resp => {
                    this.setState({
                        messageAlertTag: 'Tag agregado exitosamente',
                        alertTypeTag: 'success',
                        tag: '',
                        openAlertTag: true,
                        editTag: false,
                        indexTag: -1,
                    })
                    this.getData(this.state.datosGenerales.uid);
                    setTimeout(function(){ self.setState({openAlertTag: false}) }, 3000);
                }).catch(err => {
                    console.log(err.message);
                });

        }
    }

    remove(e){
        let array = this.state.tags;
        // const item = array[e.index];
        array.splice(e.index, 1);
        this.setState({
            tags: array
        })
        this.deleteTag();
    }

    selected(e){
        this.setState({
            indexTag: e.index,
            editTag: true,
            tag: this.state.tags[e.index]
        });
    }

    cancelarTag(){
        this.setState({ editTag: false, tag: '' });
    }

    deleteTag(){
        const self = this;
        let array = this.state.tags;
        firebase.db.collection("programadores").doc(`${this.state.datosGenerales.uid}`)
            .update({tags: array})
            .then(resp => {
                this.setState({
                    messageAlertTag: 'Tag eliminado exitosamente',
                    alertTypeTag: 'success',
                    tag: '',
                    openAlertTag: true,
                    editTag: false
                })
                //this.getData();
                this.getData(this.state.datosGenerales.uid);
                setTimeout(function(){ self.setState({openAlertTag: false}) }, 3000);
            }).catch(err => {
                console.log(err.message);
            });
    }

    getIdiomas(){
        return this.state.datosGenerales.idiomas ? this.state.datosGenerales.idiomas :this.state.datosGenerales.nivelIngles
    }

    render(){

        return(
            <div>
                <Menu link='home' logout={this.logout} ></Menu>
                {this.state.loader &&
                    <div id="loader">
                        <div className="lds-heart"><div></div></div>
                    </div>
                }
            <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Cargando...'
                >
            {!this.state.loader &&
            <main className="container d-flex align-items-center justify-content-center topMain mb-3">
                <div className="row h-100 w-100 align-items-center justify-content-center" id="divToPrint" >
                    <div className="row p-0 w-100">
                        <div className="col-12 p-0 list-postul rounded border border-secondary shadow-lg w-100">
                            
                            <div className="list-postul-header text-center p-3">
                                <h2>PERFIL: <span>{this.state.datosGenerales.nombres} {this.state.datosGenerales.apellidos}</span></h2>
                            </div>
                            <div className="list-postul-content py-3 px-2 px-md-5 text-left">
                                
                                <div className="datos">
                                    <div className="datos-header w-100 text-center">
                                        <h3 className="heading font-weight-bold">Datos Generales</h3>
                                        <p>
                                            Datos generales ingresados por el postulante
                                        </p>
                                        {!this.state.editDatosGenerales &&
                                            <Button color="info" onClick={(e)=> { this.setState({ editDatosGenerales: true})}} > 
                                                Editar datos generales
                                            </Button>
                                        }
                                        {this.state.editDatosGenerales &&
                                            <div>
                                                <Button color="success" onClick={this.guardarDatosGenerales}> 
                                                    Guardar datos Generales
                                                </Button>
                                                <Button color="danger" style={{ marginLeft: 10}}
                                                    onClick={(e)=> { this.setState({ editDatosGenerales: false})}}> 
                                                    Cancelar
                                                </Button>
                                            </div>
                                        }
                                        <Alert color={this.state.alertType} isOpen={this.state.openAlertDatos} toggle={this.closeAlerts} >
                                            {this.state.alertMessageDatos}
                                        </Alert>
                                        
                                    </div>
                                    <div className="datos-content w-100 py-3">
                                        <div className="form-group row m-0">
                                            <div className="col-md-6">
                                                <div className="form-holder">
                                                    <Label>Nombres</Label>
                                                    <Input type="text" className="form-control"  name="nombres" value={this.state.datosGenerales.nombres} 
                                                        readOnly={!this.state.editDatosGenerales} 
                                                        onChange={this.handleChangeGenerales}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-holder">
                                                    <Label>Apellidos</Label>
                                                    <Input type="text" className="form-control"  name="apellidos" value={this.state.datosGenerales.apellidos} 
                                                        readOnly={!this.state.editDatosGenerales}
                                                        onChange={this.handleChangeGenerales} />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="form-group row m-0">
                                            <div className="col-md-6">
                                                <div className="form-holder">
                                                    <Label>Correo electr&oacute;nico</Label>
                                                    <Input type="email" name="email"  className="form-control" value={this.state.datosGenerales.email} 
                                                        readOnly={true} 
                                                        onChange={this.handleChangeGenerales} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-holder ">
                                                    <Label>Numero telef&oacute;nico con Whatsapp</Label>
                                                    <Input type="text" className="form-control"  name="numWhatsapp" value={this.state.datosGenerales.numWhatsapp}
                                                         readOnly={!this.state.editDatosGenerales} 
                                                         onChange={this.handleChangeGenerales}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row m-0">
                                            <div className="col-md-6">
                                                <div className="form-holder">
                                                    <Label>Cedula de identidad</Label>
                                                    <Input type="text" name="cedula"  className="form-control" value={this.state.datosGenerales.cedula} 
                                                        readOnly={!this.state.editDatosGenerales}
                                                        onChange={this.handleChangeGenerales} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-holder ">
                                                    <Label>Ciudad</Label>
                                                    <Input type="text" className="form-control"  name="ciudad" value={this.state.datosGenerales.ciudad} 
                                                        readOnly={!this.state.editDatosGenerales} 
                                                        onChange={this.handleChangeGenerales} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row m-0">
                                            <div className="col-md-6">
                                                <div className="form-holder">
                                                    <Label>Fecha</Label>
                                                    <Input type="text" name=""  className="form-control" value={this.state.datosGenerales.fecha}
                                                     readOnly={true} 
                                                     onChange={this.handleChangeGenerales}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-holder ">
                                                    <Label>Años de experiencia</Label>
                                                    <Input type="text" className="form-control"  name="anosExperiencia" value={this.state.datosGenerales.anosExperiencia} 
                                                        readOnly={!this.state.editDatosGenerales} 
                                                        onChange={event => {
                                                            let datosG = this.state.datosGenerales;
                                                            datosG.anosExperiencia = parseInt(event.target.value , 10);
                                                            this.setState({ datosGenerales: datosG})
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row m-0">
                                            <div className="col-md-6">
                                                <div className="form-holder ">
                                                    <Label>
                                                        Título académico
                                                    </Label>
                                                    {!this.state.editDatosGenerales &&
                                                        <Input type="text" className="form-control"  name="" value={this.state.datosGenerales.titulo} readOnly={true} />
                                                    }
                                                {this.state.editDatosGenerales &&
                                                    <div className="caja-select">
                                                        <Input type="select" name="titulo"  value={this.state.datosGenerales.titulo} id="titulo" onChange={this.handleChangeSelect} >
                                                            <option value="" disabled>Seleccione</option>
                                                            <option value="Analista de Marketing">Analista de Marketing</option>
                                                            <option value="Bachiller">Bachiller</option>
                                                            <option value="Diseñador Gráfico">Diseñador Gráfico</option>
                                                            <option value="Ingeniero de Sistemas">Ingeniero de Sistemas</option>
                                                            <option value="Ingeniero de Computacion">Ingeniero de Computacion</option>
                                                            <option value="TSU Informatica">TSU Informatica</option>
                                                            <option value="Estudios inconclusos">Estudios inconclusos</option>
                                                            <option value="Otro">Otro</option>
                                                        </Input>
                                                    </div>
                                                }
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-holder ">
                                                    <Label>Estudio avanzado</Label>
                                                    <Input type="text" className="form-control"  name="anosExperiencia" value={this.state.datosGenerales.otroTitulo} 
                                                        readOnly={!this.state.editDatosGenerales} 
                                                        onChange={event => {
                                                            let datosG = this.state.datosGenerales;
                                                            datosG.otroTitulo = event.target.value;
                                                            this.setState({ datosGenerales: datosG})
                                                        }} />
                                                </div>
                                            </div>
                                            {this.state.datosGenerales.universidad !== '' && 
                                                <div className="col-md-6">
                                                    <div className="form-holder ">
                                                        <Label>
                                                            Universidad/Institucion
                                                        </Label>
                                                    {!this.state.editDatosGenerales &&
                                                        <Input type="text" className="form-control" name="universidad" value={this.state.datosGenerales.universidad} readOnly={true} />
                                                    }
                                                    {this.state.editDatosGenerales &&
                                                        <div className="caja-select">
                                                            <Input type="select" name="universidad"  value={this.state.datosGenerales.universidad} id="universidad" onChange={this.handleChangeSelect} onKeyUp={this.validarAvanzar}  
                                                                disabled
                                                            >
                                                                
                                                                {this.state.universidades.map((uni, key) => (
                                                                    <option value={uni} key={key}>{uni}</option>
                                                                ))}
                                                            </Input>
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            }

                                            <div className="col-md-6">
                                                <div className="form-holder ">
                                                    <Label>Direcci&oacute;n</Label>
                                                    <Input type="text" className="form-control"  name="direccion" value={this.state.datosGenerales.direccion}
                                                     readOnly={!this.state.editDatosGenerales} 
                                                     onChange={this.handleChangeGenerales}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-holder ">
                                                    <Label>
                                                        {this.state.datosGenerales.idiomas ? 'Idiomas' : 'Habla y escribe ingles al 100%'}
                                                    </Label>
                                                    {!this.state.editDatosGenerales &&
                                                        <Input type="text" className="form-control"  name="" value={this.getIdiomas()} readOnly={true} />
                                                    }
                                                    {this.state.editDatosGenerales &&

                                                        <Typeahead
                                                            //filterBy={this.filterByCallback.bind(this)}
                                                            clearButton
                                                            id="idiomas"
                                                            labelKey="name"
                                                            multiple={true}
                                                            emptyLabel="No se encontraron coincidencias."
                                                            defaultSelected={this.state.datosGenerales.idiomas}
                                                            options={this.state.idiomasList}
                                                            placeholder="Español, Ingles, Francés" 
                                                            value={this.state.datosGenerales.idiomas} 
                                                            onChange={this.handleChangeIdiomas}
                                                            onInputChange={this._handleSearchIdiomas}
                                                            ref={(ref) => this._typeaheadIdiomas = ref}
                                                            paginationText="Ver mas resultados"
                                                        />


                                                        /*<div className="caja-select">
                                                            <Input type="select" name="nivelIngles" value={this.state.datosGenerales.nivelIngles} id="nivelIngles" onChange={this.handleChangeSelect}  >
                                                                <option value="" disabled>Seleccione</option>
                                                                <option value="No">No</option>
                                                                <option value="Si">Si</option>
                                                            </Input>
                                                        </div>*/
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-holder">
                                                    <Label>
                                                            Aspiración salarial MENSUAL en dolares USD
                                                        </Label>
                                                    <Input type="text" className="form-control"  name="aspiracion" value={this.state.datosGenerales.aspiracion} 
                                                        readOnly={!this.state.editDatosGenerales} 
                                                        onChange={this.handleChangeGenerales} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-holder ">
                                                    <Label>
                                                        Tipo de perfil
                                                    </Label>
                                                    {!this.state.editDatosGenerales &&
                                                        <Input type="text" className="form-control"  name="" value={this.state.datosGenerales.tipoPerfiles} 
                                                        readOnly={true} onChange={this.handleChangeGenerales} />
                                                    }
                                                    {this.state.editDatosGenerales &&
                                                        <Typeahead
                                                            clearButton
                                                            id="categorias"
                                                            labelKey="nombre"
                                                            emptyLabel="No se encontraron coincidencias."
                                                            allowNew={this.state.addNew}
                                                            defaultSelected={this.state.datosGenerales.tipoPerfiles}
                                                            newSelectionPrefix="Añadir Perfil: "
                                                            options={this.state.categorias}
                                                            placeholder="Ej: Backend JAVA, Backend JavaScript, Backend PHP..." 
                                                            value={this.state.datosGenerales.tipoPerfiles} 
                                                            onChange={this.handleChangeAutocomplete}
                                                            onInputChange={this._handleSearch}
                                                            ref={(ref) => this._typeahead = ref}
                                                            paginationText="Ver mas resultados"
                                                        />
                                                    }               
                                                </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>

                                <div className="datos my-5">
                                    <div className="datos-header w-100 text-center">
                                        <h3 className="heading font-weight-bold">Datos Tecnicos</h3>
                                        <p>
                                            Perfil profesional del postulante
                                        </p>
                                        {!this.state.editDatosTecnologias &&
                                            <Button color="info" onClick={(e)=> {this.setState({ editDatosTecnologias: true})}} > 
                                                Editar Tecnologías
                                            </Button>
                                        }
                                        {this.state.editDatosTecnologias &&
                                            <div>
                                                <Button color="info" onClick={(e)=> { this.guardarTecnologias(e) }} > 
                                                    Guardar Tecnologías
                                                </Button>
                                                <Button color="danger" style={{ marginLeft: 10 }}
                                                    onClick={(e)=> { this.setState({ editDatosTecnologias: false})}}
                                                 > 
                                                    Cancelar
                                                </Button>
                                            </div>
                                        }
                                        <Alert color={this.state.alertType} isOpen={this.state.openAlertDatosTecnologias} toggle={this.closeAlerts} >
                                            {this.state.alertMessageDatosTecnologias}
                                        </Alert>
                                    </div>
                                    <h4 className="mt-3">Tecnologías</h4>

                                    {this.state.tecnologias.map((tecnologia, key) => (
                                        <Tecnologia  tecnologia={tecnologia} 
                                            key={key} 
                                            edit={this.state.editDatosTecnologias} 
                                            valueInput={this.valueInputTecnologia}
                                            indice = {key}
                                        />
                                    ))}

                                    {this.state.tecnologias.length === 0 &&
                                        <Tecnologia  
                                            tecnologia={{tecnologia: '', tecnologiaDominio: '', tecnologiaVersion: '', tecnologiaAños: ''}}
                                            edit={false} 
                                        />
                                    }

                                    <div className="datos-header w-100 text-center mt-5">
                                        {!this.state.editDatosExperiencias &&
                                            <Button color="info" onClick={(e)=> {this.setState({ editDatosExperiencias: true})}} > 
                                                Editar Experiencias
                                            </Button>
                                        }
                                        {this.state.editDatosExperiencias &&
                                            <div>
                                                <Button color="info" onClick={(e)=> { this.guardarExperiencias(e) }} > 
                                                    Guardar Experiecias
                                                </Button>
                                                <Button color="danger" style={{ marginLeft: 10 }}
                                                    onClick={(e)=> { this.setState({ editDatosExperiencias: false})}}
                                                 > 
                                                    Cancelar
                                                </Button>
                                            </div>
                                        }
                                        <Alert color={this.state.alertType} isOpen={this.state.openAlertDatosEXperiencias} toggle={this.closeAlerts} >
                                            {this.state.alertMessageEXperiencia}
                                        </Alert>
                                    </div>

                                    <h4 className="mt-3">Experiencias laborales</h4>

                                    {this.state.experiencias.map((experiencia, key) => (
                                        <Experiencia  
                                            key={key} 
                                            experiencia={experiencia} 
                                            edit={this.state.editDatosExperiencias} 
                                            valueInput={this.valueInputExperiencia}
                                            indice = {key}
                                        />
                                    ))}

                                    {this.state.experiencias.length === 0 &&
                                        <Experiencia  experiencia={{empresaNombre: '',empresaDescripcion: '' }}  />
                                    }

                                    <div className="datos-header w-100 text-center mt-5">
                                        {!this.state.editDatosLinks &&
                                            <Button color="info" onClick={(e)=> {this.setState({ editDatosLinks: true})}} > 
                                                Editar Links
                                            </Button>
                                        }
                                        {this.state.editDatosLinks &&
                                            <div>
                                                <Button color="info" onClick={(e)=> { this.guardarLinks(e) }} > 
                                                    Guardar Links
                                                </Button>
                                                <Button color="danger" style={{ marginLeft: 10 }}
                                                    onClick={(e)=> { this.setState({ editDatosLinks: false})}}
                                                 > 
                                                    Cancelar
                                                </Button>
                                            </div>
                                        }
                                        <Alert color={this.state.alertType} isOpen={this.state.openAlertDatosLinks} toggle={this.closeAlerts} >
                                            {this.state.alertMessageLinks}
                                        </Alert>
                                    </div>

                                    <h4 className="mt-3">Links</h4>

                                    {this.state.links.map((link, key) => (
                                        <Links  
                                            link={link}  
                                            key={key} 
                                            edit={this.state.editDatosLinks} 
                                            valueInput={this.valueInputLink}
                                            indice = {key}
                                        />
                                    ))}

                                    {this.state.links.length === 0 &&
                                        <Links  link={{link: '', linkDescripcion: '' }}  />
                                    }


                                    <div className="datos-header w-100 text-center mt-5">
                                        {!this.state.editDatosCursos &&
                                            <Button color="info" onClick={(e)=> {this.setState({ editDatosCursos: true})}} > 
                                                Editar Cursos
                                            </Button>
                                        }
                                        {this.state.editDatosCursos &&
                                            <div>
                                                <Button color="info" onClick={(e)=> { this.guardarCursos(e) }} > 
                                                    Guardar Cursos
                                                </Button>
                                                <Button color="danger" style={{ marginLeft: 10 }}
                                                    onClick={(e)=> { this.setState({ editDatosCursos: false})}}
                                                 > 
                                                    Cancelar
                                                </Button>
                                            </div>
                                        }
                                        <Alert color={this.state.alertType} isOpen={this.state.openAlertDatosCursos} toggle={this.closeAlerts} >
                                            {this.state.alertMessageCursos}
                                        </Alert>
                                    </div>

                                    {this.state.cursos.length > 0 &&
                                        <div>
                                            <h4 className="mt-3">Cursos</h4>
                                            {this.state.cursos.map((curso, key) => (
                                                <Curso      
                                                    curso={curso}  key={key}  
                                                    back={this.back} 
                                                    edit={this.state.editDatosCursos} 
                                                    valueInput={this.valueInputCurso}
                                                    indice = {key}
                                                />
                                            ))}
                                        </div>
                                    }
                                </div>

                                {this.state.historico.length > 0 &&
                                    <div className="datos">
                                        <div className="datos-header w-100 text-center">
                                            <h3 className="heading font-weight-bold">Historico</h3>
                                        </div>
                                        {this.state.historico.map((hist, key) => (
                                            <Alert color="secondary" key={key} >
                                                <label><b>{hist.accion}</b></label> 
                                                <label style={{ float: 'right'}}>
                                                    {moment(new Date(hist.fecha)).format('DD-MM-YYYY')}
                                                </label>
                                            </Alert>
                                        ))}
                                    </div>
                                }

                                <div className="datos">
                                    <div className="datos-header w-100 text-center">
                                        <h3 className="heading font-weight-bold">Tags</h3>
                                    </div>
                                </div>

                                <div className="form-group row m-0">
                                    <div className="col-12 col-md-6">
                                        <div className="form-holder">
                                            <Label>Nombre de Tag</Label>
                                            <Input type="text" className="form-control" id="tag" name="tag" value={this.state.tag} onChange={this.handleChange} />
                                        </div>   
                                    </div>
                                    {this.state.editTag === false && (
                                        <div className="col-12 col-md-2" style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                                            <div className="form-holder">
                                                <Button color="success" onClick={this.addTag}>Guardar Tag</Button>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.editTag === true && (
                                        <div className="col-6 col-md-2" style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                                            <div className="form-holder">
                                                <Button color="success" onClick={(e) => this.addTag(this.state.indexTag)}>Actualizar Tag</Button>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.editTag === true && (
                                        <div className="col-6 col-md-2" style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                                            <div className="form-holder">
                                                <Button color="danger" onClick={this.cancelarTag}>Cancelar</Button>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="form-group row m-0 mt-3">
                                    <div className="col-12 text-center">
                                        <Alert color={this.state.alertTypeTag} isOpen={this.state.openAlertTag} toggle={this.closeAlerts} >
                                            {this.state.messageAlertTag}
                                        </Alert>
                                    </div>
                                </div>

                                <div className="col-12" style={{display: 'flex', flexWrap: 'wrap'}} >
                                    <label>Tags agregados:&nbsp;</label>
                                    {this.state.tags.map((data, key) => (
                                        <Chip key={key} text={data} remove={this.remove} selected={this.selected} index={key} type="tags" />
                                    ))}
                                </div>

                                <div className="datos">
                                    <div className="datos-header w-100 text-center">
                                        <h3 className="heading font-weight-bold">Costos</h3>
                                        <p>
                                            calculo de costos para el postulante
                                        </p>
                                    </div>

                                    <div className="form-group row m-0">
                                        <div className="col-6">
                                            <div className="form-holder">
                                                <Label>Salario</Label>
                                                <Input type="text" className="form-control" id="salario" name="salario" value={this.state.salario} onChange={this.handleChange} />
                                            </div>   
                                        </div>
                                        <div className="col-6">
                                            <div className="form-holder">
                                                <Label>Costo Mensual</Label>
                                                
                                                <input type="text" className="form-control" id="costoMesString" name="costoMesString" value={this.state.costoMesString} onChange={this.handleChange} />

                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row m-0 mt-3">
                                        <div className="col-12 text-center">
                                            <Alert color={this.state.alertType} isOpen={this.state.openAlert} toggle={this.closeAlerts} >
                                                {this.state.alertMessage}
                                            </Alert>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-12">
                                    <div className="form-holder text-center">
                                        <Button color="success"  onClick={this.actualizarCostos} >Actualizar costos</Button>
                                    </div>
                                </div>
                                <div className="form-group row m-0 align-items-start">
                                            <div className="col-md-6">
                                                <Label>
                                                    Estado
                                                </Label>
                                                <div className="caja-select">
                                                    <Input type="select" name="estado" id="estado" value={this.state.estado}  onChange={this.changeEstado}  >
                                                        <option value="Habilitado">Habilitado</option>
                                                        <option value="No contratado">No contratado</option>
                                                        <option value="No cumple los requisitos">No cumple los requisitos</option>
                                                        <option value="No revisado">No revisado</option>
                                                        <option value="Contratado">Contratado</option>
                                                        <option value="Penalizado">Penalizado</option>
                                                        <option value="Tiene trabajo">Tiene trabajo</option>
                                                    </Input>
                                                </div>
                                            </div>
                                            {this.state.estado !== 'No contratado' &&
                                                <div className="col-md-6">
                                                    <Label>
                                                        &nbsp;
                                                    </Label>
                                                    <br/>
                                                    <Button color="success" onClick={this.actualizarEstado} >Actualizar estado</Button>
                                                </div>
                                            }
                                        </div>
                                        {this.state.estado === 'No contratado' &&
                                        
                                            <div className="form-group row m-0 align-items-start">
                                                <div className="col-md-6">
                                                    <Label>
                                                        Motivo
                                                    </Label>
                                                    <div className="caja-select">
                                                        <Input type="select" name="motivo" id="motivo" value={this.state.motivo}  onChange={this.changeMotivo}  >
                                                            {notificaciones.noContratado.motivos.map((mot, j) => (                                                                
                                                                <option value={mot} key={j}>{mot}</option> 
                                                            ))}
                                                        </Input>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <Label>
                                                        &nbsp;
                                                    </Label>
                                                    <br/>
                                                    <Button color="success" onClick={this.actualizarEstado} >Actualizar estado</Button>
                                                </div>
                                            </div>
                                        }
                                        <div className="form-group row m-0 mt-3">
                                            <div className="col-12 text-center">
                                                <Alert color={this.state.alertTypeEstado} isOpen={this.state.openAlertEstado} toggle={this.closeAlerts} >
                                                    {this.state.alertMessageEstado}
                                                </Alert>
                                            </div>
                                        </div>
                                {this.state.estado === 'Habilitado' &&
                                    <div className="form-group row m-0 align-items-start mt-3">
                                    <div className="col-12 text-center">
                                        <Button color="info"  onClick={this.match} >Buscar Match</Button>
                                    </div>
                                    {this.state.isMatch && 
                                    <div className="col-12 mt-3">
                                        <table className="table" style={{marginBottom: 0 }} >
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="d-table-cell" style={{padding: '0.30rem'}}>Obligatorias</th>
                                                    <th scope="col" className="d-table-cell" style={{padding: '0.30rem'}}>Opcionales</th>
                                                    <th scope="col" className="d-table-cell" style={{padding: '0.30rem'}}>Costo Mes</th>
                                                    <th scope="col" className="d-table-cell" style={{padding: '0.30rem'}}>Ingles</th>
                                                    <th scope="col" className="d-table-cell" style={{padding: '0.30rem'}}>Datos de la Empresa</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.matches.length === 0 &&
                                                    <tr>
                                                        <td className="d-table-cell" style={{ textAlign: 'center'}} colSpan="4" >
                                                            No encaja en ninguna busqueda
                                                        </td>
                                                    </tr>
                                                }
                                                {this.state.matches.map((bsq, k) => ( 
                                                    <tr key={k} >
                                                        <td className="d-table-cell" style={{padding: '0.30rem'}} >
                                                            {bsq.tecnologias.map((tec, j) => ( 
                                                                <div key={j}>
                                                                    <span><b>{tec.nombre}</b>&nbsp;</span>
                                                                    <span>{tec.tiempo}&nbsp;</span>
                                                                    <span>{tec.periodo}</span>
                                                                </div>
                                                            ))}
                                                        </td>
                                                        {bsq.opcionales.length === 0 &&
                                                            <td className="d-table-cell">Ninguna</td>
                                                        }
                                                        {bsq.opcionales.length > 0 &&
                                                            <td className="d-table-cell">
                                                                {bsq.opcionales.map((tec, j) => ( 
                                                                <div key={j}>
                                                                    <span><b>{tec.nombre}</b>&nbsp;</span>
                                                                    <span>{tec.tiempo}&nbsp;</span>
                                                                    <span>{tec.periodo}</span>
                                                                </div>
                                                                ))}
                                                            </td>
                                                        }
                                                        <td className="d-table-cell" style={{padding: '0.30rem'}}>{bsq.costoMensual}</td>
                                                        <td className="d-table-cell" style={{padding: '0.30rem'}}>{bsq.nivelIngles}</td>
                                                        <td className="d-table-cell" style={{padding: '0.30rem'}}>
                                                            <span><b>{bsq.empresa.nombreEmpresa}</b></span><br />
                                                            <span>{bsq.empresa.email}</span><br />
                                                        </td>
                                                    </tr>

                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    }
                                </div>
                                }
                                
                                <div className="col-12 mt-3">
                                    <div className="form-holder text-center">
                                        <Button color="primary"  onClick={this.viewPDF} style={{marginRight: 5}} >Ver PDF</Button>
                                        <Button color="info"  onClick={this.back} >Volver al listado</Button>
                                    </div>
                                </div>

                            </div>
                            
                        </div>
                    </div>
                </div>
            </main>
            } 
            </LoadingOverlay>
            </div>
        );
    }
    
}

export default withRouter(DetalleProgramador)