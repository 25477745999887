export default {
  habilitado: {
    texto: `<p>ha aprobado tu perfil, nos interesa tus conocimientos técnicos y estamos en concordancia con tu aspiracion salarial, serás considerado para proyectos con nuestros clientes, te estaremos contactando apenas tengamos disposición de un proyecto que se adapte a tus características mediante tu email ó tu numero de whatsapp.</p>
	
	<p>Consideraciones previas a una entrevista:</p>
	<p>
	  1. Expresarse en un lenguaje técnico cient&iacute;fico, evita la
	  informalidad, comunicate como un profesional en tu area.
	</p>
	<p>
	   2. Ser capaz de responder preguntas técnicas porque los
	  entrevistadores son especialistas en el área donde estás buscando
	  empleo, aunque en algunas ocasiones no lo mencionen al presentarse,
	  no es apropiado un "yo sé cómo se hace, pero no como se dice", una
	  simple búsqueda un Google te puede ser de gran utilidad, ejemplo:
	  "preguntas para una entrevista de Laravel, Angular, etc".
	</p>
	<p>
	  3. Respecto a la experiencia: Así dispongas una amplia experiencia,
	  es necesario tener disposición a realizar pruebas y es importante
	  repasar algoritmos básicos para el caso de los programadores.  
	</p>
	<p>
	  4. No menos importante es la presentación... su presentación
	  personal hasta la comprobación del correcto funcionamiento de su
	  equipo de trabajo es relevante para la entrevista para un trabajo
	  remoto y por supuesto la puntualidad. Es importante ingresar 5
	  minutos antes de la hora citada.
	</p>
	<p>
	  5. Sé amable, trata correctamente a las personas con las cuales te
	  relacionas, poseer habilidades blandas te abrirá las puertas en
	  Hispanos Soluciones y en cualquier otra empresa.
	</p>`,
  },
  noHabilitado: {
    texto: `ha recibido todos tus datos, debido a que ya tienes proyectos con uno de nuestros clientes tu estado pasará a ocupado, seras habilitado nuevamente cuando culmines los proyectos o cuando termines relación con uno de nuestros clientes.`,
  },
  penalizado: {
    texto: `ha evaluado tu historial de trabajo y considera que tuviste alguna experiencia negativa con por lo menos uno de nuestros clientes, por tal motivo has quedado penalizado en nuestra plataforma de forma permanente y no podremos asignarte nuevos proyectos.`,
  },
  noContratado: {
    texto: `ha decidido no contratarlo/a, debido a:`,
    motivos: [
      "Aspiración salarial por encima de nuestras posibilidades",
      "Falta de conocimientos técnicos",
      "Aspiración salarial elevada y falta de conocimientos técnicos",
    ],
  },
  perfilIncompleto: {
    texto: `lo ha registrado en su plataformar https://postulate.hispanossoluciones.com/, 
			para que tu postulación se lleve a cabo debes iniciar sesión con la cuenta de correo`,
  },
  noCumpleRequisitos: {
    texto: `acepta que has indicado que no cumples con los requisitos laborales para ser asigando a nuestros clientes, de igual manera te ofrecemos gracias por haber participado.`,
  },
  tieneTrabajo: {
    texto: `Actualmente te encuentras laborando, cuando termines la relación laboral con la empresa actual, seras considerado en nuestra plataforma.`,
  },
  contratado: {
    texto: `Hemos recibido todos tus datos, actualmente te integraste a nuestro equipo de trabajo, por lo tanto tu estatus esta siendo cambiado en nuestra plataforma
			como Contratado, Éxitos...
		`,
  },
};
